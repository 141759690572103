@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");

/*=============================================
=            CSS Index            =
=============================================

    -> Helper CSS
    -> Default CSS
    -> Spacing
    -> Header
    -> Footer
    -> Sidebar
    -> Container
    -> Button
    -> Search overlay
    -> Section title
    -> Backgrounds
    -> Accordions
    -> Preloader
    -> Scroll top
    -> Pagination
    -> Icon
    -> Progress bar
    -> Featured project
    -> Industry slider
    -> Testimonial slider
    -> Brand logo slider
    -> Project counter
    -> Blog newsletter
    -> Support footer
    -> CTA area 
    -> Career
    -> Icon info
    -> Banner
    -> Partner career
    -> Industry grid
    -> Feature background
    -> About counter
    -> Project grid post
    -> Social element
    -> Contact form
    -> Industry icon
    -> Project filter
    -> Quote background
    -> Industry flip
    -> Industry tab
    -> Breadcrumb
    -> Case study content table
    -> Image gallery slider
    -> Blog post
    -> Team member slider
    -> product slider
    -> Blog
    -> Service details
    -> Common
    -> Shop
    -> Cart
    -> Checkout
    -> My account
    -> Login Register
    -> not found



/*=====  End of CSS Index  ======*/

/*=============================================
=            Helper CSS            =
=============================================*/

.row-0 {
	margin-left: 0px;
	margin-right: 0px;
}

.row-0 > [class*="col"] {
	padding-left: 0px;
	padding-right: 0px;
}

.row-1 {
	margin-left: -1px;
	margin-right: -1px;
}

.row-1 > [class*="col"] {
	padding-left: 1px;
	padding-right: 1px;
}

.row-2 {
	margin-left: -2px;
	margin-right: -2px;
}

.row-2 > [class*="col"] {
	padding-left: 2px;
	padding-right: 2px;
}

.row-3 {
	margin-left: -3px;
	margin-right: -3px;
}

.row-3 > [class*="col"] {
	padding-left: 3px;
	padding-right: 3px;
}

.row-4 {
	margin-left: -4px;
	margin-right: -4px;
}

.row-4 > [class*="col"] {
	padding-left: 4px;
	padding-right: 4px;
}

.row-5 {
	margin-left: -5px;
	margin-right: -5px;
}

.row-5 > [class*="col"] {
	padding-left: 5px;
	padding-right: 5px;
}

.row-6 {
	margin-left: -6px;
	margin-right: -6px;
}

.row-6 > [class*="col"] {
	padding-left: 6px;
	padding-right: 6px;
}

.row-7 {
	margin-left: -7px;
	margin-right: -7px;
}

.row-7 > [class*="col"] {
	padding-left: 7px;
	padding-right: 7px;
}

.row-8 {
	margin-left: -8px;
	margin-right: -8px;
}

.row-8 > [class*="col"] {
	padding-left: 8px;
	padding-right: 8px;
}

.row-9 {
	margin-left: -9px;
	margin-right: -9px;
}

.row-9 > [class*="col"] {
	padding-left: 9px;
	padding-right: 9px;
}

.row-10 {
	margin-left: -10px;
	margin-right: -10px;
}

.row-10 > [class*="col"] {
	padding-left: 10px;
	padding-right: 10px;
}

.row-11 {
	margin-left: -11px;
	margin-right: -11px;
}

.row-11 > [class*="col"] {
	padding-left: 11px;
	padding-right: 11px;
}

.row-12 {
	margin-left: -12px;
	margin-right: -12px;
}

.row-12 > [class*="col"] {
	padding-left: 12px;
	padding-right: 12px;
}

.row-13 {
	margin-left: -13px;
	margin-right: -13px;
}

.row-13 > [class*="col"] {
	padding-left: 13px;
	padding-right: 13px;
}

.row-14 {
	margin-left: -14px;
	margin-right: -14px;
}

.row-14 > [class*="col"] {
	padding-left: 14px;
	padding-right: 14px;
}

.row-15 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-15 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-16 {
	margin-left: -16px;
	margin-right: -16px;
}

.row-16 > [class*="col"] {
	padding-left: 16px;
	padding-right: 16px;
}

.row-17 {
	margin-left: -17px;
	margin-right: -17px;
}

.row-17 > [class*="col"] {
	padding-left: 17px;
	padding-right: 17px;
}

.row-18 {
	margin-left: -18px;
	margin-right: -18px;
}

.row-18 > [class*="col"] {
	padding-left: 18px;
	padding-right: 18px;
}

.row-19 {
	margin-left: -19px;
	margin-right: -19px;
}

.row-19 > [class*="col"] {
	padding-left: 19px;
	padding-right: 19px;
}

.row-20 {
	margin-left: -20px;
	margin-right: -20px;
}

.row-20 > [class*="col"] {
	padding-left: 20px;
	padding-right: 20px;
}

.row-21 {
	margin-left: -21px;
	margin-right: -21px;
}

.row-21 > [class*="col"] {
	padding-left: 21px;
	padding-right: 21px;
}

.row-22 {
	margin-left: -22px;
	margin-right: -22px;
}

.row-22 > [class*="col"] {
	padding-left: 22px;
	padding-right: 22px;
}

.row-23 {
	margin-left: -23px;
	margin-right: -23px;
}

.row-23 > [class*="col"] {
	padding-left: 23px;
	padding-right: 23px;
}

.row-24 {
	margin-left: -24px;
	margin-right: -24px;
}

.row-24 > [class*="col"] {
	padding-left: 24px;
	padding-right: 24px;
}

.row-25 {
	margin-left: -25px;
	margin-right: -25px;
}

.row-25 > [class*="col"] {
	padding-left: 25px;
	padding-right: 25px;
}

.row-26 {
	margin-left: -26px;
	margin-right: -26px;
}

.row-26 > [class*="col"] {
	padding-left: 26px;
	padding-right: 26px;
}

.row-27 {
	margin-left: -27px;
	margin-right: -27px;
}

.row-27 > [class*="col"] {
	padding-left: 27px;
	padding-right: 27px;
}

.row-28 {
	margin-left: -28px;
	margin-right: -28px;
}

.row-28 > [class*="col"] {
	padding-left: 28px;
	padding-right: 28px;
}

.row-29 {
	margin-left: -29px;
	margin-right: -29px;
}

.row-29 > [class*="col"] {
	padding-left: 29px;
	padding-right: 29px;
}

.row-30 {
	margin-left: -30px;
	margin-right: -30px;
}

.row-30 > [class*="col"] {
	padding-left: 30px;
	padding-right: 30px;
}

.row-31 {
	margin-left: -31px;
	margin-right: -31px;
}

.row-31 > [class*="col"] {
	padding-left: 31px;
	padding-right: 31px;
}

.row-32 {
	margin-left: -32px;
	margin-right: -32px;
}

.row-32 > [class*="col"] {
	padding-left: 32px;
	padding-right: 32px;
}

.row-33 {
	margin-left: -33px;
	margin-right: -33px;
}

.row-33 > [class*="col"] {
	padding-left: 33px;
	padding-right: 33px;
}

.row-34 {
	margin-left: -34px;
	margin-right: -34px;
}

.row-34 > [class*="col"] {
	padding-left: 34px;
	padding-right: 34px;
}

.row-35 {
	margin-left: -35px;
	margin-right: -35px;
}

.row-35 > [class*="col"] {
	padding-left: 35px;
	padding-right: 35px;
}

.row-36 {
	margin-left: -36px;
	margin-right: -36px;
}

.row-36 > [class*="col"] {
	padding-left: 36px;
	padding-right: 36px;
}

.row-37 {
	margin-left: -37px;
	margin-right: -37px;
}

.row-37 > [class*="col"] {
	padding-left: 37px;
	padding-right: 37px;
}

.row-38 {
	margin-left: -38px;
	margin-right: -38px;
}

.row-38 > [class*="col"] {
	padding-left: 38px;
	padding-right: 38px;
}

.row-39 {
	margin-left: -39px;
	margin-right: -39px;
}

.row-39 > [class*="col"] {
	padding-left: 39px;
	padding-right: 39px;
}

.row-40 {
	margin-left: -40px;
	margin-right: -40px;
}

.row-40 > [class*="col"] {
	padding-left: 40px;
	padding-right: 40px;
}

.row-41 {
	margin-left: -41px;
	margin-right: -41px;
}

.row-41 > [class*="col"] {
	padding-left: 41px;
	padding-right: 41px;
}

.row-42 {
	margin-left: -42px;
	margin-right: -42px;
}

.row-42 > [class*="col"] {
	padding-left: 42px;
	padding-right: 42px;
}

.row-43 {
	margin-left: -43px;
	margin-right: -43px;
}

.row-43 > [class*="col"] {
	padding-left: 43px;
	padding-right: 43px;
}

.row-44 {
	margin-left: -44px;
	margin-right: -44px;
}

.row-44 > [class*="col"] {
	padding-left: 44px;
	padding-right: 44px;
}

.row-45 {
	margin-left: -45px;
	margin-right: -45px;
}

.row-45 > [class*="col"] {
	padding-left: 45px;
	padding-right: 45px;
}

.row-46 {
	margin-left: -46px;
	margin-right: -46px;
}

.row-46 > [class*="col"] {
	padding-left: 46px;
	padding-right: 46px;
}

.row-47 {
	margin-left: -47px;
	margin-right: -47px;
}

.row-47 > [class*="col"] {
	padding-left: 47px;
	padding-right: 47px;
}

.row-48 {
	margin-left: -48px;
	margin-right: -48px;
}

.row-48 > [class*="col"] {
	padding-left: 48px;
	padding-right: 48px;
}

.row-49 {
	margin-left: -49px;
	margin-right: -49px;
}

.row-49 > [class*="col"] {
	padding-left: 49px;
	padding-right: 49px;
}

.row-50 {
	margin-left: -50px;
	margin-right: -50px;
}

.row-50 > [class*="col"] {
	padding-left: 50px;
	padding-right: 50px;
}

.row-51 {
	margin-left: -51px;
	margin-right: -51px;
}

.row-51 > [class*="col"] {
	padding-left: 51px;
	padding-right: 51px;
}

.row-52 {
	margin-left: -52px;
	margin-right: -52px;
}

.row-52 > [class*="col"] {
	padding-left: 52px;
	padding-right: 52px;
}

.row-53 {
	margin-left: -53px;
	margin-right: -53px;
}

.row-53 > [class*="col"] {
	padding-left: 53px;
	padding-right: 53px;
}

.row-54 {
	margin-left: -54px;
	margin-right: -54px;
}

.row-54 > [class*="col"] {
	padding-left: 54px;
	padding-right: 54px;
}

.row-55 {
	margin-left: -55px;
	margin-right: -55px;
}

.row-55 > [class*="col"] {
	padding-left: 55px;
	padding-right: 55px;
}

.row-56 {
	margin-left: -56px;
	margin-right: -56px;
}

.row-56 > [class*="col"] {
	padding-left: 56px;
	padding-right: 56px;
}

.row-57 {
	margin-left: -57px;
	margin-right: -57px;
}

.row-57 > [class*="col"] {
	padding-left: 57px;
	padding-right: 57px;
}

.row-58 {
	margin-left: -58px;
	margin-right: -58px;
}

.row-58 > [class*="col"] {
	padding-left: 58px;
	padding-right: 58px;
}

.row-59 {
	margin-left: -59px;
	margin-right: -59px;
}

.row-59 > [class*="col"] {
	padding-left: 59px;
	padding-right: 59px;
}

.row-60 {
	margin-left: -60px;
	margin-right: -60px;
}

.row-60 > [class*="col"] {
	padding-left: 60px;
	padding-right: 60px;
}

.row-61 {
	margin-left: -61px;
	margin-right: -61px;
}

.row-61 > [class*="col"] {
	padding-left: 61px;
	padding-right: 61px;
}

.row-62 {
	margin-left: -62px;
	margin-right: -62px;
}

.row-62 > [class*="col"] {
	padding-left: 62px;
	padding-right: 62px;
}

.row-63 {
	margin-left: -63px;
	margin-right: -63px;
}

.row-63 > [class*="col"] {
	padding-left: 63px;
	padding-right: 63px;
}

.row-64 {
	margin-left: -64px;
	margin-right: -64px;
}

.row-64 > [class*="col"] {
	padding-left: 64px;
	padding-right: 64px;
}

.row-65 {
	margin-left: -65px;
	margin-right: -65px;
}

.row-65 > [class*="col"] {
	padding-left: 65px;
	padding-right: 65px;
}

.row-66 {
	margin-left: -66px;
	margin-right: -66px;
}

.row-66 > [class*="col"] {
	padding-left: 66px;
	padding-right: 66px;
}

.row-67 {
	margin-left: -67px;
	margin-right: -67px;
}

.row-67 > [class*="col"] {
	padding-left: 67px;
	padding-right: 67px;
}

.row-68 {
	margin-left: -68px;
	margin-right: -68px;
}

.row-68 > [class*="col"] {
	padding-left: 68px;
	padding-right: 68px;
}

.row-69 {
	margin-left: -69px;
	margin-right: -69px;
}

.row-69 > [class*="col"] {
	padding-left: 69px;
	padding-right: 69px;
}

.row-70 {
	margin-left: -70px;
	margin-right: -70px;
}

.row-70 > [class*="col"] {
	padding-left: 70px;
	padding-right: 70px;
}

.row-71 {
	margin-left: -71px;
	margin-right: -71px;
}

.row-71 > [class*="col"] {
	padding-left: 71px;
	padding-right: 71px;
}

.row-72 {
	margin-left: -72px;
	margin-right: -72px;
}

.row-72 > [class*="col"] {
	padding-left: 72px;
	padding-right: 72px;
}

.row-73 {
	margin-left: -73px;
	margin-right: -73px;
}

.row-73 > [class*="col"] {
	padding-left: 73px;
	padding-right: 73px;
}

.row-74 {
	margin-left: -74px;
	margin-right: -74px;
}

.row-74 > [class*="col"] {
	padding-left: 74px;
	padding-right: 74px;
}

.row-75 {
	margin-left: -75px;
	margin-right: -75px;
}

.row-75 > [class*="col"] {
	padding-left: 75px;
	padding-right: 75px;
}

.row-76 {
	margin-left: -76px;
	margin-right: -76px;
}

.row-76 > [class*="col"] {
	padding-left: 76px;
	padding-right: 76px;
}

.row-77 {
	margin-left: -77px;
	margin-right: -77px;
}

.row-77 > [class*="col"] {
	padding-left: 77px;
	padding-right: 77px;
}

.row-78 {
	margin-left: -78px;
	margin-right: -78px;
}

.row-78 > [class*="col"] {
	padding-left: 78px;
	padding-right: 78px;
}

.row-79 {
	margin-left: -79px;
	margin-right: -79px;
}

.row-79 > [class*="col"] {
	padding-left: 79px;
	padding-right: 79px;
}

.row-80 {
	margin-left: -80px;
	margin-right: -80px;
}

.row-80 > [class*="col"] {
	padding-left: 80px;
	padding-right: 80px;
}

/* no gutters */

.no-gutters {
	margin-left: 0;
	margin-right: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
	margin: 0;
}

/*=====  End of Helper CSS  ======*/

/*=============================================
=            Default CSS            =
=============================================*/

/*-- Google Font --*/

*,
*::after,
*::before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	line-height: 1.66;
	font-size: 0.9375rem;
	font-style: normal;
	font-weight: 400;
	visibility: visible;
	font-family: "Roboto", sans-serif;
	color: #888;
	position: relative;
	background-color: #ffffff;
}

body.no-overflow {
	overflow: hidden;
}

body.boxed {
	max-width: 1500px;
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
	margin: 0 auto;
	height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #222;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	margin-top: 0;
	line-height: 1.23;
}

h1 {
	font-size: 2rem;
}

h2 {
	font-size: 1.5625rem;
}

h3 {
	font-size: 1.375rem;
}

h4 {
	font-size: 1rem;
}

h5 {
	font-size: 0.875rem;
}

h6 {
	font-size: 0.5625rem;
}

p:last-child {
	margin-bottom: 0;
}

a,
button {
	color: inherit;
	display: inline-block;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
}

a,
button,
img,
input,
span {
	-webkit-transition: all 0.1s ease 0s;
	-o-transition: all 0.1s ease 0s;
	transition: all 0.1s ease 0s;
}

*:focus {
	outline: none !important;
}

a:focus {
	color: inherit;
	outline: none;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

button,
input[type="submit"] {
	cursor: pointer;
}

ul {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}

/*-- Tab Content & Pane Fix --*/

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	padding-right: 15px;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='black'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: 95% 50%;
}

.slick-slide > div > div {
	vertical-align: middle;
}

/*----------  Sharp border tippyjs theme  ----------*/

/* If `animateFill: true` (default) */

.tippy-tooltip.sharpborder__yellow-theme .tippy-backdrop {
	background-color: #ffc246;
	font-weight: 400;
	color: #fff;
}

/* If `animateFill: false` */

.tippy-tooltip.sharpborder__yellow-theme {
	font-weight: 400;
	border-radius: 0;
	font-size: 12px;
	background-color: #ffc246;
	color: #fff;
	line-height: 12px;
	white-space: nowrap;
	padding: 8px 10px;
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.sharpborder__yellow-theme .tippy-arrow {
	border-bottom-color: #ffc246;
}

/* If `animateFill: true` (default) */

.tippy-tooltip.sharpborder__black-theme .tippy-backdrop {
	background-color: #000000;
	font-weight: 400;
	color: #fff;
}

/* If `animateFill: false` */

.tippy-tooltip.sharpborder__black-theme {
	font-weight: 400;
	border-radius: 0;
	font-size: 12px;
	background-color: #000000;
	color: #fff;
	line-height: 12px;
	white-space: nowrap;
	padding: 8px 10px;
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.sharpborder__black-theme .tippy-arrow {
	border-bottom-color: #000000;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.sharpborder__black-theme .tippy-arrow {
	border-top-color: #000000;
}

.tippy-popper[x-placement^='left'] .tippy-tooltip.sharpborder__black-theme .tippy-arrow {
	border-left-color: #000000;
}

.border {
	border: 1px solid #eee !important;
}

.border-top {
	border-top: 1px solid #eee !important;
}

.border-right {
	border-right: 1px solid #eee !important;
}

.border-bottom {
	border-bottom: 1px solid #eee !important;
}

.border-left {
	border-left: 1px solid #eee !important;
}

/* slider default style */

.ht-swiper-button-nav {
	position: absolute;
	cursor: pointer;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
	background-image: none;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline: none !important;
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 18px;
	font-weight: 500;
	color: #222;
	border: 2px solid rgba(34, 34, 34, 0.16);
	border-radius: 100%;
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 2;
}

.ht-swiper-button-nav:hover {
	background-color: #ffc246;
	border-color: #ffc246;
	color: #ffffff;
}

.ht-swiper-button-prev {
	left: 0;
}

.ht-swiper-button-next {
	right: 0;
}

.swiper-pagination {
	position: static;
	display: block;
	max-width: 100%;
	text-align: center;
	width: auto;
	z-index: 7;
	line-height: 1;
}

.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	cursor: pointer;
	margin: 0 5px;
}

.swiper-pagination-bullet-active {
	background-color: #222;
	cursor: default;
}

.swiper-pagination .swiper-pagination-bullet {
	vertical-align: bottom;
}

input::-webkit-input-placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input::-moz-placeholder {
	-moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input:-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input::-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input::placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.vertical-navigation-home-wrapper {
	margin-left: 300px;
}

/*=====  End of Default CSS  ======*/

/*=============================================
=            spacing            =
=============================================*/

.section-space--inner--30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.section-space--inner--50 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section-space--inner--60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.section-space--inner--80 {
	padding-top: 80px;
	padding-bottom: 80px;
}

.section-space--inner--100 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.section-space--inner--120 {
	padding-top: 120px;
	padding-bottom: 120px;
}

.section-space--outter--120 {
	margin-top: 120px;
	margin-bottom: 120px;
}

.section-space--inner--140 {
	padding-top: 140px;
	padding-bottom: 140px;
}

.section-space--inner--190 {
	padding-top: 190px;
	padding-bottom: 190px;
}

.section-space--inner--top--30 {
	padding-top: 30px;
}

.section-space--inner--top--40 {
	padding-top: 40px;
}

.section-space--inner--top--120 {
	padding-top: 120px;
}

.section-space--inner--top--100 {
	padding-top: 100px;
}

.section-space--inner--top--200 {
	padding-top: 200px;
}

.section-space--inner--bottom--120 {
	padding-bottom: 120px;
}

.section-space--inner--bottom--30 {
	padding-bottom: 30px;
}

.section-space--inner--bottom--50 {
	padding-bottom: 50px;
}

.section-space--inner--bottom--300 {
	padding-bottom: 300px;
}

.section-space--inner--left--30 {
	padding-left: 30px;
}

.section-space--top--30 {
	margin-top: 30px !important;
}

.section-space--top--m30 {
	margin-top: -30px !important;
}

.section-space--top--60 {
	margin-top: 60px;
}

.section-space--top--80 {
	margin-top: 80px;
}

.section-space--bottom--20 {
	margin-bottom: 20px !important;
}

.section-space--bottom--30 {
	margin-bottom: 30px !important;
}

.section-space--bottom--m30 {
	margin-bottom: -30px !important;
}

.section-space--bottom--40 {
	margin-bottom: 40px !important;
}

.section-space--bottom--m40 {
	margin-bottom: -40px !important;
}

.section-space--bottom--50 {
	margin-bottom: 50px !important;
}

.section-space--bottom--60 {
	margin-bottom: 60px !important;
}

.section-space--bottom--80 {
	margin-bottom: 80px;
}

.section-space--bottom--120 {
	margin-bottom: 120px !important;
}

/*=====  End of spacing  ======*/

/*=============================================
=            Header            =
=============================================*/

.header-area {
	background-color: #ffffff;
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.04);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.04);
}

.header-area.header-sticky.is-sticky {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
	animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
	-webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}

.header-area.header-sticky.is-sticky .header-top-bar {
	display: none;
}

.header-area.header-sticky.is-sticky .search-form {
	display: none;
}

.header-area.header-sticky.is-sticky .header-navigation {
	-webkit-flex-basis: calc(100% - 400px);
	-ms-flex-preferred-size: calc(100% - 400px);
	flex-basis: calc(100% - 400px);
}

.header-area.header-sticky.is-sticky .header-search {
	-webkit-flex-basis: 180px;
	-ms-flex-preferred-size: 180px;
	flex-basis: 180px;
}

.header-area.header-sticky.is-sticky .header-below {
	display: none;
}

.header-area.header-sticky.is-sticky.header-area--transparent .header-top-area {
	display: none;
}

.header-area.header-sticky.is-sticky.header-area--transparent .header-navigation-area--transparent-bg {
	background-color: #ffffff;
}

.header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__nav--transparent nav > ul > li > a {
	color: #222;
}

.header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__nav--transparent nav > ul > li > a:hover {
	color: #ffc246;
}

.header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__icon--transparent .header-navigation__icon__search a {
	color: #222;
}

.header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__icon--transparent .header-navigation__icon__search a:hover {
	color: #ffc246;
}

.header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__icon--transparent .header-navigation__icon__cart a {
	color: #222;
}

.header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__icon--transparent .header-navigation__icon__cart a:hover {
	color: #ffc246;
}

.header-area.header-sticky.is-sticky .header-top-bar-area {
	display: none;
}

.header-area.header-sticky.is-sticky .header-navigation-wrapper--style5 .header-navigation {
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.header-area.header-sticky.is-sticky .header-navigation-wrapper--style5 .header-navigation__nav nav > ul > li > a {
	padding: 35px 15px;
}

.header-area.header-sticky.is-sticky .header-bottom-bar {
	display: none;
}

.header-area.header-sticky--default.is-sticky .header-info-area {
	display: none;
}

.header-area__desktop--default .mobile-navigation-icon {
	display: none;
}

.header-area__desktop--default .mobile-navigation-icon i {
	background-color: #222;
}

.header-area__desktop--default .mobile-navigation-icon i:before {
	background-color: #222;
}

.header-area__desktop--default .mobile-navigation-icon i:after {
	background-color: #222;
}

.header-area__desktop .header-navigation-area--transparent-bg {
	border-top: 1px solid rgba(238, 238, 238, 0.18);
}

.header-area__desktop .header-navigation-area--style2 {
	padding-left: 60px;
}

.header-area__desktop .header-navigation-area--style3 {
	background-color: #05103B;
}

.header-area__desktop--style3 {
	display: block !important;
}

.header-area__mobile {
	display: none;
}

.header-area__mobile .logo {
	-webkit-flex-basis: 0;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	padding-right: 0;
}

.header-area--absolute {
	position: absolute;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
}

.header-area--transparent {
	background-color: transparent;
}

.language-change-trigger--mobile .selected-lang {
	color: rgba(34, 34, 34, 0.4);
}

.header-top-bar {
	font-weight: 400;
	line-height: 1;
	padding: 8px 0;
	color: #ddd;
	background-color: #222;
	font-size: 0.875rem;
}

.header-top-bar--white {
	padding: 0;
	color: #888;
	border-bottom: 1px solid #eee;
	background-color: #ffffff;
}

.header-top-bar--style2 {
	padding-top: 15px;
	padding-bottom: 15px;
}

.topbar-menu li {
	display: inline-block;
	margin-right: 15px;
}

.topbar-menu li:last-child {
	margin-right: 0;
}

.topbar-menu li a {
	display: block;
}

.topbar-menu li a:hover {
	color: #ffc246;
}

.topbar-menu--bold {
	padding: 0 15px;
}

.topbar-menu--bold li a {
	font-weight: 500;
	line-height: 1;
	color: #ffffff;
}

.top-bar-right-wrapper {
	text-align: right;
}

.top-bar-right-wrapper .text a {
	color: #05103B;
}

.top-bar-right-wrapper .text a:hover {
	color: #ffc246;
}

.topbar-info {
	display: inline-block;
}

.topbar-info li {
	display: inline-block;
	margin-right: 30px;
}

.topbar-info li:last-child {
	margin-right: 0;
}

.topbar-info li a {
	display: block;
}

.topbar-info li a:hover {
	color: #ffc246;
}

.topbar-info li a i {
	line-height: 1;
	margin-right: 5px;
	vertical-align: middle;
	font-size: 1.125rem;
}

.topbar-info--style2 li {
	margin-right: 0;
	padding: 10px 30px;
	border-right: 1px solid #eee;
	font-size: 0.875rem;
}

.topbar-info--style2 li i {
	color: #ffc246;
}

.topbar-info--style3 li {
	margin-right: 0;
	padding: 10px 20px;
	font-size: 0.875rem;
}

.topbar-info--style3 li:first-child {
	padding-left: 0;
}

.topbar-info--style3 li i {
	color: #ffc246;
}

.topbar-info--style4 li {
	margin-right: 0;
	padding: 0 20px;
	font-size: 0.875rem;
}

.topbar-info--style4 li:first-child {
	padding-left: 0;
}

.topbar-info--style4 li i {
	color: #ffc246;
}

.language-change-wrapper {
	font-weight: 700;
	line-height: 1;
	position: relative;
	display: inline-block;
	margin-left: 30px;
	cursor: pointer;
	color: #ddd;
	font-size: 0.875rem;
}

.language-change-wrapper a:hover {
	color: #ffc246;
}

.language-change-wrapper .language-flag {
	position: relative;
	display: inline-block;
	overflow: hidden;
	width: 24px;
	height: 24px;
	margin-right: 9px;
	vertical-align: middle;
	border-radius: 100%;
}

.language-change-wrapper .language-flag img {
	position: absolute;
	top: -50%;
	left: -50%;
	width: 48px;
	max-width: 48px;
	height: 48px;
}

.language-change-wrapper .selected-lang i {
	vertical-align: middle;
	font-size: 0.5rem;
}

.language-change-wrapper .language-dropdown {
	position: absolute;
	z-index: 9;
	top: 100%;
	right: 0;
	left: auto;
	visibility: hidden;
	min-width: 200px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transform: translateY(20px);
	-ms-transform: translateY(20px);
	transform: translateY(20px);
	text-align: left;
	opacity: 0;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
	box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
}

.language-change-wrapper .language-dropdown li a {
	display: block;
	padding: 10px;
	color: #222;
	font-size: 0.8125rem;
}

.language-change-wrapper .language-dropdown li a:hover {
	background: #eee;
}

.language-change-wrapper .language-dropdown li a img {
	width: 18px;
	margin-right: 10px;
}

.language-change-wrapper:hover .language-dropdown {
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

.language-change-wrapper--style2 {
	margin-right: 20px;
	margin-left: 0;
}

.language-change-wrapper--style2 .selected-lang {
	color: #222;
}

.language-change-wrapper--style2 .language-dropdown {
	left: 0;
}

.language-change-wrapper--style3 {
	margin-left: 0;
	padding: 0 15px;
	border-left: 1px solid rgba(238, 238, 238, 0.18);
}

.language-change-wrapper--style3 .selected-lang {
	color: #ffffff;
}

.language-change-wrapper--style3 .selected-lang:hover {
	color: #ffc246;
}

.header-navigation-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-navigation-wrapper--style3__left {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.header-navigation-wrapper--style3__right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.header-navigation-wrapper--style4 {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding-left: 35px;
	border-left: 1px solid #eee;
}

.header-navigation-wrapper--style5 .header-navigation {
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.header-navigation-wrapper--style5 .header-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.logo {
	-webkit-flex-basis: 220px;
	-ms-flex-preferred-size: 220px;
	flex-basis: 220px;
	padding-right: 30px;
}

.logo a img {
	max-width: 160px;
}

.header-navigation {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-basis: calc(100% - 535px);
	-ms-flex-preferred-size: calc(100% - 535px);
	flex-basis: calc(100% - 535px);
}

.header-navigation__nav {
	position: relative;
	-webkit-flex-basis: calc(100% - 260px);
	-ms-flex-preferred-size: calc(100% - 260px);
	flex-basis: calc(100% - 260px);
	padding: 0 20px;
}

.header-navigation__nav nav > ul {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.header-navigation__nav nav > ul > li {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-navigation__nav nav > ul > li > a {
	white-space: nowrap;
}

.header-navigation__nav nav > ul > li.has-children > a {
	position: relative;
}

.header-navigation__nav nav > ul > li.has-children > a:after {
	font-family: Ionicons;
	font-size: 8px;
	font-weight: 500;
	position: static;
	margin-left: 10px;
	content: "\f123";
	vertical-align: middle;
}

.header-navigation__nav nav > ul > li.has-children--multilevel-submenu {
	position: relative;
}

.header-navigation__nav nav > ul > li.has-children:hover .megamenu {
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

.header-navigation__nav nav > ul > li.has-children:hover .megamenu--home-variation__item {
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

.header-navigation__nav nav > ul > li.has-children:hover > .submenu {
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

.header-navigation__nav nav > ul > li > a {
	font-weight: 700;
	line-height: 1.26;
	position: relative;
	display: block;
	padding: 46px 16px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	color: #222;
	font-size: 0.8125rem;
}

.header-navigation__nav nav > ul > li > a:before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 0;
	height: 3px;
	content: "";
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-color: #ffc246;
}

.header-navigation__nav nav > ul > li:hover > a {
	color: #ffc246;
}

.header-navigation__nav nav > ul > li:hover > a:before {
	right: auto;
	left: 0;
	width: 100%;
}

.header-navigation__nav--style3 {
	padding: 0;
	padding-left: 30px;
}

.header-navigation__nav--style3 nav > ul > li:first-child > a {
	padding-left: 0;
}

.header-navigation__nav--style3 nav > ul > li > a {
	padding: 25px 16px;
}

.header-navigation__nav--style3 nav > ul > li > a:before {
	display: none;
}

.header-navigation__nav--style4 nav > ul > li:first-child > a {
	padding-left: 0;
}

.header-navigation__nav--style4 nav > ul > li > a {
	padding: 40px 15px;
}

.header-navigation__nav--style4 nav > ul > li > a:before {
	display: none;
}

.header-navigation__nav--style5 nav > ul > li:first-child > a {
	padding-left: 0;
}

.header-navigation__nav--style5 nav > ul > li > a {
	padding: 30px 15px;
}

.header-navigation__nav--style5 nav > ul > li > a:before {
	display: none;
}

.header-navigation__nav--style6 {
	padding-left: 0;
}

.header-navigation__nav--style6 nav > ul > li:first-child > a {
	padding-left: 0;
}

.header-navigation__nav--style6 nav > ul > li > a {
	padding: 35px 15px;
	color: #ffffff;
}

.header-navigation__nav--style6 nav > ul > li > a:before {
	display: none;
}

.header-navigation__nav--style7 {
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	margin-right: 30px;
}

.header-navigation__nav--style7 nav > ul > li > a {
	color: #ffffff;
}

.header-navigation__nav--vertical-mode {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	width: 100%;
	padding: 0;
}

.header-navigation__nav--vertical-mode nav > ul {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.header-navigation__nav--vertical-mode nav > ul > li > a {
	padding: 15px 0 !important;
}

.header-navigation__nav--vertical-mode nav > ul > li > a:before {
	display: none;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children {
	position: relative;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children > a:after {
	position: absolute;
	top: 50%;
	right: 0;
	content: "\f363";
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 1.125rem;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children .megamenu {
	top: 0;
	left: 110%;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children > .submenu {
	top: 0;
	left: 110%;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children > .submenu .submenu {
	top: 0;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children .megamenu--home-variation {
	top: -100px;
	min-width: 1100px;
}

.header-navigation__contact {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-basis: 260px;
	-ms-flex-preferred-size: 260px;
	flex-basis: 260px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding-right: 30px;
}

.header-navigation__contact__image {
	width: 35px;
	margin-right: 20px;
}

.header-navigation__contact__image i {
	color: #ffc246;
	font-size: 3rem;
}

.header-navigation__contact__content {
	width: 250px;
	word-wrap: break-word;
}

.header-navigation__contact__content .text {
	line-height: 1;
	display: inline-block;
	margin-bottom: 5px;
	font-size: 0.875rem;
}

.header-navigation__contact__content .sub-text {
	font-weight: 700;
	font-size: 1.5rem;
}

.header-navigation__icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	color: #222;
}

.header-navigation__icon i {
	font-size: 1.25rem;
}

.header-navigation__icon__search > a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-navigation__icon__search > a:hover {
	color: #ffc246;
}

.header-navigation__icon__cart {
	position: relative;
	margin-left: 20px;
}

.header-navigation__icon__cart > a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-navigation__icon__cart > a:hover {
	color: #ffc246;
}

.header-navigation__icon__cart:before {
	font-size: 10px;
	font-weight: 500;
	line-height: 15px;
	position: absolute;
	top: -5px;
	right: -10px;
	width: 15px;
	height: 15px;
	padding: 0 4px;
	content: attr(data-count);
	text-align: center;
	color: #222;
	border-radius: 100%;
	background: #ffc246;
}

.header-navigation__icon__cart .minicart-box {
	position: absolute;
	z-index: 99999;
	top: calc(100% + 15px);
	right: 0;
	visibility: hidden;
	overflow: auto;
	width: 360px;
	max-height: 700px;
	padding: 30px 20px 27px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transform: translateY(30px);
	-ms-transform: translateY(30px);
	transform: translateY(30px);
	opacity: 0;
	border-bottom: 3px solid #ffc246;
	background-color: #fff;
	-webkit-box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
	box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
}

.header-navigation__icon__cart .minicart-box.active {
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

.header-navigation__icon--transparent .header-navigation__icon__search > a {
	color: #ffffff;
}

.header-navigation__icon--transparent .header-navigation__icon__search > a:hover {
	color: #ffc246;
}

.header-navigation__icon--transparent .header-navigation__icon__cart > a {
	color: #ffffff;
}

.header-navigation__icon--transparent .header-navigation__icon__cart > a:hover {
	color: #ffc246;
}

.header-navigation--header-default {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-basis: 0;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.header-navigation--header-default .header-navigation__nav {
	padding: 0;
}

.header-navigation--header-default .header-navigation__nav nav > ul > li {
	border-left: 1px solid #eee;
}

.header-navigation--header-default .header-navigation__nav nav > ul > li:last-child {
	border-right: 1px solid #eee;
}

.header-navigation--header-default .header-navigation__nav nav > ul > li > a {
	padding: 25px 20px;
}

.header-navigation--header-default .header-navigation__nav nav > ul > li > a:before {
	top: 0;
}

.header-navigation--header-default .header-navigation__nav--transparent nav > ul > li {
	margin-right: 30px;
	border-left: 0;
}

.header-navigation--header-default .header-navigation__nav--transparent nav > ul > li:last-child {
	margin-right: 0;
	border-right: 0;
}

.header-navigation--header-default .header-navigation__nav--transparent nav > ul > li > a {
	padding-right: 0;
	padding-left: 0;
	color: #ffffff;
}

.header-search {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-flex-basis: 315px;
	-ms-flex-preferred-size: 315px;
	flex-basis: 315px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 315px;
}

.header-search--style3 {
	position: relative;
	height: 100%;
	border-left: 1px solid #eee; /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */
}

.header-search--style3 form {
	height: 100%;
}

.header-search--style3 input {
	width: 390px;
	height: 100%;
	padding: 0 20px;
	color: #222;
	border: none;
}

.header-search--style3 input::-webkit-input-placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style3 input::-moz-placeholder {
	-moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style3 input:-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style3 input::-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style3 input::placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style3 button {
	font-size: 20px;
	line-height: 40px;
	position: absolute;
	top: 50%;
	right: 0;
	width: 40px;
	height: 40px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #222;
	border: none;
	background: none;
}

.header-search--style3 button:hover {
	color: #ffc246;
}

.header-search--style3 input:focus::-webkit-input-placeholder {
	color: transparent;
}

.header-search--style3 input:focus:-moz-placeholder {
	color: transparent;
}

.header-search--style3 input:focus::-moz-placeholder {
	color: transparent;
}

.header-search--style3 input:focus:-ms-input-placeholder {
	color: transparent;
}

.header-search--style5 {
	position: relative;
	height: 100%;
	border-left: 1px solid #eee; /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */
}

.header-search--style5 form {
	height: 100%;
}

.header-search--style5 input {
	width: 390px;
	height: 100%;
	padding: 20px;
	color: #222;
	border: none;
}

.header-search--style5 input::-webkit-input-placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style5 input::-moz-placeholder {
	-moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style5 input:-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style5 input::-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style5 input::placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-search--style5 button {
	font-size: 20px;
	line-height: 40px;
	position: absolute;
	top: 50%;
	right: 0;
	width: 40px;
	height: 40px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #222;
	border: none;
	background: none;
}

.header-search--style5 button:hover {
	color: #ffc246;
}

.header-search--style5 input:focus::-webkit-input-placeholder {
	color: transparent;
}

.header-search--style5 input:focus:-moz-placeholder {
	color: transparent;
}

.header-search--style5 input:focus::-moz-placeholder {
	color: transparent;
}

.header-search--style5 input:focus:-ms-input-placeholder {
	color: transparent;
}

.search-form {
	position: relative;
	width: 250px;
	max-width: 100%;
	margin-bottom: 20px; /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */
}

.search-form input {
	font-weight: 400;
	width: 100%;
	height: 38px;
	padding-right: 40px;
	color: #222;
	border: none;
	border-bottom: 1px solid #ddd;
	background: none;
}

.search-form input:focus {
	border-bottom-color: #ffc246;
}

.search-form input::-webkit-input-placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-form input::-moz-placeholder {
	-moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-form input:-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-form input::-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-form input::placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-form input:focus::-webkit-input-placeholder {
	color: transparent;
}

.search-form input:focus:-moz-placeholder {
	color: transparent;
}

.search-form input:focus::-moz-placeholder {
	color: transparent;
}

.search-form input:focus:-ms-input-placeholder {
	color: transparent;
}

.search-form button {
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	border: none;
	background: none;
	font-size: 1.25rem;
}

.search-form--style2 {
	width: 300px;
}

.search-form--style2 input::-webkit-input-placeholder {
	font-style: italic;
}

.search-form--style2 input::-moz-placeholder {
	font-style: italic;
}

.search-form--style2 input:-ms-input-placeholder {
	font-style: italic;
}

.search-form--style2 input::-ms-input-placeholder {
	font-style: italic;
}

.search-form--style2 input::placeholder {
	font-style: italic;
}

.social-links ul li {
	display: inline-block;
	margin-right: 20px;
}

.social-links ul li:last-child {
	margin-right: 0;
}

.social-links ul li a {
	display: block;
	color: rgba(34, 34, 34, 0.4);
}

.social-links ul li a:hover {
	color: #222;
}

.social-links--white-topbar {
	margin-right: 30px;
}

.social-links--white-topbar ul li a {
	font-size: 16px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	color: #bdbdbd;
}

.social-links--white-topbar ul li a:hover {
	color: #ffc246;
}

.social-links--style2 ul li a {
	color: #888;
	font-size: 1.125rem;
}

.social-links--style3 ul li a {
	color: #bdbdbd;
	font-size: 1.125rem;
}

.social-links--style3 ul li a:hover {
	color: #ffc246;
}

.social-links--style4 ul li a {
	color: #222;
	font-size: 1.125rem;
}

.social-links--style4 ul li a:hover {
	color: #222;
}

.social-links--style5 ul li a {
	color: #ffffff;
	font-size: 1.125rem;
}

.social-links--style5 ul li a:hover {
	color: #ffc246;
}

.megamenu {
	position: absolute;
	z-index: 9;
	top: 100%;
	left: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
	-webkit-transition-duration: 0.6s;
	-o-transition-duration: 0.6s;
	transition-duration: 0.6s;
	-webkit-transform: translateY(50px);
	-ms-transform: translateY(50px);
	transform: translateY(50px);
	opacity: 0;
	border-bottom: 3px solid #ffc246;
	background-color: #ffffff;
	-webkit-box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
	box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
}

.megamenu--home-variation {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
	min-width: 980px;
	padding: 60px 40px 50px;
}

.megamenu--home-variation__item {
	visibility: hidden;
	-webkit-flex-basis: 17%;
	-ms-flex-preferred-size: 17%;
	flex-basis: 17%;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
	-webkit-transform: translateY(100px);
	-ms-transform: translateY(100px);
	transform: translateY(100px);
	text-align: center;
	opacity: 0;
}

.megamenu--home-variation__item:nth-child(1n+6) {
	margin-top: 50px;
}

.megamenu--home-variation__item > a {
	display: block;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.megamenu--home-variation__item > a:hover {
	-webkit-transform: translateY(-10px) !important;
	-ms-transform: translateY(-10px) !important;
	transform: translateY(-10px) !important;
}

.megamenu--home-variation__item > a:hover .megamenu--home-variation__item__image {
	-webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.16);
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.16);
}

.megamenu--home-variation__item__image {
	margin-bottom: 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.17);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.17);
}

.megamenu--home-variation__item__title {
	font-size: 1.125rem;
}

.megamenu--mega {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	width: 100%;
	min-width: 980px;
	padding: 50px 25px 35px;
}

.megamenu--mega > li {
	-webkit-flex-basis: 22%;
	-ms-flex-preferred-size: 22%;
	flex-basis: 22%;
}

.megamenu--mega > li .page-list-title {
	margin-bottom: 20px;
	padding-bottom: 10px;
	letter-spacing: 1px;
	border-bottom: 1px solid #eee;
	font-size: 1rem;
}

.megamenu--mega > li > ul > li {
	position: relative;
	padding-left: 15px;
}

.megamenu--mega > li > ul > li:after {
	position: absolute;
	top: 50%;
	left: 0;
	width: 5px;
	height: 5px;
	content: "";
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	border-radius: 50%;
	background-color: #d8d8d8;
}

.megamenu--mega > li > ul > li a {
	line-height: 1.2;
	padding: 10px 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	color: #999;
}

.megamenu--mega > li > ul > li a:hover {
	color: #ffc246;
}

.megamenu--tab {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-width: 980px;
}

.megamenu--tab .bg-img--menu {
	background-repeat: no-repeat;
	background-position: left top;
	background-size: cover;
}

.megamenu--tab__menu {
	-webkit-flex-basis: 240px;
	-ms-flex-preferred-size: 240px;
	flex-basis: 240px;
	padding: 35px 0;
}

.megamenu--tab__menu .nav-tabs {
	border-bottom: 0;
}

.megamenu--tab__menu .nav-tabs .nav-link {
	font-weight: 500;
	position: relative;
	padding: 15px 30px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	color: #999;
	border: none;
	border-radius: 0;
}

.megamenu--tab__menu .nav-tabs .nav-link:after {
	position: absolute;
	top: 50%;
	left: calc(100% - 6px);
	visibility: hidden;
	width: 0;
	height: 0;
	margin-top: -6px;
	content: " ";
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-duration: 0.2s;
	-o-transition-duration: 0.2s;
	transition-duration: 0.2s;
	pointer-events: none;
	opacity: 0;
	border: solid transparent;
	border-width: 6px;
	border-color: rgba(255, 194, 70, 0);
	border-left-color: #ffc246;
}

.megamenu--tab__menu .nav-tabs .nav-link.active,
.megamenu--tab__menu .nav-tabs .nav-link:hover {
	color: #222;
	background-color: #ffc246;
}

.megamenu--tab__menu .nav-tabs .nav-link.active:after,
.megamenu--tab__menu .nav-tabs .nav-link:hover:after {
	left: 100%;
	visibility: visible;
	opacity: 1;
}

.megamenu--tab__content {
	-webkit-flex-basis: calc(100% - 240px);
	-ms-flex-preferred-size: calc(100% - 240px);
	flex-basis: calc(100% - 240px);
}

.megamenu--tab__content .tab-pane {
	padding: 60px 40px;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li {
	-webkit-flex-basis: 28%;
	-ms-flex-preferred-size: 28%;
	flex-basis: 28%;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li .page-list-title {
	margin-bottom: 20px;
	padding-bottom: 10px;
	letter-spacing: 1px;
	border-bottom: 1px solid #eee;
	font-size: 1rem;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li > ul > li {
	position: relative;
	padding-left: 15px;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li > ul > li:after {
	position: absolute;
	top: 50%;
	left: 0;
	width: 5px;
	height: 5px;
	content: "";
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	border-radius: 50%;
	background-color: #d8d8d8;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li > ul > li a {
	line-height: 1.2;
	padding: 10px 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	color: #999;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li > ul > li a:hover {
	color: #ffc246;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li .menu-widget__image {
	margin-bottom: 20px;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li .menu-widget__title {
	font-weight: 500;
	line-height: 19px;
	font-size: 1rem;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li .menu-widget__short-desc {
	line-height: 1.1;
	margin-top: 15px;
	margin-bottom: 0;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li .menu-widget__link {
	font-weight: 700;
	line-height: 1.1;
	position: relative;
	margin-top: 20px;
	padding-bottom: 2px;
	color: #ffc246;
	border-bottom: 2px solid #eee;
	font-size: 0.8125rem;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li .menu-widget__link:after {
	position: absolute;
	right: 0;
	bottom: -2px;
	width: 0;
	height: 2px;
	content: "";
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-color: #ffc246;
}

.megamenu--tab__content .tab-pane .megamenu-tab-wrapper > li .menu-widget__link:hover:after {
	right: auto;
	left: 0;
	width: 100%;
}

.megamenu--tab__content .tab-pane .menu-item-list-desc {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: -26px;
}

.megamenu--tab__content .tab-pane .menu-item-list-desc__single {
	position: relative;
	-webkit-flex-basis: 49%;
	-ms-flex-preferred-size: 49%;
	flex-basis: 49%;
	padding-left: 20px;
}

.megamenu--tab__content .tab-pane .menu-item-list-desc__single:after {
	font-family: Ionicons;
	font-size: 8px;
	position: absolute;
	top: 3px;
	left: 0;
	display: block;
	content: "\f400";
	color: #b6b6b6;
}

.megamenu--tab__content .tab-pane .menu-item-list-desc__single .title {
	font-weight: 400;
	line-height: 1.2;
	margin-bottom: 0;
	font-size: 0.9375rem;
}

.megamenu--tab__content .tab-pane .menu-item-list-desc__single .title a {
	color: #222;
}

.megamenu--tab__content .tab-pane .menu-item-list-desc__single .title a:hover {
	text-decoration: underline;
}

.megamenu--tab__content .tab-pane .menu-item-list-desc__single .desc {
	margin-top: 13px;
	margin-bottom: 26px;
}

.submenu {
	position: absolute;
	z-index: 9;
	top: 100%;
	left: 0;
	visibility: hidden;
	min-width: 270px;
	padding: 25px 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
	-webkit-transition-duration: 0.6s;
	-o-transition-duration: 0.6s;
	transition-duration: 0.6s;
	-webkit-transform: translateY(50px);
	-ms-transform: translateY(50px);
	transform: translateY(50px);
	opacity: 0;
	border-bottom: 3px solid #ffc246;
	background-color: #ffffff;
	-webkit-box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
	box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
}

.submenu li {
	position: relative;
}

.submenu li > a {
	display: block;
	padding: 11px 30px;
	color: #999;
}

.submenu li > a:hover {
	color: #ffc246;
}

.submenu li:hover > .submenu {
	z-index: 9;
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

.submenu li.has-children > a {
	position: relative;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.submenu li.has-children > a:after {
	font-family: Ionicons;
	font-size: 8px;
	font-weight: 500;
	position: absolute;
	top: 50%;
	right: 20px;
	content: "\f125";
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	vertical-align: middle;
}

.submenu .submenu {
	top: 0;
	right: auto;
	left: 100%;
}

.submenu .submenu .submenu {
	right: 100%;
	left: auto;
	background: #ffffff;
}

.submenu .submenu .submenu .submenu {
	right: auto;
	left: 100%;
	background: #ffffff;
}

.submenu .submenu .submenu .submenu .submenu {
	right: 100%;
	left: auto;
	background: #ffffff;
}

/* header info area */

.header-info-area {
	padding: 40px 0;
}

.header-info-area--style2 {
	padding: 25px 0;
}

.header-info-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.header-info-wrapper .logo {
	-webkit-flex-basis: 395px;
	-ms-flex-preferred-size: 395px;
	flex-basis: 395px;
	width: 395px;
}

.header-info-wrapper .header-contact-info {
	width: calc(100% - 395px);
}

.header-info-wrapper--style2 {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.header-info-wrapper--style2 .header-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-info-single-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.header-info-single-item__icon {
	line-height: 1;
	padding-right: 15px;
	color: #ffc246;
}

.header-info-single-item__icon i {
	font-size: 2.375rem;
}

.header-info-single-item__title {
	font-weight: 500;
	margin-bottom: 5px;
	color: #222;
	font-size: 0.9375rem;
}

.header-info-single-item__subtitle {
	color: #888;
	font-size: 0.875rem;
}

/* mobile menu */

.mobile-menu-area {
	padding: 15px 0;
}

.mobile-menu-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.mobile-menu-content .social-links {
	padding: 0 15px;
}

.mobile-menu-content .mobile-navigation-icon {
	margin-left: 15px;
}

.mobile-navigation-icon {
	position: relative;
	width: 24px;
	height: 25px;
	cursor: pointer;
}

.mobile-navigation-icon:hover i {
	background-color: #222;
}

.mobile-navigation-icon:hover i:before {
	width: 80%;
	background-color: #222;
}

.mobile-navigation-icon:hover i:after {
	width: 60%;
	background-color: #222;
}

.mobile-navigation-icon i {
	position: absolute;
	top: 50%;
	width: 100%;
	height: 2px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: rgba(34, 34, 34, 0.4);
}

.mobile-navigation-icon i:before {
	position: absolute;
	bottom: 8px;
	left: 0;
	width: 100%;
	height: 2px;
	content: "";
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-color: rgba(34, 34, 34, 0.4);
}

.mobile-navigation-icon i:after {
	position: absolute;
	bottom: -8px;
	left: 0;
	width: 100%;
	height: 2px;
	content: "";
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-color: rgba(34, 34, 34, 0.4);
}

.mobile-navigation-icon--style3 i {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: #222;
}

.mobile-navigation-icon--style3 i:before {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: #222;
}

.mobile-navigation-icon--style3 i:after {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: #222;
}

.mobile-navigation-icon--style3:hover i:before {
	width: 60%;
}

.mobile-navigation-icon--style3:hover i:after {
	width: 60%;
}

.mobile-navigation-icon--desktop-off {
	display: none;
}

/* mobile menu overlay */

.mobile-menu-overlay {
	position: fixed;
	z-index: 9999;
	top: 0;
	right: 0;
	overflow: auto;
	width: 100vw;
	height: 100%;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
	background-color: #222;
}

.mobile-menu-overlay.active {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

.mobile-menu-overlay__header {
	padding: 15px 0;
	background-color: #ffffff;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon i {
	line-height: 1;
	font-size: 3.4375rem;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover {
	color: #ffc246;
}

.mobile-menu-overlay__body {
	padding: 15px 30px;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li {
	border-bottom: 1px solid #333333;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a {
	font-weight: 700;
	line-height: 1.5;
	display: block;
	padding-top: 18px;
	padding-bottom: 18px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	color: rgba(255, 255, 255, 0.7);
	font-size: 1.25rem;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a:hover {
	color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li:last-child {
	border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children {
	position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before {
	content: "\f3d0";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand {
	line-height: 40px;
	position: absolute;
	top: 12px;
	right: 0;
	width: 40px;
	height: 40px;
	cursor: pointer;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	text-align: center;
	color: #fff;
	background: rgba(255, 255, 255, 0.1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover {
	background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:before {
	font-family: "Ionicons";
	font-size: 24px;
	font-weight: 500;
	content: "\f3d3";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu {
	padding: 12px 0 14px 10px;
	border-top: 1px solid #333333;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li {
	border-bottom: 1px solid #333333;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a {
	font-size: 15px;
	font-weight: 500;
	line-height: 1.5;
	display: block;
	padding: 10px 0;
	color: rgba(255, 255, 255, 0.7);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover {
	color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child {
	border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children {
	position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before {
	content: "\f3d0";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand {
	line-height: 30px;
	position: absolute;
	top: 6px;
	right: 0;
	width: 30px;
	height: 30px;
	cursor: pointer;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	text-align: center;
	color: #fff;
	background: rgba(255, 255, 255, 0.1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover {
	background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before {
	font-family: "Ionicons";
	font-size: 16px;
	font-weight: 500;
	content: "\f3d3";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children {
	position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before {
	content: "\f3d0";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before {
	font-family: "Ionicons";
	font-size: 16px;
	font-weight: 500;
	content: "\f3d3";
}

/* minicart */

.minicart-product-wrapper {
	margin-bottom: 30px;
	border-bottom: 1px solid #ddd;
}

.minicart-product-wrapper .single-minicart-product {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 20px;
}

.minicart-product-wrapper .single-minicart-product__image {
	-webkit-flex-basis: 80px;
	-ms-flex-preferred-size: 80px;
	flex-basis: 80px;
	border: 1px solid #ddd;
}

.minicart-product-wrapper .single-minicart-product__content {
	position: relative;
	-webkit-flex-basis: calc(100% - 80px);
	-ms-flex-preferred-size: calc(100% - 80px);
	flex-basis: calc(100% - 80px);
	margin-left: 15px;
}

.minicart-product-wrapper .single-minicart-product__content .close-icon {
	font-weight: 700;
	position: absolute;
	top: 0;
	right: 0;
	color: #888;
}

.minicart-product-wrapper .single-minicart-product__content .close-icon i {
	font-size: 0.75rem;
}

.minicart-product-wrapper .single-minicart-product__content .close-icon:hover {
	color: #222;
}

.minicart-product-wrapper .single-minicart-product__content .title {
	font-weight: 700;
	line-height: 1.2;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	font-size: 1rem;
}

.minicart-product-wrapper .single-minicart-product__content .title:hover {
	color: #ffc246;
}

.minicart-product-wrapper .single-minicart-product__content .quantity {
	color: #888;
	font-size: 0.9375rem;
}

.minicart-product-wrapper .single-minicart-product__content .quantity .price {
	font-weight: 600;
	color: #ffc246;
}

.minicart-calculations {
	font-weight: 600;
	margin-bottom: 30px;
	color: #888;
	font-size: 1rem;
}

.minicart-calculations .value {
	float: right;
	color: #ffc246;
	font-size: 1.25rem;
}

.minicart-buttons {
	margin-bottom: -10px;
}

.minicart-link {
	font-size: 13px;
	font-weight: 700;
	line-height: 46px;
	display: block;
	height: 50px;
	margin-bottom: 10px;
	padding: 0 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	text-align: center;
	border: 2px solid #222;
	border-radius: 0;
}

.minicart-link--cart:hover {
	color: #ffffff;
	background-color: #222;
}

.minicart-link--checkout {
	color: #ffffff;
	background-color: #222;
}

.minicart-link--checkout:hover {
	color: #ffffff;
	border-color: #ffc246;
	background-color: #ffc246;
}

.header-wrapper--style3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.header-wrapper--style3__logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 30px;
	background-color: #ffc246;
}

.header-wrapper--style3__logo a {
	display: block;
}

.header-wrapper--style3__logo a img {
	padding: 15px 0;
}

.header-wrapper--style3__header-right {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.header-wrapper--vertical {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	width: 300px;
	height: 100%;
	-webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);
}

.header-wrapper--vertical__inner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	height: 100%;
	padding: 43px 44px 70px;
}

.header-wrapper--shadow {
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}

.header-wrapper__inner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.header-wrapper__inner .logo {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.header-wrapper__inner .header-navigation {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.header-wrapper__inner .header-navigation__nav {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.header-wrapper__inner .request-quote-button-wrapper {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.navigation-top--style3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.navigation-top--style3__left {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.navigation-top--style3__right {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.navigation-top--style3__right .topbar-menu {
	margin-right: 30px;
}

.cart-wrapper--style3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 80px;
	border-left: 1px solid #eee;
}

.cart-wrapper--style3 .header-navigation__icon__cart {
	display: inline-block;
	margin-left: 0;
	color: #222;
	font-size: 1.25rem;
}

.cart-wrapper--style3 .header-navigation__icon__cart:before {
	color: #ffffff;
}

.cart-wrapper--style3 .header-navigation__icon__cart--desktop-off {
	display: none;
}

.header-search--style4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 80px;
	border-left: 1px solid #eee;
}

.header-search--style4 .header-navigation__icon__search {
	display: inline-block;
	margin-left: 0;
	color: #222;
	font-size: 1.25rem;
}

.desktop-menu-wrapper--style3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 80px;
	border-left: 1px solid #eee;
}

/*----------  header overlay menu  ----------*/

.header-overlay-menu {
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
}

.header-overlay-menu:before {
	position: absolute;
	top: -3px;
	left: 0;
	width: 100%;
	height: 3px;
	content: "";
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.header-overlay-menu.is-sticky:before {
	display: none;
}

.header-overlay-menu.is-sticky .header-bottom .header-bottom-container .logo-with-offcanvas .overlay-menu-icon a {
	color: #333;
}

.header-overlay-menu.is-sticky .header-bottom .header-bottom-container .logo-with-offcanvas .overlay-menu-icon a i {
	font-size: 25px;
}

.header-overlay-menu:hover {
	border-bottom: none;
	background-color: #fff;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.header-overlay-menu:hover .header-bottom .header-bottom-container .logo-with-offcanvas .overlay-menu-icon a {
	color: #333;
}

.header-overlay-menu:hover .header-bottom .header-bottom-container .logo-with-offcanvas .overlay-menu-icon a i {
	font-size: 25px;
}

.header-overlay-menu .header-bottom .header-bottom-container .logo-with-offcanvas {
	-webkit-flex-basis: 50% !important;
	-ms-flex-preferred-size: 50% !important;
	flex-basis: 50% !important;
}

.header-overlay-menu .header-bottom .header-bottom-container .logo-with-offcanvas .overlay-menu-icon a {
	color: #ffffff;
}

.header-overlay-menu .header-bottom .header-bottom-container .logo-with-offcanvas .overlay-menu-icon a i {
	font-size: 25px;
}

.header-overlay-menu .header-bottom .header-bottom-container .header-bottom-navigation {
	display: none;
}

.header-overlay-menu .header-bottom .header-bottom-container .header-right-container {
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
}

.overlay-navigation-inactive {
	visibility: hidden;
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
	-webkit-transition-duration: 0.6s;
	-o-transition-duration: 0.6s;
	transition-duration: 0.6s;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	opacity: 0;
}

.overlay-navigation-inactive .overlay-navigation-menu-container {
	visibility: hidden;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	opacity: 0;
}

.overlay-navigation-inactive .overlay-navigation-menu-container nav ul li {
	-webkit-transition-delay: 0.5s;
	-o-transition-delay: 0.5s;
	transition-delay: 0.5s;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transform: translateY(50px);
	-ms-transform: translateY(50px);
	transform: translateY(50px);
	opacity: 0;
}

.overlay-navigation-active {
	visibility: visible;
	-webkit-transition-duration: 0.6s;
	-o-transition-duration: 0.6s;
	transition-duration: 0.6s;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

.overlay-navigation-active .overlay-navigation-menu-container nav ul li {
	-webkit-transition-delay: 0.5s;
	-o-transition-delay: 0.5s;
	transition-delay: 0.5s;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

/*----------  navigation overlay  ----------*/

.overlay-navigation-menu {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	display: table;
	width: 100%;
	height: 100%; /*----------  close icon  ----------*/ /*----------  overlay menu container style  ----------*/
}

.overlay-navigation-menu:after {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	opacity: 0.9;
	background-color: #ffffff;
}

.overlay-navigation-menu .overlay-menu-close-icon {
	position: absolute;
	top: 30px;
	right: 30px;
	display: inline-block;
}

.overlay-navigation-menu .overlay-menu-close-icon a {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	color: #333333;
}

.overlay-navigation-menu .overlay-menu-close-icon a:hover {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.overlay-navigation-menu .overlay-menu-close-icon a i {
	font-size: 45px;
}

.overlay-navigation-menu .overlay-navigation-menu-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100vh;
	text-align: center;
}

.overlay-navigation-menu .overlay-navigation-menu-container nav {
	margin: auto;
}

.overlay-navigation-menu .overlay-navigation-menu-container nav ul li {
	margin-bottom: 20px;
}

.overlay-navigation-menu .overlay-navigation-menu-container nav ul li:last-of-type {
	margin-bottom: 0;
}

.overlay-navigation-menu .overlay-navigation-menu-container nav ul li a {
	font-size: 32px;
	line-height: 57px;
	position: relative;
	color: #333333;
}

.overlay-navigation-menu .overlay-navigation-menu-container nav ul li a:after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: auto;
	width: 0;
	height: 2px;
	content: "";
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	background-color: #333;
}

.overlay-navigation-menu .overlay-navigation-menu-container nav ul li a:hover:after {
	right: auto;
	left: 0;
	width: 100%;
}

.overlay-navigation-menu .overlay-navigation-menu-container nav ul li.has-children ul li {
	margin-bottom: 0;
}

.overlay-navigation-menu .overlay-navigation-menu-container nav ul li.has-children ul li a {
	font-size: 20px;
	line-height: 40px;
}

/*----------  header vertical  ----------*/

.header-vertical__logo {
	margin-bottom: 115px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.header-vertical__bottom .copyright-text {
	margin-top: 25px;
	font-size: 0.875rem;
}

.header-icon-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.header-info-slider--style2 {
	border-right: 1px solid #ededed;
}

.header-info-slider--style2 .header-info-single-item {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 30px;
	border-left: 1px solid #ededed;
}

.header-info-slider--style2 .header-info-single-item__title {
	font-weight: 700;
	font-size: 1rem;
}

.header-top-area {
	padding: 25px 0;
}

.header-top-area .logo {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	padding: 15px 0;
}

.header-top-area .header-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-top-area .header-top-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.header-top-area .get-quote-btn-wrapper {
	padding-left: 15px;
}

.header-navigation-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.get-quote-button-wrapper {
	overflow: hidden;
	text-align: center;
}

.get-quote-button-wrapper a {
	font-weight: 700;
	line-height: 80px;
	position: relative;
	min-width: 220px;
	height: 80px;
	letter-spacing: 0.62px;
	color: #ffffff;
	background-color: #ffc246;
}

.get-quote-button-wrapper a:before {
	position: absolute;
	top: 0;
	left: -50%;
	display: block;
	width: 3.5em;
	height: 100%;
	content: "";
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
	-webkit-transform: skewX(-45deg);
	-ms-transform: skewX(-45deg);
	transform: skewX(-45deg);
	background-color: rgba(255, 255, 255, 0.5);
}

.get-quote-button-wrapper a:hover:before {
	left: 150%;
	-webkit-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.header-navigation-area-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.header-navigation-area-wrapper .logo {
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	padding: 20px 35px;
	background-color: #ffffff;
}

.header-navigation-area-wrapper .header-navigation-wrapper {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0 60px;
	background-color: #06112c;
}

.header-text-info-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-text-info-wrapper__icon {
	margin-right: 15px;
}

.header-text-info-wrapper__icon i {
	color: #ffc246;
	font-size: 2.3125rem;
}

.header-text-info-wrapper__text {
	font-weight: 500;
	color: #ffffff;
	font-size: 1.5rem;
}

.header-bottom-bar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-bottom-bar__left {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.header-bottom-bar__right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.call-now-wrapper {
	font-weight: 500;
	padding-left: 20px;
	color: #222;
}

.call-now-wrapper a {
	text-decoration: underline;
	color: #ffc246;
}

.header-navigation__icon__cart--white-text:before {
	color: #ffffff;
}

/*=====  End of Header  ======*/

/*=============================================
=            footer            =
=============================================*/

.footer-content-wrapper {
	margin-bottom: 50px;
}

.footer-logo {
	margin-bottom: 35px;
}

.footer-logo--style2 {
	margin-bottom: 170px;
}

.footer-desc {
	color: #f1f1f1;
	margin-bottom: 35px;
	max-width: 370px;
}

.footer-desc--style2 {
	color: rgba(255, 255, 255, 0.5);
}

.footer-desc--style3 {
	max-width: 100%;
	margin-bottom: 0;
}

.footer-desc--style4 {
	color: #777;
}

.footer-intro-wrapper .social-links {
	line-height: 1;
}

.footer-intro-wrapper .social-links li a {
	color: #f1f1f1;
}

.footer-intro-wrapper .social-links li a:hover {
	color: #ffc246;
}

.footer-intro-wrapper .social-links--round-style li {
	margin-right: 5px;
}

.footer-intro-wrapper .social-links--round-style li a {
	font-size: 18px;
	display: block;
	text-align: center;
	height: 50px;
	width: 50px;
	line-height: 50px;
	border: 1px solid rgba(221, 221, 221, 0.16);
	border-radius: 50%;
	position: relative;
	color: #ffffff;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.footer-intro-wrapper .social-links--round-style li a:hover {
	border-color: #ffffff;
	background-color: #ffffff;
	color: #222;
}

.footer-intro-wrapper .social-links--round-style--style2 li a {
	border-color: #d1d1d1;
	color: #888;
	background-color: transparent;
}

.footer-intro-wrapper .social-links--round-style--style2 li a:hover {
	border-color: #ffc246;
	background-color: #ffc246;
	color: #ffffff;
}

.footer-widget-wrapper--style3 {
	margin-bottom: -40px;
}

.footer-widget-wrapper--style3 .footer-widget {
	margin-bottom: 40px;
}

.footer-widget__title {
	color: #fff;
	margin-bottom: 40px;
	font-size: 0.875rem;
}

.footer-widget__title--black {
	color: #222;
}

.footer-widget__title--style2 {
	padding-bottom: 25px;
	border-bottom: 1px solid rgba(238, 238, 238, 0.13);
}

.footer-widget__title--style3 {
	font-size: 1.875rem;
	line-height: 1.2;
	font-weight: 300;
}

.footer-widget__navigation {
	line-height: 1;
}

.footer-widget__navigation li {
	padding: 12px 0;
	line-height: 1;
}

.footer-widget__navigation li:first-child {
	padding-top: 0;
}

.footer-widget__navigation li:last-child {
	padding-bottom: 0;
}

.footer-widget__navigation li a {
	color: #f1f1f1;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	line-height: 1.2;
}

.footer-widget__navigation li a:hover {
	color: #ffc246;
	text-decoration: underline;
}

.footer-widget__navigation--address li {
	color: rgba(255, 255, 255, 0.5);
}

.footer-widget__navigation--address li i {
	font-size: 1.125rem;
	color: #ffc246;
	margin-right: 15px;
}

.footer-widget__navigation--style2 {
	overflow: hidden;
}

.footer-widget__navigation--style2 li {
	width: 50%;
	float: left;
}

.footer-widget__navigation--style2 li:nth-child(2) {
	padding-top: 0;
}

.footer-widget__navigation--style2 li a {
	font-size: 0.9375rem;
	color: rgba(255, 255, 255, 0.5);
	line-height: 1.66;
}

.footer-widget__navigation--style3 li a {
	color: rgba(255, 255, 255, 0.5);
}

.footer-widget__navigation--style4 li a {
	color: #777;
}

.footer-widget__newsletter { /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */
}

.footer-widget__newsletter input {
	height: 50px;
	width: 100%;
	border: 1px solid #ddd;
	padding: 0 15px;
	margin-bottom: 20px;
}

.footer-widget__newsletter input:focus {
	border-color: #ffc246;
}

.footer-widget__newsletter input:focus::-webkit-input-placeholder {
	color: transparent;
}

.footer-widget__newsletter input:focus:-moz-placeholder {
	color: transparent;
}

.footer-widget__newsletter input:focus::-moz-placeholder {
	color: transparent;
}

.footer-widget__newsletter input:focus:-ms-input-placeholder {
	color: transparent;
}

.footer-widget__newsletter--style2 {
	position: relative;
	margin-bottom: 40px;
}

.footer-widget__newsletter--style2 input {
	border: none;
	background-color: transparent;
	border-bottom: 2px solid #ffc246;
	padding-left: 0;
	padding-right: 30px;
	margin-bottom: 0;
	color: #ffffff;
}

.footer-widget__newsletter--style2 input::-webkit-input-placeholder {
	color: #f1f1f1;
}

.footer-widget__newsletter--style2 input::-moz-placeholder {
	color: #f1f1f1;
}

.footer-widget__newsletter--style2 input:-ms-input-placeholder {
	color: #f1f1f1;
}

.footer-widget__newsletter--style2 input::-ms-input-placeholder {
	color: #f1f1f1;
}

.footer-widget__newsletter--style2 input::placeholder {
	color: #f1f1f1;
}

.footer-widget__newsletter--style2 button {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 0;
	background: none;
	padding: 0;
	border: none;
	font-size: 18px;
	font-weight: 500;
	color: #ffffff;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.footer-widget__newsletter--style2 button:hover {
	color: #ffc246;
}

.footer-copyright-wrapper {
	padding: 25px 0;
	color: #f1f1f1;
	font-size: 0.875rem;
}

.footer-copyright-right {
	text-align: right;
}

.footer-copyright-right__navigation li {
	display: inline-block;
	position: relative;
	padding: 0 15px;
}

.footer-copyright-right__navigation li:last-child {
	padding-right: 0;
}

.footer-copyright-right__navigation li a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.footer-copyright-right__navigation li a:hover {
	color: #ffc246;
	text-decoration: underline;
}

.footer-copyright-right__navigation li:before {
	position: absolute;
	width: 3px;
	height: 3px;
	top: 50%;
	left: -2px;
	content: '';
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #d8d8d8;
}

.footer-copyright-right__navigation li:first-child:before {
	display: none;
}

.footer-copyright-right__navigation--style2 li a {
	color: rgba(255, 255, 255, 0.5);
}

.footer-copyright-left {
	color: #f1f1f1;
}

.footer-copyright-left--style2 {
	color: rgba(255, 255, 255, 0.5);
}

/*=====  End of footer ======*/

/*=============================================
=            Sidebar            =
=============================================*/

.page-sidebar {
	background: #fafafa;
	padding: 60px 25px;
}

.sidebar-search {
	position: relative; /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */
}

.sidebar-search input {
	width: 100%;
	padding: 0 20px;
	padding-right: 50px;
	background: #ffffff;
	-webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.06);
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.06);
	border-color: transparent;
	outline: none;
	border: 0;
	color: #222;
	font-weight: 400;
	height: 50px;
	border-bottom: 2px solid transparent;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-search input::-webkit-input-placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-search input::-moz-placeholder {
	-moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-search input:-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-search input::-ms-input-placeholder {
	-ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-search input::placeholder {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-search input:focus {
	border-color: #ffc246;
}

.sidebar-search input:focus::-webkit-input-placeholder {
	color: transparent;
}

.sidebar-search input:focus:-moz-placeholder {
	color: transparent;
}

.sidebar-search input:focus::-moz-placeholder {
	color: transparent;
}

.sidebar-search input:focus:-ms-input-placeholder {
	color: transparent;
}

.sidebar-search button {
	position: absolute;
	background: none;
	border: none;
	right: 20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 1.25rem;
	color: #222;
}

.sidebar-search button:hover {
	color: #ffc246;
}

.single-sidebar-widget + .single-sidebar-widget {
	margin-top: 70px;
}

.sidebar-category li {
	display: block;
	border-bottom: 1px solid #eee;
	background-color: #ffffff;
	position: relative;
}

.sidebar-category li:last-child {
	border-bottom: 0;
}

.sidebar-category li a {
	padding: 17px 50px 17px 35px;
	font-size: 1rem;
	font-weight: 500;
	display: block;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-category li a .counter {
	position: absolute;
	top: 50%;
	right: 20px;
	font-size: 12px;
	border-radius: 100%;
	line-height: 1;
	color: #fff;
	background-color: #222;
	padding: 5px;
	min-width: 22px;
	text-align: center;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.sidebar-category li a:hover {
	background-color: #ffc246;
	color: #222;
}

.sidebar-category li.active a {
	background-color: #ffc246;
	color: #222;
}

.sidebar-recent-post {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 25px;
	margin-top: 25px;
	border-top: 1px solid #ddd;
}

.sidebar-recent-post:first-child {
	padding-top: 0;
	margin-top: 0;
	border-top: 0;
}

.sidebar-recent-post__image {
	-webkit-flex-basis: 80px;
	-ms-flex-preferred-size: 80px;
	flex-basis: 80px;
	margin-right: 20px;
}

.sidebar-recent-post__image a {
	display: block;
	position: relative;
	z-index: 1;
}

.sidebar-recent-post__image a img {
	width: 100%;
}

.sidebar-recent-post__image a:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	content: "";
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	visibility: hidden;
	opacity: 0;
}

.sidebar-recent-post__image a:before {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	content: "\f2f5";
	font-family: Ionicons;
	z-index: 3;
	color: #ffffff;
	text-align: center;
	font-size: 24px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	visibility: hidden;
	opacity: 0;
}

.sidebar-recent-post__image a:hover:after {
	visibility: visible;
	opacity: 0.5;
}

.sidebar-recent-post__image a:hover:before {
	visibility: visible;
	opacity: 1;
}

.sidebar-recent-post__content {
	-webkit-flex-basis: calc(100% - 100px);
	-ms-flex-preferred-size: calc(100% - 100px);
	flex-basis: calc(100% - 100px);
}

.sidebar-recent-post__content .title {
	font-size: 1.125rem;
	line-height: 1.4;
}

.sidebar-recent-post__content .title a {
	color: #222;
}

.sidebar-recent-post__content .title a:hover {
	text-decoration: underline;
}

.sidebar-tag-list li {
	display: inline-block;
}

.sidebar-tag-list li a {
	display: block;
	font-size: 15px;
	background: #ffffff;
	border: 0;
	padding: 3px 10px;
	margin: 5px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-tag-list li a:hover {
	background-color: #ffc246;
	color: #222;
}

/* case study */

.sidebar-case-study-list {
	background-color: #ffffff;
}

.single-sidebar-case-study {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 16px 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	border-top: 1px solid #eee;
	position: relative;
}

.single-sidebar-case-study:before {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	position: absolute;
	top: 0;
	left: 0;
	content: '';
	display: inline-block;
	width: 5px;
	height: 0;
	background-color: #ffc246;
}

.single-sidebar-case-study:first-child {
	border-top: none;
}

.single-sidebar-case-study__image {
	-webkit-flex-basis: 75px;
	-ms-flex-preferred-size: 75px;
	flex-basis: 75px;
	margin-right: 25px;
}

.single-sidebar-case-study__content {
	-webkit-flex-basis: calc(100% - 100px);
	-ms-flex-preferred-size: calc(100% - 100px);
	flex-basis: calc(100% - 100px);
}

.single-sidebar-case-study__content a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	color: #222;
}

.single-sidebar-case-study__content a:hover {
	color: #ffc246;
}

.single-sidebar-case-study__content .title {
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 1.4;
	margin-bottom: 5px;
}

.single-sidebar-case-study__content .category {
	font-size: 13px;
	line-height: 1;
}

.single-sidebar-case-study__content .category a {
	color: #888;
}

.single-sidebar-case-study__content .category a:hover {
	color: #ffc246;
}

.single-sidebar-case-study:hover {
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.11);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.11);
}

.single-sidebar-case-study:hover:before {
	height: 100%;
}

/* sidebar about */

.sidebar-about__image {
	margin-bottom: 25px;
}

.sidebar-about__image img {
	width: 100%;
}

.sidebar-about__text {
	font-size: 16px;
	line-height: 1.5;
}

/* sidebar download */

.sidebar-download-items li a {
	text-align: center;
	width: 100%;
	padding: 0 24px;
	height: 50px;
	line-height: 50px;
	border-width: 0;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: .5px;
	text-transform: uppercase;
	color: #fff;
	overflow: hidden;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	z-index: 1;
	position: relative;
}

.sidebar-download-items li a:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	background-color: #ffc246;
	z-index: -1;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-download-items li a:hover {
	color: #222;
}

.sidebar-download-items li a:hover:after {
	right: 0;
	left: auto;
	width: 0;
}

.sidebar-download-items li a i {
	margin-right: 10px;
}

.sidebar-download-items li a.dark-download-btn {
	color: #222;
}

.sidebar-download-items li a.dark-download-btn:after {
	background-color: #222;
	width: 0;
	right: 0;
	left: auto;
}

.sidebar-download-items li a.dark-download-btn:hover {
	color: #ffffff;
}

.sidebar-download-items li a.dark-download-btn:hover:after {
	left: 0;
	right: auto;
	width: 100%;
}

/* sidebar other page list */

.sidebar-other-page__wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.sidebar-other-page__single {
	display: block;
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
	min-height: 160px;
	background: #fff;
	height: 100%;
	padding: 20px 10px;
	text-align: center;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-other-page__single:hover,
.sidebar-other-page__single.active {
	background-color: #ffc246;
}

.sidebar-other-page__single:nth-child(2n) {
	border-right: 0;
}

.sidebar-other-page__single:nth-last-child(2) {
	border-bottom: 0;
}

.sidebar-other-page__single:last-child {
	border-bottom: 0;
}

.sidebar-other-page__single .icon {
	font-size: 42px;
	margin-bottom: 5px;
	color: #222;
}

.sidebar-other-page__single .page-title {
	word-break: break-word;
	font-size: 15px;
	line-height: 1.266;
}

/*=====  End of Sidebar  ======*/

/*=============================================
=            container            =
=============================================*/

.container-fluid--cp-60 {
	padding: 0 60px;
}

.container-fluid--cp-80 {
	padding: 0 80px;
}

/*=====  End of container  ======*/

/*=============================================
=            Button            =
=============================================*/

.ht-btn {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: .5px;
	padding: 0 30px;
	height: 40px;
	line-height: 40px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	text-align: center;
}

.ht-btn--default {
	color: #222;
	background-color: #FEBC35;
	font-weight: 700;
}

.ht-btn--default:hover,
.ht-btn--default:focus {
	background-color: #222;
	color: #ffffff;
}

.ht-btn--dark {
	display: inline-block;
	height: 50px;
	line-height: 50px;
	padding: 0 30px;
	font-size: 0.8125rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #ffffff;
	background-color: #222;
	letter-spacing: 1px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-btn--dark:hover {
	background-color: #ffc246;
	color: #ffffff;
}

.ht-btn--dark--style2 {
	border: 2px solid #222;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	line-height: 46px;
}

.ht-btn--dark--style2:hover {
	background: transparent;
	color: #222;
	border-color: #222;
}

.ht-btn--transparent {
	display: inline-block;
	height: 50px;
	line-height: 46px;
	padding: 0 30px;
	font-size: 0.8125rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #222;
	background-color: transparent;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	border: 2px solid rgba(34, 34, 34, 0.15);
}

.ht-btn--transparent:hover {
	background-color: #222;
	color: #ffffff;
	border-color: #222;
}

.ht-btn--transparent--dark-border {
	border: 2px solid #222;
}

.ht-btn--transparent--alt:hover {
	background-color: #ffc246;
	color: #222;
	border-color: #ffc246;
}

.ht-btn--transparent--alt-dark {
	background-color: #222;
	color: #ffffff;
	border-color: #222;
}

.ht-btn--transparent--alt-dark:hover {
	background-color: transparent;
	color: #222;
	border-color: #222;
}

.ht-btn--transparent--white {
	background-color: rgba(255, 255, 255, 0);
	border: 2px solid #ffffff;
	color: #ffffff;
}

.ht-btn--transparent--white:hover {
	background-color: #ffffff;
	color: #222;
	border-color: #ffffff;
}

.ht-btn--transparent--yellow {
	background-color: #ffc246;
	border: 2px solid #ffc246;
	color: #222;
}

.ht-btn--transparent--yellow:hover {
	background-color: transparent;
	color: #ffc246;
	border-color: #ffc246;
}

.ht-btn--default {
	display: inline-block;
	height: 50px;
	line-height: 50px;
	padding: 0 30px;
	font-size: 0.8125rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #222;
	background-color: #ffc246;
	letter-spacing: 1px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	border: none;
}

.ht-btn--default:hover {
	background-color: #222;
	color: #ffffff;
}

.ht-btn--default--style2 {
	color: #ffffff;
}

.ht-btn--default--style3 {
	color: #ffffff;
}

.ht-btn--default--style3:hover,
.ht-btn--default--style3:focus {
	color: #222;
	background-color: #ffffff;
}

.ht-btn--default--style3--alt {
	color: #222;
	background-color: #ffffff;
}

.ht-btn--default--style3--alt:hover,
.ht-btn--default--style3--alt:focus {
	color: #ffffff;
	background-color: #ffc246;
}

.ht-btn--default--style4 {
	letter-spacing: 0;
	color: #ffffff;
}

.ht-btn--default--style4--transparent {
	border: 2px solid #ffc246;
	line-height: 46px;
}

.ht-btn--default--style4--transparent--white {
	color: #ffffff;
}

.ht-btn--default--style4--transparent--alt {
	color: #222;
}

.ht-btn--default--style4--transparent:hover,
.ht-btn--default--style4--transparent:focus {
	background: transparent;
	color: #ffc246;
	border-color: #ffc246;
}

.ht-btn--default--white-text {
	color: #ffffff;
}

.see-more-link {
	display: inline-block;
	position: relative;
	font-size: 0.8125rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .5px;
	border-bottom: 2px solid #eee;
	color: #ffc246;
}

.see-more-link i {
	margin-left: 5px;
}

.see-more-link:hover {
	color: #ffc246;
}

.see-more-link:hover:after {
	left: 0;
	width: 100%;
	right: auto;
}

.see-more-link:after {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	content: '';
	position: absolute;
	bottom: -2px;
	right: 0;
	width: 0;
	height: 2px;
	background-color: #ffc246;
}

.see-more-link--dark {
	color: #222;
	border-bottom: 0;
}

.see-more-link--dark i {
	color: #ffc246;
}

.see-more-link--dark.see-more-link:after {
	display: none;
}

.see-more-link--dark--style2 {
	border-bottom: 2px solid #eee;
}

.see-more-link--dark--style2.see-more-link:after {
	display: block;
}

.see-more-link--dark--style2--alt:hover {
	color: #222;
}

.see-more-link--style2 {
	padding-bottom: 8px;
}

.see-more-link--style3 {
	color: #ffffff;
	border-bottom: 0;
}

.see-more-link--style3 i {
	color: #ffc246;
}

.see-more-link--style3:after {
	display: none;
}

.career-btn {
	padding: 0 25px;
	padding-right: 70px;
	height: 50px;
	line-height: 50px;
	font-size: 0.8125rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .5px;
	overflow: hidden;
	z-index: 1;
	color: #ffffff;
	background-color: #ffc246;
	position: relative;
}

.career-btn:after {
	content: '';
	width: 0;
	height: 100%;
	top: 0;
	position: absolute;
	left: auto;
	right: 0;
	z-index: -1;
	-webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	-o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	background-color: #11202D;
}

.career-btn i {
	position: absolute;
	top: 5px;
	right: 5px;
	background-color: #ffffff;
	color: #222;
	text-align: center;
	height: 40px;
	width: 40px;
	line-height: 40px;
	display: inline-block;
	font-size: 1rem;
	font-weight: 500;
	border-color: rgba(238, 238, 238, 0.3);
}

.career-btn:hover {
	color: #ffffff;
}

.career-btn:hover:after {
	width: 100%;
	left: 0;
	right: auto;
}

.career-btn--style2 {
	padding-right: 25px;
}

.blog-readmore-button--icon {
	position: absolute;
	bottom: -30px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	height: 60px;
	line-height: 60px;
	text-align: center;
	width: 60px;
	border-radius: 100%;
	font-size: 24px;
	border: 2px solid #ffc246;
	background-color: #fff;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blog-readmore-button--icon i {
	color: #ffc246;
}

.blog-readmore-button--icon:hover {
	background-color: #ffc246;
	border-color: #ffc246;
}

.blog-readmore-button--icon:hover i {
	color: #ffffff;
}

.project-details-link {
	position: absolute;
	bottom: -30px;
	left: 50%;
	margin-left: -30px;
	font-size: 20px;
	width: 60px;
	height: 60px;
	border: 2px solid #ffc246;
	background-color: #fff;
	text-align: center;
	line-height: 60px;
	border-radius: 50%;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.project-details-link i {
	color: #ffc246;
}

.project-details-link:hover {
	background-color: #ffc246;
}

.project-details-link:hover i {
	color: #ffffff;
}

.header9-btn {
	color: #222;
	position: relative;
	margin-right: 20px;
}

.header9-btn:hover:after,
.header9-btn:active:after,
.header9-btn:focus:after {
	border-top-color: #222;
}

.header9-btn:after {
	position: absolute;
	top: 100%;
	left: 0;
	content: '';
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 13px 15px 0 0;
	border-color: transparent;
	border-top-color: #ffc246;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header10-btn {
	background-color: white;
	border: 2px solid #eee;
	color: #05103B;
	line-height: 46px;
}

.header10-btn:hover {
	background-color: #05103B;
	border-color: #05103B;
	color: #ffffff;
}

/*=====  End of Button  ======*/

/*=============================================
=            search overlay            =
=============================================*/

.search-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999999;
	visibility: hidden;
	width: 100%;
	height: 100%;
	text-align: center;
	opacity: 0;
	-webkit-transition: all ease-in-out .25s;
	-o-transition: all ease-in-out .25s;
	transition: all ease-in-out .25s;
	background-color: rgba(0, 0, 0, 0.85);
}

.search-overlay.active {
	visibility: visible;
	opacity: 1;
}

.search-overlay.active .page-popup-search-inner {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translate(-50%, -50%) scale(1);
	-ms-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
}

.search-overlay.active .search-field {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}

.search-overlay.active .form-description {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.search-overlay .popup-search-close {
	position: absolute;
	top: 10px;
	right: 60px;
	font-size: 6.25rem;
	cursor: pointer;
	line-height: 0;
	color: #ffffff;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay .popup-search-close:hover {
	color: #ffc246;
}

.search-overlay .page-popup-search-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	display: inline-block;
	visibility: hidden;
	padding: 0 30px;
	width: 75%;
	text-align: center;
	font-size: 18px;
	opacity: 0;
	-webkit-transition: all ease-in-out .3s;
	-o-transition: all ease-in-out .3s;
	transition: all ease-in-out .3s;
	-webkit-transform: translate(-50%, -50%) scale(0.8);
	-ms-transform: translate(-50%, -50%) scale(0.8);
	transform: translate(-50%, -50%) scale(0.8);
	line-height: 1;
}

.search-overlay .search-field {
	padding: .05em 0;
	height: auto;
	border: 0;
	border-bottom: 5px solid #ffc246;
	background: none;
	border-radius: 0;
	font-size: 10vw;
	-webkit-transform: scale(0, 1, 1);
	-ms-transform: scale(0, 1, 1);
	transform: scale(0, 1, 1);
	width: 100%;
	color: #ffc246;
	line-height: 1;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay .form-description {
	display: block;
	padding: .85em 0;
	text-align: right;
	font-weight: 700;
	font-size: 16px;
	line-height: 1.5;
	opacity: 0;
	-webkit-transform: translate3d(0, 50px, 0);
	transform: translate3d(0, 50px, 0);
	-webkit-transition: opacity .4s,-webkit-transform .4s;
	transition: opacity .4s,-webkit-transform .4s;
	-o-transition: opacity .4s,transform .4s;
	transition: opacity .4s,transform .4s;
	transition: opacity .4s,transform .4s,-webkit-transform .4s;
	color: #ffc246;
}

/*=====  End of search overlay  ======*/

/*=============================================
=            section title            =
=============================================*/

.section-title-area {
	line-height: 1;
}

.section-title-area .title {
	font-size: 2.5rem;
	line-height: 1;
	position: relative;
	padding-bottom: 30px;
	margin-bottom: 0;
}

.section-title-area .title:after {
	content: '';
	display: block;
	width: 50px;
	height: 4px;
	background-color: #ffc246;
	position: absolute;
	left: 0;
	bottom: 0;
}

.section-title-area .title--left {
	line-height: 1.3;
	margin-bottom: 30px;
}

.section-title-area .title--left--style2 {
	font-size: 3.125rem;
	line-height: 1.2;
	margin-bottom: 40px;
}

.section-title-area .title--left--style2:before {
	position: absolute;
	left: 0;
	bottom: 0;
	content: '';
	display: block;
	width: 150px;
	height: 1px;
	background: #ddd;
}

.section-title-area .title--left--style2:after {
	height: 3px;
}

.section-title-area .title--left--style2 span.translate-left {
	display: block;
	-webkit-transform: translateX(-110px);
	-ms-transform: translateX(-110px);
	transform: translateX(-110px);
}

.section-title-area .title--left--style2 span.translate-left--style2 {
	-webkit-transform: translateX(-30px);
	-ms-transform: translateX(-30px);
	transform: translateX(-30px);
}

.section-title-area .title--left--style2--black2 {
	color: #11202D;
}

.section-title-area .title--style2 {
	position: relative;
	font-size: 3.125rem;
	line-height: 1;
	margin-top: -5px;
}

.section-title-area .title--style2:before {
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: 0;
	content: '';
	display: block;
	width: 150px;
	height: 1px;
	background: #ddd;
}

.section-title-area .title--style2:after {
	height: 3px;
}

.section-title-area .title--style2--black2 {
	color: #11202D;
}

.section-title-area .title--style3 {
	font-size: 2.875rem;
	margin-bottom: 40px;
}

.section-title-area .title--style3 .highlight {
	color: #ffc246;
}

.section-title-area .title--style4 {
	line-height: 1.2;
	padding-bottom: 0;
	margin-top: -5px;
}

.section-title-area .title--style4 .highlight {
	color: #ffc246;
}

.section-title-area .title--style4:after {
	display: none;
}

.section-title-area .title--style4--about {
	width: 430px;
	max-width: 100%;
}

.section-title-area .title--style5 {
	padding-bottom: 0;
	font-size: 3.125rem;
	font-weight: 300;
	margin-top: -5px;
}

.section-title-area .title--style5:after {
	display: none;
}

.section-title-area .title--style5 .highlight {
	font-weight: 700;
}

.section-title-area .title--style6 {
	padding-bottom: 0;
	font-size: 3.125rem;
	line-height: 1.2;
	font-weight: 300;
}

.section-title-area .title--style6:after {
	display: none;
}

.section-title-area .title--style7 {
	font-weight: 900;
	padding-bottom: 0;
	color: #05103B;
	line-height: 1.2;
	margin-top: -7px;
}

.section-title-area .title--style7:after {
	display: none;
}

.section-title-area .title--style7 i {
	color: rgba(53, 66, 118, 0.25);
	font-size: 3.125rem;
	vertical-align: middle;
}

.section-title-area .title--style7 a:hover {
	background-color: #05103B;
}

.section-title-area .title--style8 {
	display: inline-block;
	padding-bottom: 15px;
	color: #05103B;
	margin-top: -5px;
}

.section-title-area .title--style8:after {
	width: 100%;
	height: 1px;
	background-color: #ddd;
	margin-top: 15px;
}

.section-title-area .title--style8--white {
	color: #ffffff;
}

.section-title-area .title--style8--small {
	font-size: 1.875rem;
}

.section-title-area .subtitle {
	font-size: 0.9375rem;
	letter-spacing: 2px;
	line-height: 1;
	color: #888;
	margin-bottom: 20px;
}

.section-title-area .subtitle--style2 {
	font-size: 0.875rem;
	margin-bottom: 30px;
}

.section-title-area .subtitle--style3 {
	font-size: 0.875rem;
	font-weight: 700;
	color: #afafaf;
	margin-bottom: 0;
	margin-top: 25px;
}

.section-title-area--style2 .subtitle {
	display: inline-block;
	overflow: hidden;
	color: #999;
	font-size: 0.875rem;
	position: relative;
	padding-bottom: 10px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.section-title-area--style2 .subtitle:before {
	content: '';
	display: inline-block;
	width: 100%;
	height: 2px;
	background-color: #ffc246;
	position: absolute;
	right: calc(100% + 20px);
	bottom: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.section-title-area--style2 .subtitle:after {
	content: '';
	display: inline-block;
	width: 100%;
	height: 2px;
	background-color: #ffc246;
	position: absolute;
	left: 0;
	bottom: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.section-title-area--style2 .subtitle:hover:before {
	right: 0;
}

.section-title-area--style2 .subtitle:hover:after {
	left: calc(100% + 20px);
}

.section-title-area--style2 .title {
	padding-bottom: 0;
}

.section-title-area--style2 .title:after {
	display: none;
}

.section-title-area--style3 {
	border-bottom: 1px solid #eee;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-bottom: 30px;
}

.section-title-area--middle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.section-title-area--middle .title {
	display: inline-block;
}

.section-title-area--middle .title:after {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.section-title-area--middle .subtitle {
	display: inline-block;
}

.section-title-area--middle--style2 .title {
	padding-bottom: 0;
}

.section-title-area--middle--style2 .title:after {
	display: none;
}

.section-title-area--middle--style2 .subtitle {
	overflow: hidden;
	color: #999;
	font-size: 0.875rem;
	position: relative;
	padding-bottom: 10px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.section-title-area--middle--style2 .subtitle:before {
	content: '';
	display: inline-block;
	width: 100%;
	height: 2px;
	background-color: #ffc246;
	position: absolute;
	right: calc(100% + 20px);
	bottom: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.section-title-area--middle--style2 .subtitle:after {
	content: '';
	display: inline-block;
	width: 100%;
	height: 2px;
	background-color: #ffc246;
	position: absolute;
	left: 0;
	bottom: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.section-title-area--middle--style2 .subtitle:hover:before {
	right: 0;
}

.section-title-area--middle--style2 .subtitle:hover:after {
	left: calc(100% + 20px);
}

.section-title-content {
	font-size: 1.125rem;
	line-height: 1.5;
	margin-bottom: 30px;
}

.section-title-content--style2 {
	font-size: 0.9375rem;
	line-height: 1.7;
	margin-bottom: 40px;
}

.section-title-content--style3 {
	line-height: 1.7;
}

.section-title-content--style4 {
	font-size: 0.9375rem;
	line-height: 1.5;
	color: #888;
}

.section-title-content-wrapper--about-counter {
	padding-left: 30px;
	border-left: 2px solid #eee;
}

.section-title-content-wrapper--about-counter--style2 {
	border-left: 5px solid #dbe7f9;
}

/* service title wrapper */

.service-title-area-bg {
	background-repeat: no-repeat;
	background-position: left center;
}

.service-title-container {
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;
}

.service-title-wrapper {
	text-align: center;
	width: 735px;
	max-width: 100%;
	margin: 0 auto;
}

.service-title-wrapper .title {
	font-size: 3.125rem;
	font-weight: 300;
	margin-bottom: 30px;
	margin-top: -10px;
}

.service-title-wrapper .title .highlight {
	font-weight: 700;
	color: #ffc246;
}

.service-title-wrapper .text {
	margin-bottom: 35px;
}

.widget-title {
	margin-bottom: 35px;
	margin-top: -5px;
}

.common-page-title {
	font-size: 30px;
	line-height: 1.2;
	margin-bottom: 20px;
	margin-top: -5px;
}

.career-title-area .title {
	font-size: 2.5rem;
	line-height: 1.2;
	margin-bottom: 20px;
	margin-top: -5px;
}

.career-title-area .subtitle {
	margin: 0 auto;
	width: 615px;
	max-width: 100%;
}

.case-study__page-content-title {
	margin-top: -10px;
}

/*=====  End of section title  ======*/

/*=============================================
=            backgrounds            =
=============================================*/

.white-bg {
	background-color: #ffffff;
}

.default-bg {
	background-color: #ffc246;
}

.grey-bg {
	background-color: #f7f7f7;
}

.grey-bg--style2 {
	background-color: #eeeeee;
}

.grey-bg--style3 {
	background-color: #f5f6fa;
}

.dark-bg {
	background-color: #111;
}

.dark-bg--style2 {
	background-color: #11202D;
}

.dark-bg--style3 {
	background-color: #222;
}

.dark-bg--style4 {
	background-color: #06112C;
}

.dark-bg--style5 {
	background-color: #05103B;
}

.half-bg-image {
	position: absolute;
	right: 0;
	top: 0;
	width: 50%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

/*=====  End of backgrounds  ======*/

/*=============================================
=            accordions            =
=============================================*/

.faq-wrapper .panel.panel-default {
	border: 1px solid #ebebeb;
	margin-bottom: 20px;
}

.faq-wrapper .card,
.faq-wrapper .card-header {
	border-radius: 0;
}

.faq-wrapper .card {
	border-bottom: none;
}

.faq-wrapper .card:last-child {
	margin-bottom: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.faq-wrapper .card-header {
	padding: 0;
	border-bottom: 0;
	background-color: #ffffff;
}

.faq-wrapper .card-header h5 button {
	width: 100%;
	text-align: left;
	color: #05103B;
	padding: 20px 35px;
	position: relative;
	overflow-wrap: break-word;
	white-space: normal;
	font-size: 1.125rem;
	line-height: 1.2;
	font-weight: 700;
}

.faq-wrapper .card-header h5 button span {
	position: absolute;
	right: 35px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.faq-wrapper .card-header h5 button span i {
	font-size: 12px;
	visibility: hidden;
	opacity: 0;
	display: none;
}

.faq-wrapper .card-header h5 button:hover,
.faq-wrapper .card-header h5 button:focus {
	text-decoration: none;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] {
	background-color: #f7f7f7;
	border-radius: 0;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] span i:nth-child(2) {
	visibility: visible;
	opacity: 1;
	display: inline;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"]:before {
	height: 100%;
}

.faq-wrapper .card-header h5 button[aria-expanded="false"] span i:nth-child(1) {
	visibility: visible;
	opacity: 1;
	display: inline;
}

.faq-wrapper .card-header h5 button:before {
	content: '';
	width: 3px;
	height: 0;
	background-color: #ffc246;
	position: absolute;
	top: 0;
	left: -1px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.faq-wrapper .card-header h5 button:hover:before {
	height: 100%;
}

.faq-wrapper .card-body p {
	font-size: 14px;
	line-height: 24px;
}

/*=====  End of accordions  ======*/

/*=============================================
=            preloader            =
=============================================*/

.preloader-active {
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	height: 100vh;
	width: 100vw;
	z-index: 999999;
}

.preloader-active .preloader-area-wrap {
	background-color: #ffffff;
	position: absolute;
	left: 0;
	display: block;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 999999;
}

.preloader-area-wrap {
	display: none;
}

/*=====  End of preloader  ======*/

/*=============================================
=            scroll top            =
=============================================*/

a.scroll-top {
	position: fixed;
	right: 30px;
	bottom: -60px;
	z-index: 999;
	-webkit-box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
	display: block;
	padding: 0;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	text-align: center;
	font-size: 25px;
	line-height: 60px;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	background-color: #ffc246;
	color: #ffffff;
	-webkit-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

a.scroll-top.show {
	visibility: visible;
	opacity: 1;
	bottom: 60px;
}

/*=====  End of scroll top  ======*/

/*=============================================
=            pagination            =
=============================================*/

.page-pagination {
	display: block;
	line-height: 1;
}

.page-pagination li {
	display: inline-block;
	margin-right: 15px;
}

.page-pagination li:last-child {
	margin-right: 0;
}

.page-pagination li.active a {
	color: #222;
}

.page-pagination li a {
	display: block;
	font-weight: 700;
	color: #b5b5b5;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	line-height: 1;
}

.page-pagination li a:hover {
	color: #222;
}

/*=====  End of pagination  ======*/

/*=============================================
=            icon            =
=============================================*/

.glyphs {
	text-align: center;
}

.glyph {
	display: inline-block;
	width: 8em;
	margin: 1em;
	text-align: center;
	vertical-align: top;
	border: 1px solid #eee;
}

.glyph-icon {
	padding: 10px;
	display: block;
	font-family: "Flaticon";
	font-size: 64px;
	line-height: 1;
	color: #222;
}

.glyph .class-name {
	background-color: #f7f7f7;
	color: #222;
	margin-top: 10px;
	padding: 10px;
}

/*=====  End of icon  ======*/

/*=============================================
=            progress bar            =
=============================================*/

.progress-bar-content {
	padding-right: 70px;
}

.progress-bar-item {
	margin-top: 45px;
}

.progress-bar-item .progress-info {
	font-size: 0.875rem;
	line-height: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 20px;
}

.progress-bar-item .progress-info__title {
	color: #222;
	font-weight: 700;
	display: inline-block;
	font-size: 16px;
}

.progress-bar-item .progress-info__percent {
	font-size: 16px;
	font-weight: 500;
	color: #222;
}

.progress-bar-item .progress-line {
	background-color: #eee;
	height: 4px;
	position: relative;
}

.progress-bar-item .progress-line-dot {
	background-color: #ffc246;
	border-radius: 50%;
	position: absolute;
	top: -4px;
	-webkit-transition: 0.70s;
	-o-transition: 0.70s;
	transition: 0.70s;
	left: 0;
	height: 12px;
	width: 12px;
}

.progress-bar-item .progress-line-bar {
	background-color: #ffc246;
	height: 100%;
	width: 0;
}

/*=====  End of progress bar  ======*/

/*=============================================
=            featured projects            =
=============================================*/

.section-title-video-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.section-title-video-button:hover .video-play {
	-webkit-transform: scale3d(1.15, 1.15, 1.15);
	transform: scale3d(1.15, 1.15, 1.15);
}

.section-title-video-button:hover .video-text:before {
	width: 100%;
}

.section-title-video-button .video-play {
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	color: #fff;
	background-color: #ffc246;
	margin-right: 20px;
	border-radius: 50%;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-duration: 1s;
	-o-transition-duration: 1s;
	transition-duration: 1s;
}

.section-title-video-button .video-play--style2 {
	width: 50px;
	height: 50px;
	line-height: 50px;
	background: none;
	border: 1px solid #ffc246;
	color: #ffc246;
	font-size: 1.25rem;
}

.section-title-video-button .video-play--style2 i {
	padding-left: 5px;
}

.section-title-video-button .video-text {
	font-size: 0.8125rem;
	line-height: 1;
	font-weight: 700;
	color: #222;
	padding-bottom: 6px;
	position: relative;
	border-bottom: 2px solid #eee;
}

.section-title-video-button .video-text:before {
	content: '';
	display: block;
	width: 0;
	height: 2px;
	background-color: #ffc246;
	position: absolute;
	left: 0;
	bottom: -2px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.section-title-video-button .video-text--style2 {
	border: none;
}

.section-title-video-button .video-text--style2:before {
	display: none;
}

.feature-project-box-wrapper {
	margin-bottom: -30px;
	margin-top: -200px;
}

/* single feature project box */

.single-feature-project-box {
	background-color: #fff;
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	margin-bottom: 30px;
}

.single-feature-project-box:hover {
	-webkit-transform: translateY(-20px);
	-ms-transform: translateY(-20px);
	transform: translateY(-20px);
}

.single-feature-project-box__image img {
	width: 100%;
}

.single-feature-project-box__content {
	padding: 30px 20px;
}

.single-feature-project-box__title {
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.single-feature-project-box__link {
	color: #222;
	height: 50px;
	line-height: 50px;
	padding: 0 20px;
	display: block;
	width: 100%;
	border-top: 1px solid #eee;
	position: relative;
	font-weight: 700;
	letter-spacing: 0.5px;
	font-size: 0.8125rem;
	z-index: 1;
}

.single-feature-project-box__link:hover {
	color: #222;
}

.single-feature-project-box__link:hover:before {
	width: 100%;
	left: 0;
	right: auto;
}

.single-feature-project-box__link:hover i {
	color: #222;
}

.single-feature-project-box__link:before {
	position: absolute;
	right: 0;
	width: 0;
	height: 100%;
	content: "";
	background-color: #ffc246;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	z-index: -1;
}

.single-feature-project-box__link span {
	z-index: 2;
}

.single-feature-project-box__link i {
	position: absolute;
	right: 20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #ffc246;
	font-size: 1rem;
	z-index: 2;
}

.feature-project-slider-container {
	position: relative;
}

.featured-project-two-slider-container {
	margin: -30px;
}

.featured-project-two-slider-wrapper .swiper-slide {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	padding: 30px;
}

.featured-project-two-slider-wrapper .swiper-slide:hover {
	padding: 0;
}

.featured-project-two-slider {
	position: relative;
}

.featured-project-two-slider .ht-swiper-button-next {
	right: -100px;
}

.featured-project-two-slider .ht-swiper-button-prev {
	left: -100px;
}

.featured-project-two-slider .swiper-pagination {
	margin-top: 50px;
}

.feature-project-two-single-item {
	position: relative;
	background-color: #fff;
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.feature-project-two-single-item__image img {
	width: 100%;
}

.feature-project-two-single-item__content {
	padding: 15px 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.feature-project-two-single-item__content a {
	font-size: 1.25rem;
	font-weight: 700;
	color: #222;
}

.feature-project-two-single-item:hover .feature-project-two-single-item__content {
	background-color: #ffc246;
}

.featured-project-three-slider__area-bg {
	background-repeat: no-repeat;
	background-position: top left;
}

.featured-project-three-slider__container-area {
	position: relative;
}

.featured-project-three-slider__container-area .ht-swiper-button-nav {
	background-color: #ffffff;
	width: 60px;
	height: 60px;
	line-height: 56px;
}

.featured-project-three-slider__container-area .ht-swiper-button-nav:hover {
	border-color: #ffc246;
	color: #ffffff;
	background-color: #ffc246;
}

.featured-project-three-slider__container-area .ht-swiper-button-prev {
	left: 170px;
}

.featured-project-three-slider__container-area .ht-swiper-button-next {
	right: 170px;
}

.featured-project-three-slider__container-area .swiper-pagination {
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: -70px;
	font-size: 1.25rem;
	font-weight: 500;
}

.featured-project-three-slider__container-area .swiper-pagination-current {
	color: #222;
}

.featured-project-three-slider__single-slide-wrapper.swiper-slide-active .content {
	display: block;
}

.featured-project-three-slider__single-slide-wrapper.swiper-slide-next .content {
	display: none;
}

.featured-project-three-slider__single-slide {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.featured-project-three-slider__single-slide:hover .content .project-details-link {
	color: #ffc246;
}

.featured-project-three-slider__single-slide:hover .image img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.featured-project-three-slider__single-slide .image {
	-webkit-flex-basis: 60%;
	-ms-flex-preferred-size: 60%;
	flex-basis: 60%;
	overflow: hidden;
}

.featured-project-three-slider__single-slide .image img {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-duration: 0.7s;
	-o-transition-duration: 0.7s;
	transition-duration: 0.7s;
	width: 100%;
}

.featured-project-three-slider__single-slide .content {
	-webkit-flex-basis: 40%;
	-ms-flex-preferred-size: 40%;
	flex-basis: 40%;
	padding: 0 0 40px 0;
}

.featured-project-three-slider__single-slide .content .project-no {
	font-size: 1.5rem;
	color: #ffc246;
	margin-bottom: 10px;
}

.featured-project-three-slider__single-slide .content .project-name {
	font-size: 3.125rem;
	line-height: 1.2;
	margin-bottom: 30px;
}

.featured-project-three-slider__single-slide .content .project-excerpt {
	max-width: 300px;
	margin-bottom: 40px;
	font-size: 0.9375rem;
	color: #888;
}

.featured-project-three-slider__single-slide .content .project-details-link {
	font-size: 0.8125rem;
	display: inline-block;
	font-weight: 700;
	color: #222;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.featured-project-three-slider__single-slide .content .project-details-link i {
	margin-left: 3px;
	color: #ffc246;
}

.featured-project-four__wrapper {
	margin-bottom: -40px;
}

.featured-project-single-grid {
	position: relative;
	background: #fff;
	-webkit-box-shadow: 0 30px 50px rgba(0, 0, 0, 0.06);
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.06);
	margin-bottom: 70px;
}

.featured-project-single-grid__image {
	position: relative;
}

.featured-project-single-grid__image img {
	width: 100%;
}

.featured-project-single-grid__image:before {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: inline-block;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	z-index: 2;
}

.featured-project-single-grid__content {
	padding: 30px;
}

.featured-project-single-grid__content .title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.featured-project-single-grid__content .title a:hover {
	color: #ffc246;
}

.featured-project-single-grid__content .text {
	border-top: 1px solid #eee;
	padding-top: 15px;
	margin-top: 14px;
}

.featured-project-single-grid:hover .featured-project-single-grid__image:before {
	background-color: rgba(0, 0, 0, 0.3);
}

/*=====  End of featured projects  ======*/

/*=============================================
=            industry slider            =
=============================================*/

.industry-slider-nav-area {
	padding-left: 100px;
	padding-right: 100px;
	position: relative;
}

.industry-slider-nav-area .ht-swiper-button-nav {
	-webkit-transform: translateY(-80%);
	-ms-transform: translateY(-80%);
	transform: translateY(-80%);
}

.industry-slider-nav-area .ht-swiper-button-next {
	right: 50px;
}

.industry-slider-nav-area .ht-swiper-button-prev {
	left: 50px;
}

.industry-slider-nav-container {
	padding-bottom: 30px;
	z-index: 2;
}

.industry-slider-nav-container .swiper-slide-visible {
	z-index: 6;
	-webkit-box-shadow: -10px 0 20px rgba(0, 0, 0, 0.05);
	box-shadow: -10px 0 20px rgba(0, 0, 0, 0.05);
}

.industry-slider-nav-container .swiper-slide-active {
	background-color: #ffc246;
	color: #222;
	position: relative;
}

.industry-slider-nav-container .swiper-slide-active:before {
	content: '';
	width: 100%;
	height: 20px;
	background-color: #ffc246;
	display: block;
	position: absolute;
	left: 0;
	bottom: -20px;
	opacity: 1;
}

.industry-slider-nav-container .swiper-slide-active:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #ffc246;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: -30px;
}

.industry-single-nav {
	padding: 30px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
}

.industry-single-nav__icon {
	font-size: 3.125rem;
	margin-right: 20px;
}

.industry-single-nav__title {
	font-size: 1.25rem;
	font-weight: 700;
}

.industry-slider-content-container {
	margin-top: -30px;
}

.industry-slider-content-single {
	height: 730px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 15%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.industry-slider-content-single .industry-content-inner {
	width: 550px;
	max-width: 100%;
	background-color: #fff;
	padding: 60px 40px;
}

.industry-slider-content-single .industry-content-inner .title {
	font-size: 20px;
}

/* industry slider two */

.industry-two-slider {
	position: relative;
	margin-bottom: -50px;
}

.industry-two-slider .ht-swiper-button-prev {
	left: -110px;
}

.industry-two-slider .ht-swiper-button-next {
	right: -110px;
}

.industry-two-slider__container {
	padding-bottom: 50px;
}

.industry-two-slider__single-item__image a {
	display: block;
	width: 100%;
}

.industry-two-slider__single-item__image a img {
	width: 100%;
}

.industry-two-slider__single-item__content {
	padding: 25px 25px 45px;
	background: #ffffff;
	margin: -30px 20px 0;
	-webkit-box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
	z-index: 2;
	position: relative;
}

.industry-two-slider__single-item__content .title {
	font-size: 1.5rem;
	line-height: 1.4;
	margin-bottom: 15px;
}

.industry-two-slider__single-item__content .title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.industry-two-slider__single-item__content .title a:hover {
	color: #ffc246;
}

.industry-two-slider__single-item__content:after {
	content: '';
	width: 0;
	height: 3px;
	position: absolute;
	left: auto;
	right: 0;
	bottom: 0;
	background: #ffc246;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.industry-two-slider__single-item:hover .industry-two-slider__single-item__content:after {
	width: 100%;
	left: 0;
	right: auto;
}

.industry-two-slider__single-item--style2 .industry-two-slider__single-item__content {
	padding-left: 0;
	padding-bottom: 0;
	margin: 0;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.industry-two-slider__single-item--style2 .industry-two-slider__single-item__content:after {
	top: 0;
}

.industry-two-slider__single-item--style3 .industry-two-slider__single-item__image {
	overflow: hidden;
}

.industry-two-slider__single-item--style3 .industry-two-slider__single-item__image img {
	-webkit-transition: all .3s, -webkit-transform .6s;
	transition: all .3s, -webkit-transform .6s;
	-o-transition: all .3s, transform .6s;
	transition: all .3s, transform .6s;
	transition: all .3s, transform .6s, -webkit-transform .6s;
}

.industry-two-slider__single-item--style3 .industry-two-slider__single-item__content {
	margin: 0;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #eee;
}

.industry-two-slider__single-item--style3 .industry-two-slider__single-item__content:after {
	display: none;
}

.industry-two-slider__single-item--style3:hover .industry-two-slider__single-item__image img {
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1);
}

/* industry slider three*/

.industry-three-slider__area {
	background-repeat: no-repeat;
	background-position: left top;
}

.industry-three-slider__area .section-title-area {
	padding-left: 30px;
}

.industry-three-slider__single-slide .item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.industry-three-slider__single-slide .item-wrap__image {
	background-size: cover;
	height: 355px;
}

.industry-three-slider__single-slide .item-wrap__content {
	height: 355px;
	background-color: #11202D;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 40px;
	margin-bottom: 40px;
}

.industry-three-slider__single-slide .item-wrap__content__icon {
	color: #fff;
	line-height: 1;
	font-size: 70px;
}

.industry-three-slider__single-slide .item-wrap__content__title {
	padding-right: 60px;
	position: relative;
	line-height: 1.66;
	margin-bottom: 0;
}

.industry-three-slider__single-slide .item-wrap__content__title a {
	color: #ffffff;
}

.industry-three-slider__single-slide .item-wrap__content__title a:hover {
	color: #ffffff;
}

.industry-three-slider__single-slide .item-wrap__content__title:after {
	content: '\f30f';
	font-family: Ionicons;
	font-size: 1.5rem;
	font-weight: 400;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #ffffff;
}

.industry-three-slider__single-slide:nth-child(2n+1) .item-wrap__image {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2;
}

.industry-three-slider__single-slide:nth-child(2n+1) .item-wrap__content {
	background-color: #ffc246;
	margin-top: 40px;
	margin-bottom: 0;
}

.industry-three-slider__navigation-wrapper .ht-swiper-button-nav {
	position: static;
	display: inline-block;
	margin-right: 10px;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.industry-three-slider__navigation-wrapper .ht-swiper-button-nav:last-child {
	margin-right: 0;
}

/* industry slider four */

.industry-four-slider__container-area {
	position: relative;
}

.industry-four-slider__container-area .ht-swiper-button-prev {
	left: -80px;
}

.industry-four-slider__container-area .ht-swiper-button-next {
	right: -80px;
}

.industry-four-slider__container {
	padding: 30px 20px;
}

.industry-four-slider__single-slide {
	position: relative;
	background-color: #fff;
	-webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);
	padding: 40px 35px 30px;
	text-align: center;
}

.industry-four-slider__single-slide .image {
	margin-bottom: 30px;
}

.industry-four-slider__single-slide .image i {
	color: #ffc246;
	font-size: 80px;
}

.industry-four-slider__single-slide .content .title {
	font-size: 1.25rem;
}

.industry-four-slider__single-slide .content .title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.industry-four-slider__single-slide .content .title a:hover {
	color: #ffc246;
}

.industry-four-slider__single-slide:before {
	position: absolute;
	bottom: 0;
	right: 0;
	content: '';
	width: 0;
	height: 5px;
	background-color: #ffc246;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.industry-four-slider__single-slide:hover:before {
	width: 100%;
	left: 0;
	right: auto;
}

.industry-four-slider__text {
	font-size: 1.125rem;
	max-width: 700px;
	margin: 0 auto;
}

/* industry slider five */

.industry-five-slider__container-area .swiper-pagination {
	margin-top: 50px;
}

.industry-five-slider__container-area .swiper-pagination-bullet-active {
	background-color: #999;
}

.industry-five-slider__container {
	margin: -30px 0;
	padding: 30px 0;
}

.industry-five-slider__wrapper .swiper-slide {
	height: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.industry-five-slider__single-slide {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: #fff;
	-webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.11);
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.11);
	width: 100%;
	height: 100%;
}

.industry-five-slider__single-slide__image {
	width: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.industry-five-slider__single-slide__content {
	width: 50%;
	padding: 35px 30px;
}

.industry-five-slider__single-slide__content .post-icon {
	font-size: 60px;
	line-height: 1;
	margin-bottom: 40px;
	color: #ffc246;
}

.industry-five-slider__single-slide__content .title {
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.industry-five-slider__single-slide__content .title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.industry-five-slider__single-slide__content .title a:hover {
	color: #ffc246;
}

.industry-five-slider__single-slide__content .post-excerpt {
	margin-bottom: 0;
}

.industry-five-slider__single-slide__content .see-more-link {
	margin-top: 30px;
}

/* industry slider six */

.industry-six-slider__container-area {
	position: relative;
}

.industry-six-slider__container-area .swiper-pagination {
	top: auto;
	bottom: calc(50% - 280px);
	text-align: left;
	padding-left: 90px;
	position: absolute;
}

.industry-six-slider__container-area .swiper-pagination-bullet {
	-webkit-transition: all .3s ease,width 0s,border-color 0s;
	-o-transition: all .3s ease,width 0s,border-color 0s;
	transition: all .3s ease,width 0s,border-color 0s;
	text-align: center;
	height: 45px;
	line-height: 41px;
	border: 2px solid transparent;
	font-weight: 500;
	font-size: 15px;
	color: #acacac;
	padding: 0 7px;
	width: auto;
	background-color: transparent;
	opacity: 1;
}

.industry-six-slider__container-area .swiper-pagination-bullet-active,
.industry-six-slider__container-area .swiper-pagination-bullet:hover {
	color: #222;
}

.industry-six-slider__single-slide {
	padding-left: 400px;
	position: relative;
}

.industry-six-slider__content {
	background-color: #ffc246;
	color: #ffffff;
	padding: 35px 30px 50px;
	position: absolute;
	top: 50%;
	left: 100px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 400px;
	max-width: 100%;
	height: 400px;
	z-index: 2;
}

.industry-six-slider__content .slider-no {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 60px;
}

.industry-six-slider__content .slider-title {
	color: #ffffff;
	font-size: 2.5rem;
	font-weight: 700;
}

.industry-six-slider__content .slider-title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.industry-six-slider__content .slider-title a:hover {
	color: #222;
}

.industry-six-slider__content .slider-excerpt {
	margin-bottom: 70px;
}

.industry-six-slider__content .see-more-link {
	color: #ffffff;
	border-bottom: 2px solid rgba(238, 238, 238, 0.24);
}

.industry-six-slider__content .see-more-link i {
	color: #ffffff;
}

.industry-six-slider__content .see-more-link:hover {
	color: #222;
}

.industry-six-slider__content .see-more-link:after {
	display: none;
}

.industry-six-slider__background {
	min-height: 715px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

/* industry slider seven */

.industry-seven-slider__single-slide:nth-child(2n+1) .item-wrap__image {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2;
}

.industry-seven-slider__single-slide:nth-child(2n+1) .item-wrap__info {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.industry-seven-slider__single-slide:nth-child(3n+1) .item-wrap__info {
	background-color: #11202d;
}

.industry-seven-slider__single-slide:nth-child(3n+2) .item-wrap__info {
	background-color: #2b8c10;
}

.industry-seven-slider__single-slide:nth-child(3n+3) .item-wrap__info {
	background-color: #F07036;
}

.industry-seven-slider__single-slide .item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.industry-seven-slider__single-slide .item-wrap__image {
	height: 600px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.industry-seven-slider__single-slide .item-wrap__info {
	padding: 80px;
	position: relative;
	height: 600px;
}

.industry-seven-slider__single-slide .item-wrap__info .icon {
	color: #fff;
	line-height: 1;
	font-size: 4.375rem;
	margin-bottom: 40px;
}

.industry-seven-slider__single-slide .item-wrap__info .title {
	font-size: 2.5rem;
	line-height: 1.3;
	display: block;
	position: relative;
	padding-bottom: 40px;
	margin-bottom: 40px;
	color: #ffffff;
	font-weight: 300;
}

.industry-seven-slider__single-slide .item-wrap__info .title .highlight {
	font-weight: 700;
	display: block;
}

.industry-seven-slider__single-slide .item-wrap__info .title a:hover {
	color: #ffffff;
}

.industry-seven-slider__single-slide .item-wrap__info .title:after {
	content: '';
	display: block;
	width: 70px;
	height: 5px;
	background-color: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
}

.industry-seven-slider__single-slide .item-wrap__info .excerpt {
	color: #ffffff;
	opacity: 0.7;
}

.industry-seven-slider__single-slide .item-wrap__info .show-more-link {
	font-size: 0.9375rem;
	font-weight: 700;
	text-align: right;
	color: #fff;
	position: absolute;
	right: 80px;
	bottom: 80px;
	display: inline-block;
}

.industry-seven-slider__single-slide .item-wrap__info .show-more-link i {
	display: inline-block;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	padding-left: 5px;
}

.industry-seven-slider__single-slide .item-wrap__info .show-more-link:hover i {
	-webkit-transform: translateX(5px);
	-ms-transform: translateX(5px);
	transform: translateX(5px);
}

/*=====  End of industry slider  ======*/

/*=============================================
=            testimonial slider            =
=============================================*/

.testimonial-slider__title-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #eee;
}

.testimonial-slider__title {
	font-size: 2.5rem;
	margin-top: -5px;
	margin-bottom: 0;
}

.testimonial-slider__title i {
	display: inline-block;
	vertical-align: bottom;
	font-size: 50px;
	color: #c9c9c9;
}

.testimonial-slider__title--style2 {
	line-height: 1.35;
	font-weight: 900;
}

.testimonial-slider__title--style2 i {
	margin-bottom: 20px;
	display: block;
	font-size: 3.875rem;
}

.testimonial-slider__title--style2--black3 {
	color: #05103B;
}

.testimonial-slider__nav-container .ht-swiper-button-nav {
	display: inline-block;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	width: auto;
	height: auto;
	line-height: 1;
	border: none;
	font-size: 1rem;
	font-weight: 500;
	color: #222;
	padding: 10px 20px;
	position: relative;
}

.testimonial-slider__nav-container .ht-swiper-button-nav:hover {
	background: none;
	color: #ffc246;
}

.testimonial-slider__nav-container .ht-swiper-button-nav + .ht-swiper-button-nav:before {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	content: '';
	width: 1px;
	height: 20px;
	background-color: #ddd;
	display: inline-block;
}

.testimonial-slider__nav-container .ht-swiper-button-next {
	padding-right: 0;
}

.testimonial-slider__content-area {
	margin: 0 -20px;
	margin-top: -30px;
}

.testimonial-slider__content-area .swiper-pagination {
	margin-top: 50px;
}

.testimonial-slider__content-area--style2 {
	margin-top: 0;
}

.testimonial-slider__container {
	padding: 30px 20px;
}

.testimonial-slider__container--style2 {
	padding: 0 20px;
}

.testimonial-slider__container--style3 {
	padding: 0 20px;
}

.testimonial-slider__container--style3 .testimonial-slider__single-item {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.testimonial-slider__single-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
	padding: 40px 30px;
	position: relative;
}

.testimonial-slider__single-item__image {
	-webkit-flex-basis: 90px;
	-ms-flex-preferred-size: 90px;
	flex-basis: 90px;
	margin-right: 30px;
}

.testimonial-slider__single-item__image img {
	width: 90px;
	height: 90px;
	border-radius: 50%;
}

.testimonial-slider__single-item__image--style2 {
	-webkit-flex-basis: 70px;
	-ms-flex-preferred-size: 70px;
	flex-basis: 70px;
	margin-right: 20px;
	margin-bottom: 0;
}

.testimonial-slider__single-item__image--style2 img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}

.testimonial-slider__single-item__image .quote-icon {
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	width: 54px;
	height: 54px;
	line-height: 58px;
	text-align: center;
	background-color: #ffc246;
}

.testimonial-slider__single-item__image .quote-icon i {
	color: #ffffff;
	font-size: 24px;
}

.testimonial-slider__single-item__content {
	-webkit-flex-basis: calc(100% - 120px);
	-ms-flex-preferred-size: calc(100% - 120px);
	flex-basis: calc(100% - 120px);
}

.testimonial-slider__single-item__content .testimonial-name {
	font-size: 1.25rem;
	margin-bottom: 15px;
}

.testimonial-slider__single-item__content .testimonial-name .designation {
	font-size: 15px;
	font-weight: 400;
	font-style: italic;
	color: #888;
}

.testimonial-slider__single-item__content .testimonial-name .designation:before {
	content: ' - ';
}

.testimonial-slider__single-item__content .rating {
	margin-bottom: 10px;
}

.testimonial-slider__single-item__content .rating i {
	font-size: 20px;
	color: #f8c823;
}

.testimonial-slider__single-item__content--style2 {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.testimonial-slider__single-item:before {
	position: absolute;
	top: calc(100% - 3px);
	left: 0;
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 35px 35px 0 0;
	border-color: #fff transparent transparent;
	-webkit-filter: drop-shadow(0 6px 5px rgba(0, 0, 0, 0.05));
	filter: drop-shadow(0 6px 5px rgba(0, 0, 0, 0.05));
}

.testimonial-slider__single-item__author-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 40px;
}

.testimonial-slider__single-item__author-info .testimonial-name {
	-webkit-flex-basis: calc(100% - 90px);
	-ms-flex-preferred-size: calc(100% - 90px);
	flex-basis: calc(100% - 90px);
	font-size: 1.125rem;
	line-height: 1.2;
}

.testimonial-slider__single-item__author-info .testimonial-name .designation {
	display: block;
	font-size: 0.9375rem;
	line-height: 1.8;
	color: #888;
	font-weight: 400;
	font-style: italic;
}

.testimonial-slider__single-item--style2 {
	padding: 0;
}

.testimonial-slider__single-item--style2 .testimonial-slider__single-item__image {
	-webkit-flex-basis: 250px;
	-ms-flex-preferred-size: 250px;
	flex-basis: 250px;
	margin-right: 30px;
	height: auto;
	border-radius: 0;
}

.testimonial-slider__single-item--style2 .testimonial-slider__single-item__image img {
	width: 100%;
	height: auto;
	border-radius: 0;
}

.testimonial-slider__single-item--style2 .testimonial-slider__single-item__content {
	-webkit-flex-basis: calc(100% - 250px);
	-ms-flex-preferred-size: calc(100% - 250px);
	flex-basis: calc(100% - 250px);
}

.testimonial-slider__single-item--style2 .testimonial-slider__single-item__content .testimonial-name .designation {
	color: #ffc246;
}

.testimonial-slider__single-item--style2 .testimonial-slider__single-item__content .rating {
	margin-bottom: 0;
}

.testimonial-slider__single-item--style2:before {
	display: none;
}

.testimonial-slider__single-item--style3 {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 35px 40px 45px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider__single-item--style3 .testimonial-slider__single-item__image {
	margin-bottom: 40px;
}

.testimonial-slider__single-item--style3 .testimonial-slider__single-item__content .testimonial-name {
	color: #05103B;
}

.testimonial-slider__single-item--style3 .testimonial-slider__single-item__content .testimonial-name .designation {
	display: block;
	margin-top: 5px;
}

.testimonial-slider__single-item--style3 .testimonial-slider__single-item__content .testimonial-name .designation:before {
	display: none;
}

.testimonial-slider__single-item--style3:before {
	display: none;
}

.testimonial-slider__single-item--style3:hover {
	-webkit-transform: translateY(-20px);
	-ms-transform: translateY(-20px);
	transform: translateY(-20px);
}

.testimonial-slider-two__container-area {
	position: relative;
}

.testimonial-slider-two__container-area .swiper-pagination {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 30px;
}

.testimonial-slider-two__container-area .swiper-pagination-bullet {
	width: 25px;
	height: 4px;
	background-color: #ddd;
	border-radius: 0;
	margin: 0 5px;
	opacity: .15;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider-two__container-area .swiper-pagination-bullet-active,
.testimonial-slider-two__container-area .swiper-pagination-bullet:hover {
	background-color: #ffc246;
	opacity: 1;
}

.testimonial-slider-two__slide {
	opacity: 0.3;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider-two__slide.swiper-slide-active {
	width: 1200px !important;
	max-width: 100%;
	opacity: 1;
	margin: 0 auto;
}

.testimonial-slider-two__slide.swiper-slide-active .rating {
	visibility: visible;
	opacity: 1;
}

.testimonial-slider-two__slide.swiper-slide-active .testimonial-slider-two__author-info {
	visibility: visible;
	opacity: 1;
}

.testimonial-slider-two__slide .rating {
	margin-bottom: 30px;
	line-height: 1;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider-two__slide .rating i {
	font-size: 20px;
	color: #f8c823;
}

.testimonial-slider-two__slide .text {
	font-size: 2.125rem;
	line-height: 1.4;
	color: #ffffff;
	margin-bottom: 30px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider-two__author-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider-two__author-info .image {
	width: 70px;
	margin-right: 15px;
}

.testimonial-slider-two__author-info .image img {
	border-radius: 50%;
}

.testimonial-slider-two__author-info .name-designation {
	-webkit-flex-basis: calc(100% - 70px);
	-ms-flex-preferred-size: calc(100% - 70px);
	flex-basis: calc(100% - 70px);
}

.testimonial-slider-two__author-info .name-designation .testimonial-name {
	font-size: 1.125rem;
	color: #ffffff;
}

.testimonial-slider-two__author-info .name-designation .testimonial-name .designation {
	font-size: 15px;
	margin-top: 5px;
	font-weight: 400;
	color: #888;
	display: block;
}

.testimonial-slider-three__area {
	background-repeat: no-repeat;
	background-position: top right;
}

.testimonial-slider-three__wrapper-area {
	background-repeat: no-repeat;
	background-position: right bottom;
}

.testimonial-slider-three__image {
	-webkit-transform: translateY(-80px);
	-ms-transform: translateY(-80px);
	transform: translateY(-80px);
}

.testimonial-slider-three__image img {
	width: 100%;
}

.testimonial-slider-three__container-area {
	position: relative;
	padding: 60px 80px;
}

.testimonial-slider-three__container-area .testi-quote {
	width: 116px;
	height: 87px;
	display: block;
	position: absolute;
	top: -40px;
	right: 50px;
}

.testimonial-slider-three__container-area .testi-quote svg * {
	fill: #ffc246;
}

.testimonial-slider-three__single-slide .testimonial-item__image {
	margin-bottom: 60px;
}

.testimonial-slider-three__single-slide .testimonial-item__image img {
	border-radius: 50%;
}

.testimonial-slider-three__single-slide .testimonial-item__text {
	font-size: 1.625rem;
	line-height: 1.6;
	margin-bottom: 30px;
}

.testimonial-slider-three__single-slide .testimonial-item__rating {
	margin-bottom: 30px;
	line-height: 1;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider-three__single-slide .testimonial-item__rating i {
	font-size: 20px;
	color: #f8c823;
}

.testimonial-slider-three__single-slide .testimonial-item__author .testimonial-name {
	font-size: 1.25rem;
	line-height: 1.5;
}

.testimonial-slider-three__single-slide .testimonial-item__author .testimonial-name .designation {
	margin-top: 10px;
	display: block;
	font-size: 1rem;
	line-height: 1.3;
	color: #888;
	font-style: italic;
	font-weight: 400;
}

.testimonial-slider-four__container {
	margin-top: -10px;
	padding: 0 20px;
	padding-top: 10px;
}

.testimonial-slider-four__single-item-wrapper {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider-four__single-item-wrapper:hover {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

.testimonial-slider-six__area-bg {
	background-repeat: no-repeat;
	background-position: right 105%;
}

.testimonial-slider-six__area-wrapper {
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;
	position: relative;
	z-index: 2;
}

.testimonial-slider-six__area-wrapper:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	height: 100%;
	width: 1px;
	background: #eee;
}

.testimonial-slider-six__author-single-slide .author-thumbnail {
	width: 85px;
	margin: 0 auto;
}

.testimonial-slider-six__author-single-slide .author-thumbnail img {
	width: 85px;
	border-radius: 50%;
	opacity: 0.4;
}

.testimonial-slider-six__author-single-slide.swiper-slide-active .author-thumbnail img {
	opacity: 1;
}

.testimonial-slider-six__content-container-area {
	padding-top: 60px;
}

.testimonial-slider-six__content-container-area .swiper-pagination {
	margin-top: 80px;
}

.testimonial-slider-six__content-container-area .swiper-pagination-bullet-active {
	background-color: #ffc246;
}

.testimonial-slider-six__content-container-area .ht-swiper-button-nav {
	top: 25%;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.testimonial-slider-six__content-container-area .ht-swiper-button-prev {
	left: -80px;
}

.testimonial-slider-six__content-container-area .ht-swiper-button-next {
	right: -80px;
}

.testimonial-slider-six__content-single-slide .testimonial-content-wrapper {
	width: 800px;
	max-width: 100%;
	margin: 0 auto;
	text-align: center;
}

.testimonial-slider-six__content-single-slide .testimonial-content-wrapper .testimonial-text {
	font-size: 1.875rem;
	line-height: 1.5;
	color: #222;
	margin-bottom: 20px;
}

.testimonial-slider-six__content-single-slide .testimonial-content-wrapper .rating {
	margin-bottom: 20px;
}

.testimonial-slider-six__content-single-slide .testimonial-content-wrapper .rating i {
	font-size: 20px;
	color: #ffc246;
}

.testimonial-slider-six__content-single-slide .testimonial-content-wrapper .author-name {
	font-size: 1.25rem;
	line-height: 1.2;
}

.testimonial-slider-six__content-single-slide .testimonial-content-wrapper .author-name .designation {
	font-size: 0.9375rem;
	line-height: 1.2;
	color: #999;
	font-weight: 300;
	font-style: italic;
}

.testimonial-slider-seven__area-bg {
	background-color: #05103B;
	background-size: cover;
	background-repeat: no-repeat;
}

.testimonial-slider-seven__container-area .swiper-pagination {
	margin-top: 70px;
}

.testimonial-slider-seven__container-area .swiper-pagination-bullet {
	background-color: #d8d8d8;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	opacity: 1;
}

.testimonial-slider-seven__container-area .swiper-pagination-bullet-active {
	background-color: #ffc246;
}

.testimonial-slider-seven__container-area .swiper-pagination-bullet:hover {
	background-color: #ffc246;
}

.testimonial-slider-seven__container {
	padding-top: 30px;
	margin-top: -30px;
}

/* testimonial single */

.testimonial-single__wrapper {
	margin-bottom: -30px;
}

.testimonial-single__item {
	margin-bottom: 30px;
}

/*=====  End of testimonial slider  ======*/

/*=============================================
=            brand logo slider            =
=============================================*/

.brand-logo-slider__container-area {
	margin: -20px;
}

.brand-logo-slider__container {
	padding: 20px;
}

.brand-logo-slider__single {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	text-align: center;
	position: relative;
}

.brand-logo-slider__single .image {
	-webkit-transition: all .4s linear;
	-o-transition: all .4s linear;
	transition: all .4s linear;
}

.brand-logo-slider__single .image-hover {
	visibility: hidden;
	opacity: 0;
	-webkit-transform: translate(-50%, 20px);
	-ms-transform: translate(-50%, 20px);
	transform: translate(-50%, 20px);
	-webkit-transition: all .4s linear;
	-o-transition: all .4s linear;
	transition: all .4s linear;
	position: absolute;
	top: 0;
	left: 50%;
	width: 100%;
}

.brand-logo-slider__single:hover .image {
	visibility: hidden;
	opacity: 0;
	-webkit-transform: translateY(-20px);
	-ms-transform: translateY(-20px);
	transform: translateY(-20px);
}

.brand-logo-slider__single:hover .image-hover {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

.brand-logo-grid__single {
	border: 1px solid #eee;
	text-align: center;
	padding: 20px;
	margin-bottom: 30px;
	min-height: 140px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.brand-logo-grid__single:hover {
	border-color: transparent;
	background: #fff;
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

/*=====  End of brand logo slider  ======*/

/*=============================================
=            project counter            =
=============================================*/

.project-counter-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.project-counter-bg {
	background-size: cover;
	background-position: center center;
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
}

.project-counter-single-content {
	margin-bottom: 30px;
}

.project-counter-single-content__image {
	margin-bottom: 40px;
}

.project-counter-single-content__project-count {
	font-size: 3.75rem;
	line-height: 1;
	color: #ffc246;
	font-weight: 500;
	display: inline-block;
	margin-bottom: 20px;
}

.project-counter-single-content__project-title {
	font-size: 1.25rem;
	line-height: 1.5;
	margin-bottom: 15px;
	color: #ffffff;
}

.project-counter-single-content--style2 .project-counter-single-content__image {
	margin-bottom: 30px;
}

.project-counter-single-content--style2 .project-counter-single-content__project-title {
	color: #222;
}

.project-counter-content {
	padding: 120px 150px;
	background-color: #222;
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
}

.project-counter-single-content-wrapper {
	margin-bottom: -30px;
}

.counter-brand-logo-area-bg {
	background-size: cover;
	background-repeat: no-repeat;
}

.project-counter-two-area-bg {
	background-color: #ffc246;
}

.project-counter-two-wrapper {
	margin-bottom: -30px;
}

.project-counter-two-single {
	margin-bottom: 30px;
}

.project-counter-two-single__number {
	font-size: 2.75rem;
	line-height: 1;
	color: #ffffff;
	margin-bottom: 20px;
}

.project-counter-two-single__text {
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.52);
}

/*=====  End of project counter  ======*/

/*=============================================
=            blog newsletter            =
=============================================*/

.blog-title-wrapper .single-blog-title {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #e9e9e9;
}

.blog-title-wrapper .single-blog-title:first-child {
	margin-top: 0;
	padding-top: 0;
	border-top: 0;
}

.blog-title-wrapper .single-blog-title .post-title {
	font-size: 1.5rem;
}

.blog-title-wrapper .single-blog-title--style2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.blog-title-wrapper .single-blog-title--style2 .post-image {
	-webkit-flex-basis: 105px;
	-ms-flex-preferred-size: 105px;
	flex-basis: 105px;
	margin-right: 20px;
}

.blog-title-wrapper .single-blog-title--style2 .post-meta {
	-webkit-flex-basis: calc(100% - 105px);
	-ms-flex-preferred-size: calc(100% - 105px);
	flex-basis: calc(100% - 105px);
}

.blog-title-wrapper .single-blog-title--style2 .post-title {
	font-size: 1.25rem;
	margin-top: -5px;
}

.blog-title-wrapper--style2 {
	margin-bottom: 0;
}

.blog-title-wrapper--style3 {
	padding: 80px 40px 80px;
	background-color: #ffffff;
	margin-bottom: 0;
}

.post-title {
	margin-bottom: 10px;
}

.post-title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.post-title a:hover {
	color: #ffc246;
	text-decoration: underline;
}

.post-title--black2 {
	color: #11202D;
}

.post-title--black3 {
	color: #05103B;
}

.post-date {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	color: #b5b5b5;
	letter-spacing: 1px;
	line-height: 1;
}

.blog-grid-wrapper {
	margin-bottom: -40px;
}

.blog-grid-wrapper--style2 {
	margin-top: 20px;
	margin-bottom: -65px;
}

.blog-grid-wrapper--style4 {
	margin-bottom: -30px;
}

.blog-grid-wrapper--style5 {
	margin-top: 27px;
	margin-bottom: -100px;
}

.blog-grid-wrapper--style6 {
	margin-bottom: -30px;
}

.blog-post-slider__single-slide--grid-view {
	margin-bottom: 40px;
}

.blog-post-slider__single-slide--grid-view:hover .blog-post-slider__image a:before {
	background-color: rgba(0, 0, 0, 0.3);
}

.blog-post-slider__single-slide--grid-view--style2 {
	-webkit-box-shadow: 0 20px 20px rgba(0, 0, 0, 0.03);
	box-shadow: 0 20px 20px rgba(0, 0, 0, 0.03);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	position: relative;
}

.blog-post-slider__single-slide--grid-view--style2:hover {
	-webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.09);
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.09);
}

.blog-post-slider__single-slide--grid-view--style3 {
	-webkit-box-shadow: 0 20px 20px rgba(0, 0, 0, 0.03);
	box-shadow: 0 20px 20px rgba(0, 0, 0, 0.03);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	position: relative;
	margin-bottom: 30px;
}

.blog-post-slider__single-slide--grid-view--style3:hover {
	-webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.09);
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.09);
}

.blog-post-slider__single-slide--style2 .blog-post-slider__content {
	line-height: 1;
}

.blog-post-slider__single-slide--style2 .blog-post-slider__content .post-categories {
	margin-bottom: 10px;
}

.blog-post-slider__single-slide--style2 .blog-post-slider__content .post-categories a {
	padding: 4px 9px;
	margin: 5px 3px;
	margin-top: 0;
	border: 1px solid #ddd;
	color: #222;
	background-color: #ffc246;
	font-size: 13px;
	line-height: 1.5;
	font-weight: 500;
	border-color: #ffc246;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blog-post-slider__single-slide--style2 .blog-post-slider__content .post-categories a:hover {
	border-color: #222;
	background-color: #222;
	color: #ffffff;
}

.blog-post-slider__single-slide--style2 .blog-post-slider__content .post-excerpt {
	padding-top: 20px;
	border-top: 1px solid #ddd;
	line-height: 1.5;
}

.blog-post-slider__single-slide--style2 .blog-post-slider__content .post-title a:hover {
	text-decoration: none;
}

.blog-post-slider__single-slide--style2 .blog-post-slider__content .blog-readmore {
	line-height: 1;
}

.blog-post-slider__single-slide--extra-space {
	margin-bottom: 60px !important;
}

.blog-post-slider__image a {
	position: relative;
	display: block;
	width: 100%;
}

.blog-post-slider__image a:hover:before {
	background-color: rgba(0, 0, 0, 0.3);
}

.blog-post-slider__image a img {
	width: 100%;
}

.blog-post-slider__image a:before {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: inline-block;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

.blog-post-slider__content .post-meta {
	margin-bottom: 15px;
}

.blog-post-slider__content .post-date {
	margin-bottom: 25px;
}

.blog-post-slider__content .post-title {
	font-size: 1.625rem;
	margin-bottom: 20px;
}

.blog-post-slider__content .post-category {
	display: inline-block;
}

.blog-post-slider__content .post-category a {
	font-size: 0.8125rem;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	font-weight: 700;
	color: #ffc246;
	letter-spacing: 1px;
}

.blog-post-slider__content .post-category a:hover {
	color: #222;
}

.blog-post-slider__content--style2 {
	padding: 26px 30px 54px;
	background-color: #ffffff;
}

.blog-post-slider__content--style2 .post-title {
	font-size: 1.5rem;
	line-height: 1.4;
	margin-bottom: 17px;
	padding-bottom: 17px;
	border-bottom: 1px solid #e9e9e9;
}

.blog-post-slider__content--style2 .post-date {
	display: inline-block;
	position: relative;
	margin-right: 7px;
	padding-right: 15px;
	margin-bottom: 0;
}

.blog-post-slider__content--style2 .post-date:before {
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #ddd;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

/* newsletter box wrapper */

.newsletter-box-wrapper {
	padding: 50px 30px 45px;
	background-color: #ffc246;
	background-repeat: no-repeat;
	background-position: right bottom;
}

.newsletter-box-wrapper .newsletter-title {
	font-size: 2rem;
	max-width: 250px;
	margin-bottom: 60px;
}

.newsletter-box-wrapper .newsletter-title--white {
	color: #ffffff;
}

.newsletter-box-wrapper .newsletter-form-wrapper {
	margin-bottom: 80px; /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */
}

.newsletter-box-wrapper .newsletter-form-wrapper input {
	background-color: #fff;
	border: 0;
	padding: 0 15px;
	display: block;
	margin-bottom: 20px;
	height: 50px;
	color: #222;
	width: 100%;
}

.newsletter-box-wrapper .newsletter-form-wrapper ::-webkit-input-placeholder { /* Edge */
	color: #ACACAC;
}

.newsletter-box-wrapper .newsletter-form-wrapper :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #ACACAC;
}

.newsletter-box-wrapper .newsletter-form-wrapper ::-moz-placeholder {
	color: #ACACAC;
}

.newsletter-box-wrapper .newsletter-form-wrapper ::-ms-input-placeholder {
	color: #ACACAC;
}

.newsletter-box-wrapper .newsletter-form-wrapper ::placeholder {
	color: #ACACAC;
}

.newsletter-box-wrapper .newsletter-form-wrapper input:focus::-webkit-input-placeholder {
	color: transparent;
}

.newsletter-box-wrapper .newsletter-form-wrapper input:focus:-moz-placeholder {
	color: transparent;
}

.newsletter-box-wrapper .newsletter-form-wrapper input:focus::-moz-placeholder {
	color: transparent;
}

.newsletter-box-wrapper .newsletter-form-wrapper input:focus:-ms-input-placeholder {
	color: transparent;
}

.newsletter-box-wrapper .newsletter-form-wrapper button {
	width: 100%;
	background: #222;
	color: #ffffff;
	height: 50px;
	border: 2px solid #222;
	padding: 0 40px;
	font-size: 0.8125rem;
	font-weight: 700;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.newsletter-box-wrapper .newsletter-form-wrapper button:hover {
	background-color: transparent;
	color: #222;
}

.newsletter-box-wrapper .newsletter-form-wrapper--black2 button {
	background: #11202D;
	border: 2px solid #11202D;
}

.newsletter-box-wrapper .newsletter-form-wrapper--black2 button:hover {
	color: #11202D;
}

.newsletter-box-wrapper .call-text {
	font-size: 1rem;
	margin-bottom: 0;
	text-align: center;
}

.newsletter-box-wrapper .call-text--white {
	color: #ffffff;
}

/* single grid blog post */

.single-grid-blog-post {
	margin-bottom: 65px;
}

.single-grid-blog-post__image {
	position: relative;
}

.single-grid-blog-post__image .post-date {
	position: absolute;
	right: 0;
	bottom: 100%;
	font-size: 0.8125rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	padding: 7px 10px;
	background-color: #ffc246;
}

.single-grid-blog-post__image .post-image {
	overflow: hidden;
	margin-bottom: 30px;
}

.single-grid-blog-post__image .post-image a {
	display: block;
	width: 100%;
}

.single-grid-blog-post__image .post-image a img {
	width: 100%;
	-webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
	-o-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
	transition: all 1s cubic-bezier(0, 0, 0.2, 1);
}

.single-grid-blog-post__image--style2 {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-grid-blog-post__image--style2 .post-date {
	right: 30px;
	top: -27px;
	width: 60px;
	height: 60px;
	padding: 10px 5px 0;
	border-radius: 100%;
	text-align: center;
	color: #222;
	font-size: 20px;
	font-weight: 700;
	text-transform: capitalize;
	line-height: 1;
}

.single-grid-blog-post__image--style2 .post-date span {
	font-size: 13px;
}

.single-grid-blog-post__image--style2 .post-image {
	overflow: visible;
}

.single-grid-blog-post__content .post-categories {
	margin-bottom: 10px;
}

.single-grid-blog-post__content .post-categories a:hover {
	color: #ffc246;
}

.single-grid-blog-post__content .post-title {
	margin-bottom: 0;
	font-size: 1.5rem;
	line-height: 1.4;
}

.single-grid-blog-post__content .post-title a:hover {
	text-decoration: none;
}

.single-grid-blog-post__content--style2 .post-categories a {
	padding: 4px 9px;
	margin: 5px 3px;
	border: 1px solid #ddd;
	color: #222;
	font-size: 13px;
	line-height: 1.5;
	font-weight: 500;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-grid-blog-post__content--style2 .post-categories a:hover {
	background-color: #ffc246;
	border-color: #ffc246;
	color: #222;
}

.single-grid-blog-post__content--style2 .post-title a {
	color: #222;
}

.single-grid-blog-post__content--style2 .post-title a:hover {
	text-decoration: underline;
}

.single-grid-blog-post:hover .single-grid-blog-post__image img {
	-webkit-transform: scale3d(1.15, 1.15, 1.15);
	transform: scale3d(1.15, 1.15, 1.15);
}

.single-grid-blog-post--style2 {
	margin-bottom: 100px;
}

.single-grid-blog-post--style2:hover .single-grid-blog-post__image--style2 {
	-webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

.single-grid-blog-post--style2:hover .single-grid-blog-post__image--style2 img {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.single-grid-overlay-blog-post {
	position: relative;
}

.single-grid-overlay-blog-post__image {
	overflow: hidden;
}

.single-grid-overlay-blog-post__image a {
	position: relative;
	display: block;
	width: 100%;
}

.single-grid-overlay-blog-post__image a img {
	width: 100%;
	-webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
	-o-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
	transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.single-grid-overlay-blog-post__image a:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000));
	background: -webkit-linear-gradient(top, transparent, #000);
	background: -o-linear-gradient(top, transparent, #000);
	background: linear-gradient(to bottom, transparent, #000);
}

.single-grid-overlay-blog-post__content {
	position: absolute;
	bottom: 0;
	padding: 0 25px 25px;
}

.single-grid-overlay-blog-post__content .post-categories {
	margin-bottom: 15px;
}

.single-grid-overlay-blog-post__content .post-categories a {
	display: inline-block;
	font-size: 0.8125rem;
	font-weight: 500;
	color: #222;
	padding: 2px 9px;
	margin: 0 5px 5px;
	background-color: #ffc246;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-grid-overlay-blog-post__content .post-categories a:hover {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

.single-grid-overlay-blog-post__content .post-title {
	font-size: 1.5rem;
}

.single-grid-overlay-blog-post__content .post-title a {
	color: #ffffff;
}

.single-grid-overlay-blog-post__content .post-title a:hover {
	text-decoration: none;
	color: #ffc246;
}

.single-grid-overlay-blog-post__content .post-date {
	color: #ffffff;
}

.single-grid-overlay-blog-post__content--style2 {
	padding: 0 45px 45px;
}

.single-grid-overlay-blog-post:hover .single-grid-overlay-blog-post__image a img {
	-webkit-transform: scale3d(1.15, 1.15, 1.15);
	transform: scale3d(1.15, 1.15, 1.15);
}

/* hiring background */

.hiring-background {
	padding: 50px 40px;
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
}

.hiring-background__content .title {
	font-size: 1.875rem;
	line-height: 1.2;
	color: #ffffff;
	font-weight: 900;
	margin-bottom: 30px;
}

.hiring-background__content .text {
	color: #ffffff;
	margin-bottom: 40px;
}

.hiring-background__content .ht-btn {
	margin-bottom: 170px;
}

.blog-grid-area-bg {
	background-size: cover;
	background-repeat: no-repeat;
}

/*=====  End of blog newsletter  ======*/

/*=============================================
=            support footer area            =
=============================================*/

.support-footer__single {
	text-align: center;
	font-weight: 700;
	color: #222;
	font-size: 1.5rem;
	background-color: #ffc246;
	background-repeat: no-repeat;
	background-position: 110px center;
	padding: 35px 15px;
}

.support-footer__single--dark {
	background-color: #f8b224;
}

.support-footer__single--white-text {
	color: #ffffff;
}

.support-footer__area .contact-form-area {
	width: 770px;
	max-width: 100%;
	float: right;
	margin-right: 130px;
}

.google-map {
	height: 560px;
}

.google-map--style2 {
	height: 100%;
}

.google-map--style3 {
	height: 100%;
}

.gmap-marker-wrap {
	padding: 20px;
}

.gmap-marker-title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 14px;
}

.gmap-marker-content {
	font-size: 14px;
}

.gmap-marker-content i {
	font-size: 15px;
	margin-right: 5px;
	color: #ffc246;
}

.gm-style .gm-style-iw {
	-webkit-box-shadow: 0 30px 30px rgba(0, 0, 0, 0.12);
	box-shadow: 0 30px 30px rgba(0, 0, 0, 0.12);
}

.animated-dot {
	width: 100%;
	height: 100%;
	left: -9px;
	top: -5px;
	position: relative;
	background-color: #ffc246;
}

.animated-dot .middle-dot {
	bottom: -20px;
	position: absolute;
	font-size: 2.5rem;
	color: #ffc246;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated-dot .middle-dot:after {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	content: ' ';
	background-color: #fff;
	border-radius: 30px;
	z-index: 100;
	opacity: 0;
	-webkit-animation: animationSignal2 cubic-bezier(0, 0.55, 0.55, 1) 2s;
	animation: animationSignal2 cubic-bezier(0, 0.55, 0.55, 1) 2s;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-delay: 0.78s;
	animation-delay: 0.78s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated-dot .signal {
	width: 180px;
	pointer-events: none;
	height: 180px;
	border-radius: 200px;
	position: absolute;
	left: -80px;
	top: -80px;
	opacity: 0;
	-webkit-animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
	animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-delay: 0.78s;
	animation-delay: 0.78s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	background-color: #ffc246;
}

.animated-dot .signal2 {
	width: 180px;
	height: 180px;
	pointer-events: none;
	border-radius: 200px;
	position: absolute;
	left: -80px;
	top: -80px;
	opacity: 0;
	-webkit-animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
	animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	background-color: #ffc246;
}

.animated-dot.red-color {
	background-color: #F0263F;
}

.animated-dot.red-color .middle-dot {
	background-color: #F0263F;
}

.animated-dot.red-color .signal {
	background-color: #F0263F;
}

.animated-dot.red-color .signal2 {
	background-color: #F0263F;
}

/*=====  End of support footer area  ======*/

/*=============================================
=            cta area            =
=============================================*/

.cta-video-text {
	line-height: 1.5;
}

.cta-video-image {
	position: relative;
}

.cta-video-image > a {
	display: block;
	width: 100%;
}

.cta-video-image .video-popup a {
	display: block;
}

.cta-video-image__video-mark {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	z-index: -1;
}

.cta-video-image__video-mark .wave-pulse {
	width: 1px;
	height: 0;
	margin: 0 auto;
}

.cta-video-image__video-mark .wave-pulse:after {
	opacity: 0;
	content: '';
	display: block;
	position: absolute;
	width: 400px;
	height: 400px;
	top: 50%;
	border-radius: 50%;
	border: 1px solid #d8d8d8;
	-webkit-animation: zoomBig 5.25s linear 5.25s infinite;
	animation: zoomBig 5.25s linear 5.25s infinite;
}

.cta-video-image__video-mark .wave-pulse-1:before {
	-webkit-animation-delay: .75s;
	animation-delay: .75s;
}

.cta-video-image__video-mark .wave-pulse-2:before {
	-webkit-animation-delay: 2.25s;
	animation-delay: 2.25s;
}

.cta-video-image__video-mark .wave-pulse-2:after {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
}

.cta-video-image__video-mark .wave-pulse-3:before {
	-webkit-animation-delay: 3.75s;
	animation-delay: 3.75s;
}

.cta-video-image__video-mark .wave-pulse-3:after {
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.cta-video-image__image {
	overflow: hidden;
}

.cta-video-image__image img {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-duration: 0.6s;
	-o-transition-duration: 0.6s;
	transition-duration: 0.6s;
	width: 100%;
}

.cta-video-image__icon {
	position: absolute;
	top: 50%;
	left: -34px;
	-webkit-transform: translateY(-50%) scale(1);
	-ms-transform: translateY(-50%) scale(1);
	transform: translateY(-50%) scale(1);
	width: 74px;
	height: 74px;
	line-height: 74px;
	border-radius: 100%;
	color: #222;
	background-color: #fff;
	-webkit-box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
	text-align: center;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-duration: 0.6s;
	-o-transition-duration: 0.6s;
	transition-duration: 0.6s;
}

.cta-video-image__icon i {
	font-size: 20px;
	margin-left: 1px;
}

.cta-video-image:hover .cta-video-image__image img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.cta-video-image:hover .cta-video-image__icon {
	-webkit-transform: translateY(-50%) scale(1.1);
	-ms-transform: translateY(-50%) scale(1.1);
	transform: translateY(-50%) scale(1.1);
}

/* zoomBig animation */

/* cta area */

.cta-area-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	position: relative;
	z-index: 1;
}

.cta-area-bg:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 0.3;
	content: "";
	z-index: -1;
}

.cta-area-bg--style2 {
	background-attachment: scroll;
}

.cta-content__title {
	font-size: 2.75rem;
	color: #ffffff;
	margin-top: -5px;
}

.cta-content__title--style2 {
	font-size: 3.75rem;
	line-height: 1.2;
	margin-bottom: 30px;
}

.cta-content__subtitle {
	font-size: 1.25rem;
	line-height: 1.2;
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 30px;
}

.cta-content__text {
	margin-top: 25px;
	font-size: 0.9375rem;
	line-height: 1.3;
	font-weight: 500;
	color: #ffffff;
}

.cta-content__text .highlight {
	text-decoration: underline;
}

.video-play-button-wrapper a:hover .video-play-icon {
	-webkit-transform: scale3d(1.15, 1.15, 1.15);
	transform: scale3d(1.15, 1.15, 1.15);
}

.video-play-icon {
	width: 75px;
	height: 75px;
	color: #fff;
	border: 3px solid #fff;
	border-radius: 50%;
	position: relative;
	margin: 0 auto;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.video-play-icon i {
	font-size: 20px;
	line-height: 1;
	margin-left: 2px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.cta-blog-area {
	padding: 370px 80px 80px 80px;
	background-size: cover;
	background-repeat: no-repeat;
}

.cta-blog-content__title {
	font-size: 3.125rem;
	line-height: 1.2;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 30px;
}

.cta-blog-content__title .highlight {
	font-weight: 700;
}

.cta-blog-content__text {
	font-size: 1.125rem;
	line-height: 1.2;
	margin-bottom: 30px;
}

.cta-text {
	font-size: 2.25rem;
	line-height: 1.2;
	margin-bottom: 0;
}

/*=====  End of cta area  ======*/

/*=============================================
=            career section            =
=============================================*/

.career-banner__image img {
	width: 100%;
}

.career-banner__content {
	height: 100%;
	padding: 50px 40px;
	background-color: #ffc246;
}

.career-banner__content .subtitle {
	font-size: 0.875rem;
	line-height: 1.3;
	color: rgba(34, 34, 34, 0.5);
	margin-bottom: 40px;
}

.career-banner__content .title {
	font-size: 2.5rem;
	margin-bottom: 80px;
}

.job-list__wrapper {
	padding: 40px;
}

.job-list__title-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.job-list__title-wrapper .title {
	font-size: 2.125rem;
	line-height: 1.5;
}

.job-list__title-wrapper .link {
	font-size: 0.8125rem;
	font-weight: 700;
	line-height: 40px;
	color: #222;
}

.job-list__title-wrapper .link a i {
	content: '\f2c7';
	font-family: Ionicons;
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
	vertical-align: middle;
	margin-left: 15px;
	width: 40px;
	height: 40px;
	line-height: 38px;
	text-align: center;
	border: 1px solid #ddd;
	border-radius: 50%;
}

.job-list__title-wrapper .link a:hover {
	color: #ffc246;
}

.job-list__title-wrapper .link a:hover i {
	background-color: #ffc246;
	color: #ffffff;
	border-color: #ffc246;
}

.job-list__single-wrapper {
	margin-top: 20px;
}

.job-list__single {
	display: block;
	padding: 20px 0;
	border-bottom: 1px solid #ddd;
}

.job-list__single:hover .title {
	color: #ffc246;
}

.job-list__single .title {
	font-size: 1.25rem;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.job-list__single .text {
	color: #888;
}

.job-list__single:first-child {
	padding-top: 0;
}

.job-list__single:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.career-background__area-bg {
	background-size: cover;
	background-repeat: no-repeat;
}

.career-background__content {
	background: #ffc246;
	padding: 80px 50px 60px 50px;
	color: #ffffff;
}

.career-background__content .title {
	color: #ffffff;
	font-size: 2.75rem;
	line-height: 1.2;
	font-weight: 300;
	margin-bottom: 20px;
}

.career-background__content .title .highlight {
	font-weight: 700;
}

.career-background__content .text {
	margin-bottom: 30px;
}

/*=====  End of career section  ======*/

/*=============================================
=            icon info            =
=============================================*/

.icon-info-wrapper {
	margin-bottom: -30px;
}

.single-icon-info {
	margin-bottom: 30px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.single-icon-info__image {
	-webkit-flex-basis: 100px;
	-ms-flex-preferred-size: 100px;
	flex-basis: 100px;
}

.single-icon-info__content {
	-webkit-flex-basis: calc(100% - 100px);
	-ms-flex-preferred-size: calc(100% - 100px);
	flex-basis: calc(100% - 100px);
	margin-left: 20px;
}

.single-icon-info__title {
	font-size: 1.125rem;
	line-height: 1.2;
	margin-bottom: 15px;
}

.single-icon-info__title--black2 {
	color: #11202D;
}

.single-icon-info__title--black3 {
	color: #05103B;
}

.single-icon-info__text:last-child {
	margin-bottom: -5px;
}

.single-icon-info:hover .single-icon-info__image {
	-webkit-animation: box-image-pop .3s linear;
	animation: box-image-pop .3s linear;
}

/*=====  End of icon info  ======*/

/*=============================================
=            banner            =
=============================================*/

.banner-video-area {
	background-repeat: no-repeat;
	background-position: left center;
}

.banner-image img {
	width: 100%;
}

.banner-image-content {
	padding: 30px 80px;
}

.banner-image-content__title {
	font-size: 3.125rem;
	line-height: 1.2;
	font-weight: 400;
	color: #06112c;
	margin-bottom: 30px;
}

.banner-image-content__title .highlight {
	font-weight: 700;
	display: block;
}

.banner-image-content__text {
	font-size: 1.125rem;
	line-height: 1.4;
	margin-bottom: 30px;
}

.banner-image-content__btn {
	border: 2px solid #ddd;
	height: 50px;
	letter-spacing: .5px;
	padding: 0 25px;
	line-height: 46px;
	font-size: 0.8125rem;
	font-weight: 700;
	color: #222;
}

.banner-image-content__btn:hover {
	background-color: #ffc246;
	border-color: #ffc246;
	color: #222;
}

.banner-image-two {
	padding: 140px 0;
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
}

.banner-image-two__content {
	width: 660px;
	max-width: 100%;
	padding-left: 45px;
	padding-right: 45px;
	margin: 0 auto;
}

.banner-image-two__subtitle {
	letter-spacing: 2px;
	font-size: 0.875rem;
	line-height: 1.1;
	color: #ffc246;
	margin-bottom: 15px;
}

.banner-image-two__title {
	font-size: 3.125rem;
	line-height: 1.3;
	color: #ffffff;
	font-weight: 300;
	margin-bottom: 30px;
}

.banner-image-two__title .highlight {
	font-weight: 700;
	display: block;
}

.banner-image-two__text {
	font-size: 0.9375rem;
	color: #ffffff;
	font-weight: 400;
	max-width: 450px;
	margin-bottom: 40px;
}

.banner-image-two .ht-btn {
	color: #222;
}

.banner-image-three-area {
	background-repeat: no-repeat;
	background-position: right 50px;
}

.banner-image-three__left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.banner-image-three__content {
	max-width: 450px;
}

.banner-image-three__content .subtitle {
	font-size: 0.875rem;
	line-height: 1.2;
	font-weight: 700;
	color: #888;
	letter-spacing: 2px;
	margin-bottom: 30px;
}

.banner-image-three__content .title {
	font-size: 3.125rem;
	line-height: 1.2;
	font-weight: 300;
	margin-bottom: 50px;
}

.banner-image-three__content .title .highlight {
	color: #ffc246;
	font-weight: 700;
}

.banner-image-three__content .subtitle-big {
	font-size: 1.25rem;
	line-height: 1.3;
	font-weight: 400;
	color: #222;
	margin-bottom: 20px;
}

.banner-image-three__content .text {
	margin-bottom: 40px;
}

.banner-image-three__image {
	padding: 120px 30px;
}

.banner-image-three__image .banner-image {
	position: relative;
}

.banner-image-three__image .banner-sign {
	position: absolute;
	bottom: -40px;
	right: -80px;
}

/*=====  End of banner  ======*/

/*=============================================
=            partner career            =
=============================================*/

.partner-career__area {
	background-repeat: no-repeat;
	background-position: right bottom;
}

.partner-career__single-wrapper {
	margin-bottom: -40px;
}

.partner-career__single {
	position: relative;
	padding-bottom: 70px;
	margin-bottom: 40px;
}

.partner-career__single__image {
	overflow: hidden;
}

.partner-career__single__image img {
	width: 100%;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.partner-career__single__content {
	width: 330px;
	max-width: 100%;
	padding: 40px;
	background-color: #ffffff;
	-webkit-box-shadow: 0 30px 50px rgba(0, 0, 0, 0.06);
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.06);
	position: absolute;
	bottom: 0;
	right: 0;
}

.partner-career__single__content .title {
	font-size: 1.625rem;
	margin-bottom: 30px;
}

.partner-career__single__content .link {
	overflow: hidden;
}

.partner-career__single__content .link a {
	float: right;
	text-transform: uppercase;
	font-size: 0.8125rem;
	font-weight: 700;
	color: #ffc246;
	letter-spacing: 1px;
	line-height: 1;
	padding-bottom: 10px;
	border-bottom: 2px solid #eee;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.partner-career__single__content .link a:hover {
	color: #222;
}

.partner-career__single:hover .partner-career__single__image img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

/*=====  End of partner career  ======*/

/*=============================================
=            industry grid            =
=============================================*/

.industry-grid-wrapper {
	margin-bottom: -60px;
}

.single-industry-grid__heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 25px;
}

.single-industry-grid__heading .icon {
	font-size: 3.125rem;
	margin-right: 20px;
	line-height: 1;
	color: #222;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-industry-grid__heading .title {
	font-size: 1.25rem;
}

.single-industry-grid__heading .title a {
	color: #222;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-industry-grid__heading .title a:hover {
	color: #ffc246;
}

.single-industry-grid__heading:hover .icon {
	color: #ffc246;
}

.single-industry-grid-two {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.11);
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.11);
}

.single-industry-grid-two__image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left center;
	min-height: 400px;
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
}

.single-industry-grid-two__content {
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
	padding: 100px 30px;
	position: relative;
}

.single-industry-grid-two__content .content-wrapper {
	margin: 0 auto;
	max-width: 530px;
}

.single-industry-grid-two__content .title {
	font-size: 2.5rem;
	font-weight: 300;
	margin-bottom: 40px;
	line-height: 1.3;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.single-industry-grid-two__content .title a {
	color: #222;
}

.single-industry-grid-two__content .title .highlight {
	font-weight: 700;
	display: block;
}

.single-industry-grid-two__content .title i {
	font-size: 5rem;
	line-height: 1;
	margin-right: 40px;
	color: #ffc246;
}

.single-industry-grid-two .post-readmore-btn {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	width: 60px;
	height: 100%;
	background-color: #ffc246;
	line-height: 60px;
	color: #222;
	font-weight: 700;
	font-size: 0.8125rem;
}

.single-industry-grid-two .post-readmore-btn span {
	display: block;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-transform-origin: left bottom;
	-ms-transform-origin: left bottom;
	transform-origin: left bottom;
	white-space: nowrap;
	position: relative;
	z-index: 3;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-industry-grid-two .post-readmore-btn i {
	position: absolute;
	top: auto;
	bottom: 20px;
	left: 50%;
	-webkit-transform: translateX(-50%) rotate(90deg);
	-ms-transform: translateX(-50%) rotate(90deg);
	transform: translateX(-50%) rotate(90deg);
	font-size: 1.25rem;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	z-index: 3;
}

.single-industry-grid-two .post-readmore-btn:after {
	width: 100%;
	height: 0;
	content: '';
	background-color: #222;
	display: inline-block;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-industry-grid-two .post-readmore-btn:hover span {
	color: #ffffff;
}

.single-industry-grid-two .post-readmore-btn:hover i {
	color: #ffffff;
}

.single-industry-grid-two .post-readmore-btn:hover:after {
	height: 100%;
	top: 0;
	bottom: auto;
}

.single-industry-grid-two:nth-child(2n) .single-industry-grid-two__image {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2;
}

.single-industry-grid-two:nth-child(2n) .single-industry-grid-two__content {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.single-industry-grid-two:nth-child(3n+1) {
	background-color: #222;
}

.single-industry-grid-two:nth-child(3n+1) .single-industry-grid-two__content .title {
	color: #ffffff;
}

.single-industry-grid-two:nth-child(3n+1) .single-industry-grid-two__content .title a {
	color: #ffffff;
}

.single-industry-grid-two:nth-child(3n+1) .single-industry-grid-two__content .title a:hover {
	color: #ffffff;
}

/*=====  End of industry grid  ======*/

/*=============================================
=            feature backgroun            =
=============================================*/

.feature-background__wrapper {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center bottom;
}

.feature-background__image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center bottom;
	min-height: 300px;
	height: 100%;
}

.feature-background__image--style2 {
	min-height: 600px;
}

.feature-background__content {
	width: 600px;
	max-width: 100%;
	padding: 50px 0;
	padding-left: 120px;
}

.feature-background__content--style3 {
	padding-top: 135px;
	padding-bottom: 135px;
	float: right;
	width: 460px;
	max-width: 100%;
	padding-left: 0;
}

.feature-background__content--style3 .title {
	color: #05103B;
}

.feature-background__content--style2 {
	padding-top: 120px;
	padding-bottom: 120px;
}

.feature-background__content-area {
	padding-left: 200px;
}

.feature-background__content-area--style2 {
	padding-left: 100px;
}

.feature-background__content-wrapper {
	width: 670px;
	max-width: 100%;
}

.feature-background__single-content-wrapper .ht-btn {
	margin-top: 30px;
}

.feature-background__single-content {
	margin-bottom: 30px;
}

.feature-background__single-content .title {
	font-size: 1.25rem;
	font-weight: 700;
	color: #ffc246;
	margin-bottom: 25px;
}

.feature-list li {
	font-size: 0.9375rem;
	position: relative;
	margin-bottom: 25px;
	padding-left: 30px;
}

.feature-list li:last-child {
	margin-bottom: 0;
}

.feature-list li:before {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	display: block;
	font-family: Ionicons;
	content: '\f122';
	font-size: 0.75rem;
	color: #ffc246;
}

/*=====  End of feature background  ======*/

/*=============================================
=            about counter            =
=============================================*/

.about-content-row__content .title {
	margin-top: -5px;
}

.about-counter-content__area {
	background-size: cover;
	background-repeat: no-repeat;
}

.about-counter-content__content .title {
	font-size: 1.25rem;
	margin-bottom: 25px;
}

.about-counter-content__content .title--black3 {
	color: #05103B;
}

.single-counter__number {
	margin-bottom: 20px;
	font-size: 5rem;
	font-weight: 700;
	line-height: 1;
	color: #ffc246;
}

.single-counter__number .number {
	margin-bottom: 20px;
	font-size: 5rem;
	font-weight: 700;
	line-height: 1;
}

.single-counter__number .number-suffix {
	font-size: 26px;
	font-weight: 700;
	vertical-align: top;
	position: relative;
	/* left: 15px; */
}

.single-counter__text {
	font-size: 1.125rem;
	margin-bottom: 0;
}

.single-counter__text--black3 {
	color: #05103B;
}

/*=====  End of about counter  ======*/

/*=============================================
=            project grid post            =
=============================================*/

.project-grid-post__single {
	margin-bottom: 30px;
}

.project-grid-post__single:hover .project-grid-post__image {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

.project-grid-post__image {
	margin-bottom: 30px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.project-grid-post__image img {
	width: 100%;
}

.project-grid-post__title {
	margin-bottom: 20px;
	font-size: 1.25rem;
}

.project-grid-post__title--black3 {
	color: #05103B;
}

.project-grid-post__text {
	margin-bottom: 20px;
}

.project-grid-post__feature-list {
	padding: 45px 50px;
	border: 3px solid #ffc246;
	width: 100%;
}

.project-grid-post__feature-list .title {
	font-size: 1.5rem;
	line-height: 1.2;
	color: #05103B;
	font-weight: 500;
	margin-bottom: 25px;
}

.project-grid-post__feature-list .features {
	margin-bottom: 30px;
}

.project-grid-post__feature-list .features li {
	color: #05103B;
	position: relative;
	padding-left: 30px;
	margin-bottom: 15px;
}

.project-grid-post__feature-list .features li:before {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	font-family: Ionicons;
	content: '\f122';
	font-size: 12px;
	color: #ffc246;
}

/* project grid */

/*-- Project Slider --*/

.project-grid-5-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.project-grid-5-column .project-item {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 20%;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%;
}

/*-- Project --*/

.project {
	position: relative;
}

.project .image {
	display: block;
	position: relative;
}

.project .image::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: #000000;
	opacity: 0;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.project .image img {
	width: 100%;
}

.project .content {
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100%;
	text-align: center;
	padding: 0 30px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	opacity: 0;
}

.project .content .title {
	font-size: 18px;
	color: #ffffff;
	margin-bottom: 45px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.project .content .title a:hover {
	color: #ffc246;
}

.project .content .category {
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.project .content .category a {
	color: #ffffff;
	margin-right: 5px;
	font-size: 13px;
}

.project .content .category a::after {
	content: ',';
}

.project .content .category a:last-child {
	margin-right: 0;
}

.project .content .category a:last-child::after {
	display: none;
}

.project .content .category a:hover {
	color: #ffc246;
}

.project:hover .image::before {
	left: 10px;
	top: 10px;
	right: 10px;
	bottom: 10px;
	opacity: 0.75;
}

.project:hover .content {
	opacity: 1;
}

.project:hover .content .title {
	margin-bottom: 5px;
}

.project:hover .content .category a {
	color: #ffffff;
	margin-right: 5px;
	font-size: 13px;
}

.project:hover .content .category a::after {
	content: ',';
}

.project:hover .content .category a:last-child {
	margin-right: 0;
}

.project:hover .content .category a:last-child::after {
	display: none;
}

.project:hover .content .category a:hover {
	color: #ffc246;
}

/*-- Project Information --*/

.project-information {
	background-color: #222;
	padding: 30px 35px;
}

.project-information h3 {
	color: #ffffff;
	margin-bottom: 20px;
}

.project-information ul li {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	color: #ffffff;
	font-size: 14px;
}

.project-information ul li:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border: none;
}

.project-information ul li strong {
	width: 80px;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

/*=====  End of project grid post  ======*/

/*=============================================
=            service grid            =
=============================================*/

.service-grid-wrapper {
	margin-bottom: -30px;
}

/*-- Service --*/

.service .service-inner {
	text-align: center;
	padding: 70px 40px;
	border: 1px solid #eeeeee;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.service .service-inner:hover {
	border-color: transparent;
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.service .service-inner .icon {
	margin-bottom: 30px;
}

.service .service-inner .content h3 {
	font-size: 24px;
	margin-bottom: 25px;
	font-weight: 600;
}

.service .service-inner .content .read-more {
	color: #222;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	text-transform: capitalize;
	font-weight: 600;
	position: relative;
	z-index: 1;
	line-height: 20px;
}

.service .service-inner .content .read-more::before {
	content: "";
	height: 6px;
	width: 0;
	position: absolute;
	left: 0;
	bottom: 2px;
	background-color: #ffc246;
	z-index: -1;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.service .service-inner .content .read-more:hover::before {
	width: 100%;
}

.service.service-two .service-inner {
	padding: 0;
	border: 1px solid #eeeeee;
}

.service.service-two .service-inner:hover {
	border-color: transparent;
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.service.service-two .service-inner .icon {
	max-width: 80px;
	height: 80px;
	margin: auto;
	margin-top: -40px;
	margin-bottom: 25px;
	background-color: #ffffff;
	padding: 10px;
	position: relative;
	border-radius: 50%;
	z-index: 9;
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	text-align: center;
}

.service.service-two .service-inner .icon img {
	max-width: 100%;
	margin: auto;
}

.service.service-two .service-inner .icon i {
	font-size: 35px;
	line-height: 60px;
	color: #ffc246;
}

.service.service-two .service-inner .content {
	padding: 0 30px 30px;
}

.service.service-two .service-inner .content h3 {
	font-size: 24px;
	margin-bottom: 25px;
	font-weight: 600;
}

.service.service-two .service-inner .content h3 a:hover {
	color: #ffc246;
}

.service.service-two .service-inner .content .read-more {
	color: #222;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	text-transform: capitalize;
	font-weight: 600;
	position: relative;
	z-index: 1;
	line-height: 20px;
}

.service.service-two .service-inner .content .read-more::before {
	content: "";
	height: 6px;
	width: 0;
	position: absolute;
	left: 0;
	bottom: 2px;
	background-color: #ffc246;
	z-index: -1;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.service.service-two .service-inner .content .read-more:hover::before {
	width: 100%;
}

/*=====  End of service grid  ======*/

/*=============================================
=            social element            =
=============================================*/

.twitter-feed-slider__area {
	background-color: #38a1f2;
	background-repeat: no-repeat;
	background-position: center center;
	width: 100%;
	height: 100%;
	min-height: 400px;
}

.twitter-feed-slider__area--style2 {
	height: 370px;
}

.twitter-feed-slider__container-area {
	position: relative;
	height: 100%;
}

.twitter-feed-slider__container-area .ht-swiper-button-nav {
	position: absolute;
	display: inline-block;
	bottom: 20px;
	top: auto;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border: 0;
	color: #ffffff;
}

.twitter-feed-slider__container-area .ht-swiper-button-nav:hover {
	background: none;
}

.twitter-feed-slider__container-area .ht-swiper-button-prev {
	left: 40px;
}

.twitter-feed-slider__container-area .ht-swiper-button-next {
	left: 80px;
}

.twitter-feed-slider__container-area .ht-swiper-button-next:before {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -10px;
	content: '';
	display: inline-block;
	width: 1px;
	height: 20px;
	background-color: rgba(255, 255, 255, 0.6);
}

.twitter-feed-slider__container-area .twitter-icon {
	position: absolute;
	bottom: 40px;
	right: 30px;
	font-size: 40px;
	line-height: 1;
	color: #ffffff;
}

.twitter-feed-slider__container-area--style2 .ht-swiper-button-prev {
	left: 40px;
}

.twitter-feed-slider__container-area--style2 .ht-swiper-button-next {
	left: 80px;
}

.twitter-feed-slider__container {
	height: 100%;
}

.twitter-feed-slider__single {
	position: relative;
}

.twitter-feed-slider__single .tweet-date {
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.7);
	position: absolute;
	left: 40px;
	top: 40px;
}

.twitter-feed-slider__single .tweet-text {
	position: absolute;
	left: 0;
	padding: 0 40px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 1.375rem;
	line-height: 1.8;
	color: #ffffff;
	font-style: italic;
	font-weight: 700;
}

.twitter-feed-slider__single .tweet-text a {
	color: #ffffff;
}

.twitter-feed-slider__single--style2 .tweet-date {
	left: 40px;
	top: 40px;
}

.twitter-feed-slider__single--style2 .tweet-text {
	padding: 0 40px;
}

.twitter-block {
	padding: 30px;
	background-color: #ffffff;
	border: 1px solid #eee;
	min-height: 290px;
	-webkit-box-ordinal-group: 6;
	-webkit-order: 5;
	-ms-flex-order: 5;
	order: 5;
	margin-left: -1px;
}

.twitter-block__logo {
	color: #ffffff;
	background-color: #38a1f2;
	position: relative;
}

.twitter-block__logo:before {
	display: block;
	width: 144px;
	height: 144px;
	text-align: center;
	line-height: 144px;
	position: absolute;
	top: calc(50% - 26px);
	left: 50%;
	content: '\f243';
	font-family: 'Ionicons';
	font-size: 3.75rem;
	color: #ffffff;
	background-image: url("../img/patterns/4.png");
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.twitter-block__logo span {
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	color: #ffffff;
	display: block;
	width: 100%;
	text-align: center;
	padding: 40px;
	position: absolute;
	left: 0;
	bottom: 0;
}

.twitter-block__logo span a {
	color: #ffffff;
	text-transform: capitalize;
	padding-bottom: 5px;
	border-bottom: 1px solid transparent;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.twitter-block__logo span a:hover {
	border-bottom-color: #ffffff;
}

.twitter-block__status .tweet-date {
	color: #999;
	margin-bottom: 30px;
}

.twitter-block__status .tweet-text {
	font-size: 0.9375rem;
	line-height: 1.3;
	color: #222;
}

.twitter-block__status .tweet-text a {
	font-weight: 700;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.twitter-block__status .tweet-text a:hover {
	color: #ffc246;
}

/*=====  End of social element  ======*/

/*=============================================
=            contact form            =
=============================================*/

.contact-icon-info-wrapper {
	margin-bottom: 30px;
}

.contact-icon-info-wrapper--style2 {
	margin-bottom: 0;
}

.single-contact-icon-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 30px;
}

.single-contact-icon-info__image {
	margin-right: 15px;
	color: #ffc246;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.single-contact-icon-info__image i {
	font-size: 1.75rem;
	line-height: 1;
}

.single-contact-icon-info__content .title {
	font-size: 0.875rem;
	font-weight: 700;
	margin-bottom: 10px;
}

.single-contact-icon-info--style2 {
	margin-bottom: 0;
	padding: 43px 38px;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ddd;
}

.single-contact-icon-info--style2 .single-contact-icon-info__image {
	margin-right: 30px;
}

.single-contact-icon-info--style2 .single-contact-icon-info__image i {
	font-size: 50px;
}

.single-contact-icon-info--style2:last-child {
	border-bottom: 0;
}

.contact-form-wrapper {
	margin-bottom: -15px; /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */ /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */
}

.contact-form-wrapper input {
	width: 100%;
	outline: none;
	border: 0;
	border-bottom: 2px solid #ddd;
	color: #222;
	font-weight: 400;
	padding: 12px 0 12px;
	height: 50px;
	margin-bottom: 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.contact-form-wrapper input:focus {
	border-color: #ffc246;
}

.contact-form-wrapper input:focus::-webkit-input-placeholder {
	color: transparent;
}

.contact-form-wrapper input:focus:-moz-placeholder {
	color: transparent;
}

.contact-form-wrapper input:focus::-moz-placeholder {
	color: transparent;
}

.contact-form-wrapper input:focus:-ms-input-placeholder {
	color: transparent;
}

.contact-form-wrapper textarea {
	width: 100%;
	outline: none;
	border: 0;
	border-bottom: 2px solid #ddd;
	font-weight: 400;
	color: #222;
	display: block;
	padding: 12px 0 3px;
	max-width: 100%;
	margin-bottom: 30px;
	height: 150px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.contact-form-wrapper textarea:focus {
	border-color: #ffc246;
}

.contact-form-wrapper textarea:focus::-webkit-input-placeholder {
	color: transparent;
}

.contact-form-wrapper textarea:focus::-moz-placeholder {
	color: transparent;
}

.contact-form-wrapper textarea:focus:-ms-input-placeholder {
	color: transparent;
}

.contact-form-wrapper ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #888;
	opacity: 1; /* Firefox */
}

.contact-form-wrapper ::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #888;
	opacity: 1; /* Firefox */
}

.contact-form-wrapper :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #888;
	opacity: 1; /* Firefox */
}

.contact-form-wrapper ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #888;
	opacity: 1; /* Firefox */
}

.contact-form-wrapper ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #888;
	opacity: 1; /* Firefox */
}

.contact-form-wrapper :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #888;
}

.contact-form-wrapper ::-ms-input-placeholder { /* Microsoft Edge */
	color: #888;
}

.contact-form-wrapper .form-message {
	margin-top: 15px;
}

.contact-form-wrapper--style2 {
	border: 1px solid #eee;
	background-color: #f7f7f7;
	padding: 40px 30px 50px;
}

.contact-form-wrapper--style2 input,
.contact-form-wrapper--style2 textarea {
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 0 15px;
}

.contact-form-wrapper--style2 input:focus,
.contact-form-wrapper--style2 textarea:focus {
	border-color: #ddd;
}

.contact-form-wrapper--style2 textarea {
	padding: 10px 15px;
}

/* contact location grid */

.contact-location-grid__wrapper {
	margin-bottom: -30px;
}

.contact-location-grid__single {
	margin-bottom: 30px;
}

.contact-location-grid__image {
	margin-bottom: 30px;
}

.contact-location-grid__image img {
	width: 100%;
}

.contact-location-grid__content .title {
	margin-top: -5px;
	padding-bottom: 25px;
	border-bottom: 1px solid #ddd;
	margin-bottom: 20px;
}

.contact-location-grid__content .info-list {
	margin-bottom: 35px;
}

.contact-location-grid__content .info-list li {
	padding: 5px 0;
	padding-left: 40px;
	position: relative;
}

.contact-location-grid__content .info-list li:last-child {
	margin-bottom: 0;
}

.contact-location-grid__content .info-list li:before {
	font-size: 18px;
	font-family: Ionicons;
	display: block;
	position: absolute;
	top: 5px;
	left: 0;
	color: #ffc246;
}

.contact-location-grid__content .info-list li.address:before {
	content: '\f203';
}

.contact-location-grid__content .info-list li.email:before {
	content: '\f132';
}

.contact-location-grid__content .info-list li.phone:before {
	content: '\f2d2';
}

/* contact social link list */

.contact-social-link-list {
	margin-bottom: -10px;
	display: block;
	overflow: hidden;
}

.contact-social-link-list li {
	display: block;
	float: left;
	width: 50%;
	padding-right: 20px;
}

.contact-social-link-list li a {
	display: inline-block;
	padding: 10px 0;
	color: #888;
}

.contact-social-link-list li a .link-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 48px;
	text-align: center;
	border: 1px solid #ddd;
	border-radius: 50%;
	font-size: 20px;
	margin-right: 20px;
	color: #222;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.contact-social-link-list li a .link-text {
	font-size: 18px;
}

.contact-social-link-list li a:hover .link-icon {
	background-color: #ffc246;
	color: #ffffff;
	border-color: #ffc246;
}

/*=====  End of contact form  ======*/

/*=============================================
=            industry icon            =
=============================================*/

.industry-icon-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -25px;
	margin-bottom: -40px;
}

.single-industry-icon-wrapper {
	-webkit-flex-basis: 25%;
	-ms-flex-preferred-size: 25%;
	flex-basis: 25%;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
}

.single-industry-icon-wrapper:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	height: 95px;
	width: 1px;
	background: #eee;
}

.single-industry-icon-wrapper:first-child:before {
	display: none;
}

.single-industry-icon {
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
	padding-top: 110px;
}

.single-industry-icon__image {
	margin-bottom: 30px;
	width: 110px;
	height: 110px;
	border: 5px solid transparent;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.single-industry-icon__image img {
	display: block;
}

.single-industry-icon__content {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-industry-icon__content .title {
	font-size: 1.5rem;
}

.single-industry-icon__content .title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-industry-icon__content .title a:hover {
	color: #ffc246;
}

.single-industry-icon__content .text {
	margin-top: 15px;
}

.single-industry-icon__content .see-more-link {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-industry-icon:hover .single-industry-icon__image {
	-webkit-transform: translate(-50%, -20px);
	-ms-transform: translate(-50%, -20px);
	transform: translate(-50%, -20px);
	border-color: #ffc246;
}

.single-industry-icon:hover .single-industry-icon__content {
	-webkit-transform: translate(0, -10px);
	-ms-transform: translate(0, -10px);
	transform: translate(0, -10px);
}

.single-industry-icon:hover .single-industry-icon__content .see-more-link {
	visibility: visible;
	opacity: 1;
}

.single-industry-icon--style2 {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	padding-left: 0;
	padding-right: 0;
}

.single-industry-icon--style2 .single-industry-icon__content .text-wrapper {
	padding: 0 30px 40px;
}

.single-industry-icon--style2 .single-industry-icon__content .title {
	color: #05103B;
}

.single-industry-icon--style2 .single-industry-icon__content .see-more-link {
	visibility: visible;
	opacity: 1;
	width: 100%;
	background-color: #ddd;
	padding: 20px 17px;
	margin-top: 0;
	text-align: center;
	color: #222;
	border-bottom: 0;
}

.single-industry-icon--style2 .single-industry-icon__content .see-more-link:after {
	display: none;
}

.single-industry-icon--style2 .single-industry-icon__content .see-more-link:hover {
	background-color: #ffc246;
}

.single-industry-icon--style2:hover {
	-webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.09);
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.09);
}

.single-industry-icon--style2:hover .single-industry-icon__content {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.industry-icon__container {
	padding-top: 30px;
	margin-top: -30px;
}

.industry-icon__single-slide {
	text-align: center;
}

.swiper-static-nav-wrap {
	margin-right: -15px;
}

.swiper-static-nav-wrap .ht-swiper-button-nav {
	position: static;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	display: inline-block;
	bottom: 0;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border: 0;
	color: #05103B;
}

.swiper-static-nav-wrap .ht-swiper-button-nav:hover {
	background: none;
}

.swiper-static-nav-wrap .ht-swiper-button-nav i {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-static-nav-wrap .ht-swiper-button-nav:hover i {
	color: #ffc246;
}

.swiper-static-nav-wrap .ht-swiper-button-next {
	position: relative;
}

.swiper-static-nav-wrap .ht-swiper-button-next:before {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -10px;
	content: '';
	display: inline-block;
	width: 1px;
	height: 20px;
	background-color: #DDDDDD;
}

/*=====  End of industry icon  ======*/

/*=============================================
=            project filter            =
=============================================*/

.project-filter-content {
	margin-bottom: -30px;
}

.project-filter-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border: 2px solid #ffc246;
	margin-top: -30px;
}

.project-filter-menu li {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
	position: relative;
	padding: 20px 15px;
	z-index: 1;
	color: #222;
	background-color: #ffffff;
	cursor: pointer;
	border-right: 2px solid #ffc246;
	font-weight: 700;
	font-size: 0.8125rem;
	letter-spacing: .5px;
}

.project-filter-menu li:after {
	content: '';
	position: absolute;
	top: -1px;
	bottom: -1px;
	z-index: -1;
	width: 0;
	left: auto;
	right: 0;
	-webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	-o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	background-color: #ffc246;
}

.project-filter-menu li:last-child {
	border-right: 0;
}

.project-filter-menu li:hover:after,
.project-filter-menu li.active:after {
	width: 100%;
	left: 0;
	right: auto;
}

.project-filter-menu li:hover .filter-counter,
.project-filter-menu li.active .filter-counter {
	background-color: #ffffff;
	color: #000000;
}

.project-filter-menu li .filter-counter {
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	line-height: 20px;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	color: #ffffff;
	margin-left: 10px;
	background-color: #ffc246;
}

.single-case-study-project {
	background: #ffffff;
	-webkit-box-shadow: 0 30px 50px rgba(0, 0, 0, 0.06);
	box-shadow: 0 30px 50px rgba(0, 0, 0, 0.06);
	margin-bottom: 30px;
	-webkit-transform: translateY(100px);
	-ms-transform: translateY(100px);
	transform: translateY(100px);
	-webkit-animation: moveVertical .65s ease forwards;
	animation: moveVertical .65s ease forwards;
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-case-study-project__image {
	position: relative;
}

.single-case-study-project__image img {
	width: 100%;
}

.single-case-study-project__image:before {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: inline-block;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	z-index: 2;
}

.single-case-study-project__content {
	padding: 30px;
}

.single-case-study-project__content .title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-case-study-project__content .title a:hover {
	color: #ffc246;
}

.single-case-study-project__content .category {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-case-study-project__content .category:hover {
	color: #ffc246;
}

.single-case-study-project:hover .single-case-study-project__image:before {
	background-color: rgba(0, 0, 0, 0.3);
}

.single-case-study-project--style2 {
	position: relative;
}

.single-case-study-project--style2 > a {
	display: block;
}

.single-case-study-project--style2 .single-case-study-project__image {
	z-index: 1;
	overflow: hidden;
}

.single-case-study-project--style2 .single-case-study-project__image img {
	-webkit-transition: all .8s;
	-o-transition: all .8s;
	transition: all .8s;
}

.single-case-study-project--style2 .single-case-study-project__image:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.7);
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(76%, #000));
	background: -webkit-linear-gradient(top, transparent 0%, #000 76%);
	background: -o-linear-gradient(top, transparent 0%, #000 76%);
	background: linear-gradient(-180deg, transparent 0%, #000 76%);
	content: "";
	z-index: 1;
}

.single-case-study-project--style2 .single-case-study-project__content {
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 2;
	padding: 30px 35px 40px;
}

.single-case-study-project--style2 .single-case-study-project__content .category {
	color: #ffffff;
	font-size: 0.8125rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.single-case-study-project--style2 .single-case-study-project__content .category:hover {
	color: #ffc246;
}

.single-case-study-project--style2 .single-case-study-project__content .title {
	color: #ffffff;
}

.single-case-study-project--style2:hover .single-case-study-project__image img {
	-webkit-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}

.single-case-study-project--style2:hover .single-case-study-project__image:before {
	display: none;
}

.single-case-study-project--style2:hover .case-readmore {
	background-color: #ffc246;
}

.single-case-study-project--style2 .case-readmore {
	position: absolute;
	top: 30px;
	right: 30px;
	height: 60px;
	width: 60px;
	border: 2px solid #ffc246;
	border-radius: 50%;
	z-index: 3;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-case-study-project--style2 .case-readmore i {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 24px;
	color: #222;
}

/*=====  End of project filter  ======*/

/*=============================================
=            quote background            =
=============================================*/

.quote-background-area {
	background-size: cover;
	background-repeat: no-repeat;
}

.quote-background__content .quote {
	width: 650px;
	max-width: 100%;
	color: #ffffff;
	font-size: 2.75rem;
	margin-left: auto;
	margin-right: auto;
}

.quote-background__content .quote-author {
	font-size: 1.25rem;
	line-height: 1.2;
	font-weight: 700;
	color: #ffc246;
}

/*=====  End of quote background  ======*/

/*=============================================
=            industry flip            =
=============================================*/

.industry-flip-wrapper {
	margin-bottom: -30px;
}

.single-industry-flip-wrap {
	margin-bottom: 30px;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.single-industry-flip-wrap:hover .single-industry-flip-inner .single-industry-flip-front {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
}

.single-industry-flip-wrap:hover .single-industry-flip-inner .single-industry-flip-back {
	-webkit-transform: rotateX(360deg);
	transform: rotateX(360deg);
}

.single-industry-flip-inner {
	position: relative;
	height: 385px;
	-webkit-transition: -webkit-transform 0.8s;
	transition: -webkit-transform 0.8s;
	-o-transition: transform 0.8s;
	transition: transform 0.8s;
	transition: transform 0.8s, -webkit-transform 0.8s;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.single-industry-flip-front {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-transition: -webkit-transform 0.8s;
	transition: -webkit-transform 0.8s;
	-o-transition: transform 0.8s;
	transition: transform 0.8s;
	transition: transform 0.8s, -webkit-transform 0.8s;
	background-color: #05103B;
	background-repeat: no-repeat;
	background-size: cover;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.single-industry-flip-front__content {
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	width: 100%;
	padding: 50px 30px;
}

.single-industry-flip-front__content .title {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 70px;
	margin-bottom: 0;
	padding: 10px 70px 10px 18px;
	color: #222;
	background-color: #FFF;
	font-size: 1.25rem;
}

.single-industry-flip-front__content .title:after {
	font-family: Ionicons;
	font-size: 16px;
	font-weight: 500;
	line-height: 50px;
	position: absolute;
	top: 50%;
	right: 10px;
	display: inline-block;
	width: 50px;
	height: 50px;
	content: "\f125";
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	text-align: center;
	color: #ffffff;
	background-color: #ffc246;
}

.single-industry-flip-back {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 50px 30px;
	-webkit-transition: -webkit-transform 0.8s;
	transition: -webkit-transform 0.8s;
	-o-transition: transform 0.8s;
	transition: transform 0.8s;
	transition: transform 0.8s, -webkit-transform 0.8s;
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
	background-repeat: no-repeat;
	background-size: cover;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.single-industry-flip-back:before {
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: "";
	opacity: 0.74;
	background-color: #05103B;
}

.single-industry-flip-back__content {
	z-index: 2;
	width: 100%;
	margin-top: 30px;
	color: #FFF;
}

.single-industry-flip-back__content .career-btn {
	margin-top: 80px;
}

/*=====  End of industry flip  ======*/

/*=============================================
=            industry tab            =
=============================================*/

.industry-tab__link-wrapper.nav-tabs a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 500;
	color: #05103B;
}

.industry-tab__link-wrapper.nav-tabs a.nav-link {
	padding: 15px 30px;
	border: none;
	border-bottom: 1px solid #ddd;
	position: relative;
}

.industry-tab__link-wrapper.nav-tabs a.nav-link:after {
	left: calc(100% - 6px);
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 194, 70, 0);
	border-left-color: #ffc246;
	border-width: 6px;
	margin-top: -6px;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-transition-duration: 0.2s;
	-o-transition-duration: 0.2s;
	transition-duration: 0.2s;
}

.industry-tab__link-wrapper.nav-tabs a:hover,
.industry-tab__link-wrapper.nav-tabs a.active {
	background-color: #ffc246;
	color: #ffffff;
	border-radius: 0;
}

.industry-tab__link-wrapper.nav-tabs a:hover:after,
.industry-tab__link-wrapper.nav-tabs a.active:after {
	visibility: visible;
	opacity: 1;
	left: 100%;
}

.industry-tab__content-wrapper {
	padding-left: 60px;
	overflow: hidden;
}

.industry-tab__single-content-wrapper {
	margin-bottom: -30px;
}

.industry-tab__single-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 30px;
}

.industry-tab__single-content .icon {
	-webkit-flex-basis: 70px;
	-ms-flex-preferred-size: 70px;
	flex-basis: 70px;
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	margin-right: 30px;
	font-size: 30px;
	color: #ffc246;
	border: 1px solid #ddd;
	background-color: #ffffff;
}

.industry-tab__single-content .content {
	-webkit-flex-basis: calc(100% - 100px);
	-ms-flex-preferred-size: calc(100% - 100px);
	flex-basis: calc(100% - 100px);
}

.industry-tab__single-content .content .title {
	font-size: 1.25rem;
	line-height: 1.2;
	font-weight: 500;
	color: #05103B;
	margin-bottom: 15px;
}

/*=====  End of industry tab  ======*/

/*=============================================
=            breadcrumb            =
=============================================*/

.breadcrumb-area-bg {
	background-size: cover;
	background-position: center center;
	position: relative;
	z-index: 1;
}

.breadcrumb-area-bg:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 0.3;
	z-index: -1;
	content: "";
}

.breadcrumb-page-title {
	font-size: 2.5rem;
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 0;
}

.breadcrumb-page-title--style2 {
	margin-bottom: 100px;
}

.breadcrumb-page-list {
	text-align: right;
}

.breadcrumb-page-list li {
	display: inline-block;
	color: #ffffff;
	font-size: 0.8125rem;
	line-height: 1.2;
	font-weight: 700;
}

.breadcrumb-page-list li.has-children {
	position: relative;
	padding-right: 25px;
}

.breadcrumb-page-list li.has-children a {
	color: #ffffff;
	position: relative;
	letter-spacing: 0.5px;
	padding: 2px 0;
}

.breadcrumb-page-list li.has-children a:after {
	-webkit-transition: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	-o-transition: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	transition: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	content: '';
	width: 0;
	height: 1px;
	bottom: 0;
	position: absolute;
	left: auto;
	right: 0;
	z-index: 1;
	background-color: transparent;
}

.breadcrumb-page-list li.has-children:before {
	font-family: Ionicons;
	content: '\f125';
	font-size: 8px;
	font-weight: 500;
	position: absolute;
	right: 10px;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.breadcrumb-page-list li.has-children:hover a:after {
	background-color: #ffffff;
	width: 100%;
	left: 0;
	right: auto;
}

.breadcrumb-page-list--style2 {
	text-align: left;
}

/*=====  End of breadcrumb  ======*/

/*=============================================
=            case study content table            =
=============================================*/

.case-study__subcontent-wrapper {
	margin-left: 30px;
}

.case-study__page-content-title {
	font-size: 1.875rem;
	line-height: 1.5;
	margin-bottom: 30px;
}

.case-study__subcontent-single {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.case-study__marker {
	-webkit-flex-basis: 40px;
	-ms-flex-preferred-size: 40px;
	flex-basis: 40px;
	font-size: 1.25rem;
	color: #ffc246;
}

.case-study__subcontent-content {
	-webkit-flex-basis: calc(100% - 40px);
	-ms-flex-preferred-size: calc(100% - 40px);
	flex-basis: calc(100% - 40px);
}

.case-study__subtitle {
	font-size: 1.125rem;
	margin-bottom: 15px;
}

.case-study__single-step .count {
	font-size: 2.5rem;
	font-weight: 700;
	color: #ddd;
	margin-bottom: 30px;
	line-height: 1.22;
}

.case-study__single-step .count-wrap {
	position: relative;
	height: 28px;
	margin-bottom: 27px;
}

.case-study__single-step .count-wrap .dot {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	background-color: rgba(254, 188, 53, 0.18);
}

.case-study__single-step .count-wrap .dot:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: rgba(254, 188, 53, 0.29);
}

.case-study__single-step .count-wrap .dot:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ffc246;
}

.case-study__single-step .count-wrap .line {
	position: absolute;
	top: 14px;
	left: 0;
	background: #eee;
	width: 150%;
	height: 1px;
	margin-top: -1px;
	z-index: -1;
}

.case-study__step-wrapper {
	overflow: hidden;
}

.case-study__single-gallery-image {
	margin-bottom: 30px;
}

.case-study__single-gallery-image a {
	display: block;
	position: relative;
}

.case-study__single-gallery-image a img {
	width: 100%;
}

.case-study__single-gallery-image a:before {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: #ffc246;
	opacity: 0;
	visibility: hidden;
	top: 0;
	left: 0;
}

.case-study__single-gallery-image a:after {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: #ffffff;
	content: "\f489";
	font-family: Ionicons;
	font-size: 3.125rem;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.case-study__single-gallery-image a:hover:before {
	opacity: 0.9;
	visibility: visible;
}

.case-study__single-gallery-image a:hover:after {
	opacity: 1;
	visibility: visible;
}

.case-study__image-gallery-wrapper {
	margin-bottom: -30px;
}

.case-study-table__row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	border: 1px solid #ddd;
	border-bottom: 0;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.case-study-table__row:last-child {
	border-bottom: 1px solid #ddd;
}

.case-study-table__row:nth-child(2n) {
	background-color: #f7f7f7;
}

.case-study-table__icon {
	-webkit-flex-basis: 60px;
	-ms-flex-preferred-size: 60px;
	flex-basis: 60px;
	font-size: 1.25rem;
	text-align: center;
	color: #ffc246;
}

.case-study-table__details {
	padding: 12px 20px;
	-webkit-flex-basis: calc(100% - 60px);
	-ms-flex-preferred-size: calc(100% - 60px);
	flex-basis: calc(100% - 60px);
	border-left: 1px solid #ddd;
}

.case-study-table__details .highlight {
	color: #222;
}

/*=====  End of case study content table  ======*/

/*=============================================
=            image gallery slider            =
=============================================*/

.image-gallery-slider-big__container-area {
	position: relative;
}

.image-gallery-slider-big__container-area .ht-swiper-button-nav {
	background-color: #ffffff;
	border-color: #ffffff;
}

.image-gallery-slider-big__container-area .ht-swiper-button-nav:hover {
	background-color: #ffc246;
	border-color: #ffc246;
	color: #ffffff;
}

.image-gallery-slider-big__container-area .ht-swiper-button-prev {
	left: 30px;
}

.image-gallery-slider-big__container-area .ht-swiper-button-next {
	right: 30px;
}

.image-gallery-slider-small__single-slide {
	opacity: 0.5;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	cursor: pointer;
}

.image-gallery-slider-small__single-slide:hover {
	opacity: 1;
}

.image-gallery-slider-small__wrapper .swiper-slide-active .image-gallery-slider-small__single-slide {
	opacity: 1;
}

/*=====  End of image gallery slider  ======*/

/*=============================================
=            blog post            =
=============================================*/

/* blog post list type */

.single-list-blog-post-wrapper {
	margin-bottom: -90px;
}

.single-list-blog-post {
	margin-bottom: 90px;
}

.single-list-blog-post__image a {
	display: block;
	width: 100%;
}

.single-list-blog-post__image a img {
	width: 100%;
}

.single-list-blog-post__media .video iframe {
	width: 100%;
	height: 500px;
}

.single-list-blog-post__media .post-audio iframe {
	width: 100%;
	height: 165px;
}

.single-list-blog-post__media .blog-post-image-gallery {
	position: relative;
}

.single-list-blog-post__media .blog-post-image-gallery .swiper-pagination {
	position: absolute;
	bottom: 30px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.single-list-blog-post__media .blog-post-image-gallery .swiper-pagination-bullet {
	background-color: #d8d8d8;
	opacity: 1;
}

.single-list-blog-post__media .blog-post-image-gallery .swiper-pagination-bullet:hover,
.single-list-blog-post__media .blog-post-image-gallery .swiper-pagination-bullet-active {
	background-color: #ffc246;
}

.single-list-blog-post__media .blog-post-link {
	padding: 80px 100px;
	background-color: rgba(254, 188, 53, 0.18);
}

.single-list-blog-post__media .blog-post-link a {
	font-size: 1.5rem;
	line-height: 1.5;
	color: #222;
	word-wrap: break-word;
	display: block;
}

.single-list-blog-post__media .blog-post-big-quote {
	position: relative;
	padding: 63px 100px 67px;
	background: url("../img/blog/blog-quote-icon.png") no-repeat 65px 35px;
	border: 2px solid #ffc246;
	background-color: rgba(254, 188, 53, 0.18);
}

.single-list-blog-post__media .blog-post-big-quote .blog-quote-text {
	font-size: 1.5rem;
	line-height: 1.5;
	font-weight: 500;
	color: #222;
	font-style: italic;
	margin-bottom: 30px;
}

.single-list-blog-post__media .blog-post-big-quote .blog-quote-author a {
	color: #222;
	text-decoration: underline;
}

.single-list-blog-post__content .tag-list {
	line-height: 1;
	margin-bottom: 15px;
}

.single-list-blog-post__content .tag-list li {
	display: inline-block;
}

.single-list-blog-post__content .tag-list li a {
	display: block;
	padding: 4px 9px;
	margin: 5px 3px;
	border: 1px solid #ddd;
	color: #222;
	font-size: 13px;
	line-height: 1.5;
	font-weight: 500;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-list-blog-post__content .tag-list li a:hover {
	background-color: #ffc246;
	border-color: #ffc246;
}

.single-list-blog-post__content .title {
	line-height: 1.5;
	margin-bottom: 15px;
}

.single-list-blog-post__content .title a {
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-list-blog-post__content .title a:hover {
	color: #ffc246;
}

.single-list-blog-post__content .meta-list .single-meta {
	display: inline-block;
}

.single-list-blog-post__content .meta-list .single-meta p {
	font-size: 13px;
	line-height: 1.7;
	font-weight: 700;
	color: #b5b5b5;
	letter-spacing: 1px;
	display: inline-block;
}

.single-list-blog-post__content .meta-list .single-meta p a {
	color: #b5b5b5;
}

.single-list-blog-post__content .meta-list .single-meta p.author a {
	color: #222;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-list-blog-post__content .meta-list .single-meta p.author a:hover {
	color: #ffc246;
}

.single-list-blog-post__content .meta-list .single-meta:last-child {
	margin-right: 0;
}

.single-list-blog-post__content .meta-list .single-meta:first-child:before {
	display: none;
}

.single-list-blog-post__content .meta-list .single-meta:before {
	content: '';
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #ddd;
	margin: 0 11px 2px;
}

.single-list-blog-post__content .post-excerpt {
	margin-top: 25px;
	padding-top: 25px;
	border-top: 1px solid #ddd;
	margin-bottom: 30px;
}

.single-list-blog-post__content--style2 .title {
	font-size: 2.5rem;
	line-height: 1.2;
}

.single-list-blog-post__content--style2 .post-text {
	margin-top: 25px;
	padding-top: 25px;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	padding-bottom: 80px;
	margin-bottom: 30px;
}

/*=====  End of blog post  ======*/

/*=============================================
=            team member slider            =
=============================================*/

.team-slider {
	position: relative;
}

.team-slider .ht-swiper-button-prev {
	left: -100px;
}

.team-slider .ht-swiper-button-next {
	right: -100px;
}

.team-slider__single {
	padding-bottom: 100px;
	margin-bottom: 30px;
	background-color: #ffffff;
	position: relative;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.team-slider__single:hover {
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
}

.team-slider__single:hover .content {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.team-slider__single:hover .content .social-links {
	visibility: visible;
	opacity: 1;
}

.team-slider__single .image img {
	width: 100%;
}

.team-slider__single .content {
	padding: 25px 0;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	-webkit-transform: translateY(46px);
	-ms-transform: translateY(46px);
	transform: translateY(46px);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-color: #ffffff;
}

.team-slider__single .content .social-links {
	visibility: hidden;
	opacity: 0;
	margin-top: 20px;
	padding: 0 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.team-slider__single .content .identity-wrapper.has-border-left {
	border-left: 3px solid #ffc246;
	padding-left: 18px;
	padding-top: 2px;
}

.team-slider__single .content .identity-wrapper .name {
	font-size: 1.25rem;
	line-height: 1.2;
	margin-bottom: 0;
}

/*=====  End of team member slider  ======*/

/*=============================================
=            ecosystem slider            =
=============================================*/

.ecosystem-slider {
	position: relative;
}

.ecosystem-slider .ht-swiper-button-prev {
	left: -100px;
}

.ecosystem-slider .ht-swiper-button-next {
	right: -100px;
}

.ecosystem-slider__single {
	padding-bottom: 100px;
	margin-bottom: 30px;
	background-color: #ffffff;
	position: relative;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ecosystem-slider__single:hover {
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
}

.ecosystem-slider__single:hover .content {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.ecosystem-slider__single:hover .content .social-links {
	visibility: visible;
	opacity: 1;
}

.ecosystem-slider__single .image img {
	width: 100%;
}

.ecosystem-slider__single .content {
	padding: 25px 0;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	-webkit-transform: translateY(46px);
	-ms-transform: translateY(46px);
	transform: translateY(46px);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-color: #ffffff;
}

.ecosystem-slider__single .content .social-links {
	visibility: hidden;
	opacity: 0;
	margin-top: 20px;
	padding: 0 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ecosystem-slider__single .content .identity-wrapper.has-border-left {
	border-left: 3px solid #ffc246;
	padding-left: 18px;
	padding-top: 2px;
}

.ecosystem-slider__single .content .identity-wrapper .name {
	font-size: 1.25rem;
	line-height: 1.2;
	margin-bottom: 0;
}

/*=====  End of ecosystem slider  ======*/
/*=============================================
=            product slider            =
=============================================*/

.product-slider {
	position: relative;
}

.product-slider .ht-swiper-button-prev {
	left: -100px;
}

.product-slider .ht-swiper-button-next {
	right: -100px;
}

.product-slider__single {
	padding-bottom: 100px;
	margin-bottom: 30px;
	background-color: #ffffff;
	position: relative;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.product-slider__single:hover {
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
}

.product-slider__single:hover .content {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.product-slider__single:hover .content .social-links {
	visibility: visible;
	opacity: 1;
}

.product-slider__single .image img {
	width: 100%;
}

.product-slider__single .content {
	padding: 25px 0;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	-webkit-transform: translateY(46px);
	-ms-transform: translateY(46px);
	transform: translateY(46px);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-color: #ffffff;
}

.product-slider__single .content .social-links {
	visibility: hidden;
	opacity: 0;
	margin-top: 20px;
	padding: 0 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.product-slider__single .content .identity-wrapper.has-border-left {
	border-left: 3px solid #ffc246;
	padding-left: 18px;
	padding-top: 2px;
}

.product-slider__single .content .identity-wrapper .name {
	font-size: 1.25rem;
	line-height: 1.2;
	margin-bottom: 0;
}

/*=====  End of product slider  ======*/
/*=============================================
=            Portfolio slider            =
=============================================*/

.Portfolio-slider {
	position: relative;
}

.Portfolio-slider .ht-swiper-button-prev {
	left: -100px;
}

.Portfolio-slider .ht-swiper-button-next {
	right: -100px;
}

.Portfolio-slider__single {
	padding-bottom: 100px;
	margin-bottom: 30px;
	background-color: #ffffff;
	position: relative;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Portfolio-slider__single:hover {
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
}

.Portfolio-slider__single:hover .content {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.Portfolio-slider__single:hover .content .social-links {
	visibility: visible;
	opacity: 1;
}

.Portfolio-slider__single .image img {
	width: 100%;
}

.Portfolio-slider__single .content {
	padding: 25px 0;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	-webkit-transform: translateY(46px);
	-ms-transform: translateY(46px);
	transform: translateY(46px);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	background-color: #ffffff;
}

.Portfolio-slider__single .content .social-links {
	visibility: hidden;
	opacity: 0;
	margin-top: 20px;
	padding: 0 20px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Portfolio-slider__single .content .identity-wrapper.has-border-left {
	border-left: 3px solid #ffc246;
	padding-left: 18px;
	padding-top: 2px;
}

.Portfolio-slider__single .content .identity-wrapper .name {
	font-size: 1.25rem;
	line-height: 1.2;
	margin-bottom: 0;
}

/*=====  End of Portfolio slider  ======*/


/*=============================================
=            product slider            =
=============================================*/

/* related product slider */

.related-product-slider {
	position: relative;
}

.related-product-slider .ht-swiper-button-prev {
	left: -100px;
}

.related-product-slider .ht-swiper-button-next {
	right: -100px;
}

/*=====  End of product slider  ======*/

/*=============================================
=            blog            =
=============================================*/

.blog-metro-layout-wrapper {
	margin-bottom: -30px;
}

.common-blog-carousel {
	position: relative;
}

.common-blog-carousel__container--space-top {
	padding-top: 27px;
}

.common-blog-carousel .ht-swiper-button-prev {
	left: -100px;
}

.common-blog-carousel .ht-swiper-button-next {
	right: -100px;
}

/* blog post page */

.quote-text {
	border: 2px solid #ffc246;
	padding: 26px 30px 26px 75px;
	font-size: 1.125rem;
	line-height: 1.5;
	font-style: italic;
	color: #222;
	position: relative;
	margin: 30px 0;
}

.quote-text:before {
	position: absolute;
	top: 50%;
	left: 25px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	content: '\f347';
	font-family: Ionicons;
	font-size: 1.5rem;
	font-style: normal;
	color: #ffc246;
}

/* blog post feature image with text */

.image-list-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 30px;
}

.image-list-text .image-wrapper {
	-webkit-flex-basis: 370px;
	-ms-flex-preferred-size: 370px;
	flex-basis: 370px;
	margin-right: 30px;
}

.image-list-text .image-wrapper .image img {
	width: 100%;
}

.image-list-text .image-wrapper .image-text {
	background-color: #222;
	color: #ffffff;
	padding: 12px 25px;
	font-style: italic;
}

.image-list-text .text-wrapper {
	-webkit-flex-basis: calc(100% - 400px);
	-ms-flex-preferred-size: calc(100% - 400px);
	flex-basis: calc(100% - 400px);
}

.image-list-text .text-wrapper .text-feature-list {
	margin-top: 30px;
}

.image-list-text .text-wrapper .text-feature-list li {
	position: relative;
	padding-left: 30px;
}

.image-list-text .text-wrapper .text-feature-list li:before {
	position: absolute;
	top: 6px;
	left: 0;
	display: block;
	font-family: Ionicons;
	content: '\f400';
	font-size: 8px;
	color: #b6b6b6;
}

/* tag list */

.tag-list-wrapper .tag-title {
	font-size: 13px;
	line-height: 1.5;
	color: #222;
	font-weight: 700;
	margin-right: 20px;
	letter-spacing: 0.54px;
}

.tag-list-wrapper .tag-title:before {
	content: '\f2aa';
	font-family: Ionicons;
	font-size: 18px;
	font-weight: 500;
	margin-right: 9px;
	color: #ffc246;
}

.tag-list-wrapper .tag-list {
	display: inline-block;
}

.tag-list-wrapper .tag-list li {
	display: inline-block;
}

.tag-list-wrapper .tag-list li a {
	display: block;
	padding: 4px 10px;
	border: 1px solid #ddd;
	margin: 5px;
	font-weight: 400;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.tag-list-wrapper .tag-list li a:hover {
	background-color: #ffc246;
	color: #222;
	border-color: #ffc246;
}

/* blog post author */

.blog-post-author {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 95px;
	padding: 50px 46px;
	border: 1px solid #eee;
}

.blog-post-author__image {
	-webkit-flex-basis: 100px;
	-ms-flex-preferred-size: 100px;
	flex-basis: 100px;
	width: 100px;
	height: 100px;
	margin-right: 30px;
}

.blog-post-author__image img {
	border-radius: 50%;
}

.blog-post-author__content {
	-webkit-flex-basis: calc(100% - 130px);
	-ms-flex-preferred-size: calc(100% - 130px);
	flex-basis: calc(100% - 130px);
}

.blog-post-author__content .author-name {
	font-size: 1.125rem;
}

/* blog post navigation */

.blog-post-nav {
	margin-top: 80px;
}

.blog-post-nav .post-nav-link {
	font-size: 13px;
	line-height: 1.5;
	font-weight: 700;
	color: #888;
	letter-spacing: 0.5px;
	margin-bottom: 15px;
}

.blog-post-nav .post-nav-title {
	font-size: 1.125rem;
	line-height: 1.5;
	font-weight: 500;
	color: #222;
	width: 300px;
	max-width: 100%;
}

.blog-post-nav__prev .post-nav-link i {
	margin-right: 10px;
	font-size: 10px;
	font-weight: 500;
}

.blog-post-nav__prev a {
	color: #222;
}

.blog-post-nav__prev a:hover {
	text-decoration: underline;
}

.blog-post-nav__prev a:hover .post-nav-link {
	color: #222;
}

.blog-post-nav__next .post-nav-link i {
	margin-left: 10px;
	font-size: 10px;
	font-weight: 500;
}

.blog-post-nav__next a {
	color: #222;
}

.blog-post-nav__next a:hover {
	text-decoration: underline;
}

.blog-post-nav__next a:hover .post-nav-link {
	color: #222;
}

/* blog post comment form */

.blog-post-comment {
	margin-top: 80px;
}

.blog-post-comment .comment-title .title {
	font-size: 1.875rem;
	line-height: 1.3;
	margin-bottom: 15px;
}

.blog-post-comment .comment-form-wrapper input {
	position: relative;
	padding-left: 35px;
	margin-bottom: 0;
}

.blog-post-comment .comment-form-wrapper .comment-icon {
	position: relative;
}

.blog-post-comment .comment-form-wrapper .comment-icon:before {
	position: absolute;
	top: 50%;
	left: 15px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-family: Ionicons;
	font-size: 30px;
	font-weight: 500;
	color: #888;
	z-index: 2;
}

.blog-post-comment .comment-form-wrapper .comment-name:before {
	content: '\f47d';
}

.blog-post-comment .comment-form-wrapper .comment-email:before {
	content: '\f422';
}

/*=====  End of blog  ======*/

/*=============================================
=            service details            =
=============================================*/

.service-details-image-block .image img {
	width: 100%;
}

.service-details-image-block .text {
	background-color: #222;
	color: #ffffff;
	padding: 12px 25px;
	font-style: italic;
}

.service-details-text-wrapper .title {
	font-size: 1.875rem;
	line-height: 1.3;
	margin-bottom: 20px;
	margin-top: -5px;
}

.service-details-text-wrapper .big-title {
	font-size: 2.5rem;
	line-height: 1.25;
	margin-bottom: 20px;
	margin-top: -5px;
}

/* split text */

.single-split-text .title {
	font-size: 1.125rem;
	line-height: 1.2;
	margin-bottom: 10px;
}

/* solution grid */

.solution-grid-wrapper {
	padding-top: 30px;
	position: relative;
}

.solution-grid-wrapper:before {
	content: '';
	width: 100%;
	height: 1px;
	position: absolute;
	top: 14px;
	left: 0;
	background-color: #eee;
}

.solution-grid-wrapper .count-wrap {
	position: relative;
	height: 28px;
	margin-bottom: 20px;
}

.solution-grid-wrapper .count-wrap .dot {
	background-color: rgba(254, 188, 53, 0.18);
	top: -14px;
	width: 28px;
	height: 28px;
	border-radius: 100%;
	position: absolute;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 2;
}

.solution-grid-wrapper .count-wrap .dot:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: rgba(254, 188, 53, 0.29);
}

.solution-grid-wrapper .count-wrap .dot:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ffc246;
}

.solution-grid-single {
	position: relative;
	-webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.09);
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.09);
	padding: 60px 25px;
	text-align: center;
}

.solution-grid-single:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	top: 0;
	left: calc(50% + 21px);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 15px solid;
	border-color: transparent transparent #fff #fff;
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: rotate(-225deg);
	-ms-transform: rotate(-225deg);
	transform: rotate(-225deg);
	-webkit-filter: drop-shadow(-3px 3px 3px rgba(0, 0, 0, 0.05));
	filter: drop-shadow(-3px 3px 3px rgba(0, 0, 0, 0.05));
}

.solution-grid-single__title {
	font-size: 1.25rem;
	line-height: 1.3;
	margin-bottom: 15px;
}

/* service details list */

.service-details-list li {
	color: #222;
	padding-left: 20px;
	position: relative;
	margin-bottom: 15px;
}

.service-details-list li:last-child {
	margin-bottom: 0;
}

.service-details-list li:before {
	position: absolute;
	top: 6px;
	left: 0;
	display: block;
	font-family: Ionicons;
	content: '\f400';
	font-size: 8px;
	color: #b6b6b6;
}

/* service details chart */

/* service details tab */

.service-details-tab__navigation-wrapper {
	border-bottom: 0;
}

.service-details-tab__navigation-wrapper .nav-link {
	padding: 14px 20px;
	font-size: 1.125rem;
	font-weight: 700;
	background-color: #f7f7f7;
	color: #888;
	border: 1px solid transparent;
	border-bottom-color: #ddd;
	margin-left: 10px;
	border-radius: 0;
	position: relative;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.service-details-tab__navigation-wrapper .nav-link:before {
	content: '';
	display: inline-block;
	width: 3px;
	height: 0;
	position: absolute;
	top: 0;
	left: -1px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.service-details-tab__navigation-wrapper .nav-link:hover,
.service-details-tab__navigation-wrapper .nav-link.active {
	border: 1px solid #ddd;
	border-bottom-color: transparent;
}

.service-details-tab__navigation-wrapper .nav-link:hover:before,
.service-details-tab__navigation-wrapper .nav-link.active:before {
	background-color: #ffc246;
	height: 100%;
}

.service-details-tab__navigation-wrapper .nav-link:first-child {
	margin-left: 0;
}

.service-details-tab__content-wrapper {
	border-radius: 0;
	background: #fff;
	border: 1px solid #ddd;
	padding: 42px 30px 67px;
}

.service-details-tab__list li {
	color: #222;
	margin-bottom: 15px;
	position: relative;
	padding-left: 30px;
}

.service-details-tab__list li:before {
	position: absolute;
	top: 0;
	color: #ffc246;
	left: 0;
	display: block;
	font-family: Ionicons;
	content: '\f122';
	font-size: 12px;
}

.service-details-tab__list li:last-child {
	margin-bottom: 0;
}

/* service details box icon */

.service-details-box-icon__wrapper {
	margin-bottom: -30px;
}

.service-details-box-icon__wrapper--style2 {
	margin-bottom: -80px;
}

.service-details-box-icon__single .icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
	font-size: 1.875rem;
	font-weight: 500;
	width: 70px;
	height: 70px;
	border: 2px solid #ffc246;
	color: #ffc246;
	margin-bottom: 30px;
}

.service-details-box-icon__single .content .heading {
	font-size: 1.25rem;
}

.service-details-box-icon__single:hover .icon {
	-webkit-animation: box-image-pop .3s linear;
	animation: box-image-pop .3s linear;
}

.service-details-box-icon__single--style2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.service-details-box-icon__single--style2 .icon {
	-webkit-flex-basis: 70px;
	-ms-flex-preferred-size: 70px;
	flex-basis: 70px;
	margin-right: 30px;
	margin-bottom: 0;
}

.service-details-box-icon__single--style2 .content {
	-webkit-flex-basis: calc(100% - 100px);
	-ms-flex-preferred-size: calc(100% - 100px);
	flex-basis: calc(100% - 100px);
}

/* service details image desc */

.service-details-image-desc__single {
	margin-bottom: 50px;
}

.service-details-image-desc__single .image img {
	width: 100%;
}

/* service details list */

.service-details-list-text li {
	padding-left: 30px;
	position: relative;
	margin-bottom: 15px;
}

.service-details-list-text li:last-child {
	margin-bottom: 0;
}

.service-details-list-text li:before {
	position: absolute;
	top: 6px;
	left: 0;
	display: block;
	font-family: Ionicons;
	content: '\f400';
	font-size: 8px;
	color: #b6b6b6;
}

/*=====  End of service details  ======*/

/*=============================================
=            common            =
=============================================*/

/* award page */

.award-item__single {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 1px solid #ddd;
}

.award-item__single:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: 0;
}

.award-item__single .image {
	-webkit-flex-basis: 230px;
	-ms-flex-preferred-size: 230px;
	flex-basis: 230px;
	margin-right: 40px;
}

.award-item__single .image img {
	width: 100%;
}

.award-item__single .content {
	-webkit-flex-basis: calc(100% - 270px);
	-ms-flex-preferred-size: calc(100% - 270px);
	flex-basis: calc(100% - 270px);
}

.award-item__single .content .title {
	font-size: 1.125rem;
	line-height: 1.2;
	margin-bottom: 15px;
}

/* leadership page */

.leadership__single {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 1px solid #ddd;
}

.leadership__single:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: 0;
}

.leadership__single .image {
	-webkit-flex-basis: 270px;
	-ms-flex-preferred-size: 270px;
	flex-basis: 270px;
	margin-right: 30px;
}

.leadership__single .image img {
	width: 100%;
}

.leadership__single .content {
	-webkit-flex-basis: calc(100% - 300px);
	-ms-flex-preferred-size: calc(100% - 300px);
	flex-basis: calc(100% - 300px);
}

.leadership__single .content .identity-wrapper {
	margin-bottom: 20px;
}

.leadership__single .content .identity-wrapper.has-border-left {
	border-left: 3px solid #ffc246;
	padding-left: 18px;
	padding-top: 2px;
}

.leadership__single .content .identity-wrapper .name {
	font-size: 1.25rem;
	line-height: 1.2;
	margin-bottom: 0;
}

/* history page */

.history-grid__wrapper {
	position: relative;
}

.history-grid__wrapper:before {
	position: absolute;
	left: 50%;
	margin-left: -1px;
	height: 100%;
	border-left: 2px dotted rgba(151, 151, 151, 0.88);
	content: "";
}

.history-grid__single-wrapper {
	padding-right: 55px;
	position: relative;
}

.history-grid__single-wrapper:first-child {
	margin-top: 0;
}

.history-grid__single-wrapper .dot {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	position: absolute;
	top: 0;
	right: -14px;
	background-color: rgba(254, 188, 53, 0.18);
}

.history-grid__single-wrapper .dot:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: rgba(254, 188, 53, 0.29);
}

.history-grid__single-wrapper .dot:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ffc246;
}

.history-grid__single-wrapper:nth-child(2n) {
	padding-left: 55px;
	padding-right: 15px;
}

.history-grid__single-wrapper:nth-child(2n) .dot {
	top: 0;
	left: -14px;
}

.history-grid__single-wrapper:nth-child(2n+2) {
	margin-top: 100px;
}

.history-grid__single {
	position: relative;
	background: #fff;
	-webkit-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.11);
	box-shadow: 0 5px 40px rgba(0, 0, 0, 0.11);
}

.history-grid__single .content-head {
	padding: 15px 20px;
	background-color: #ffc246;
	font-size: 1.125rem;
	font-weight: 700;
	color: #222;
}

.history-grid__single .content-body {
	padding: 20px 20px 25px;
}

.history-grid__single .content-body .image {
	margin-bottom: 25px;
}

.history-grid__single .content-body .image img {
	width: 100%;
}

/* career page */

.career-single__image {
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 470px;
	height: 100%;
	background-position: center center;
}

.career-single__content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	height: 100%;
	padding: 50px 80px;
}

.career-single__content a {
	width: auto;
}

.career-single__content--yellow-bg {
	background-color: #ffc246;
}

.career-single__content--yellow-bg .career-single__text {
	color: rgba(0, 0, 0, 0.48);
}

.career-single__content--grey-bg {
	background-color: #f7f7f7;
}

.career-single__content--dark-bg {
	background-color: #111;
}

.career-single__content--dark-bg .career-single__title {
	color: #ffffff;
}

.career-single__title {
	font-size: 2.5rem;
	line-height: 1.2;
	margin-bottom: 30px;
	margin-top: -5px;
}

.career-single__text {
	margin-bottom: 50px;
}

/* about page */

.about-content-row__image {
	min-height: 430px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.about-content-row__content {
	padding-left: 60px;
	padding-right: 20px;
}

.about-content-row__content .title {
	font-size: 2.125rem;
	line-height: 1.3;
	width: 270px;
	max-width: 100%;
	margin-bottom: 20px;
}

.about-content-row__content .text {
	margin-bottom: 30px;
}

.about-list-wrapper .about-list-title {
	font-size: 20px;
	line-height: 1.2;
	display: inline-block;
	margin-bottom: 25px;
}

.about-list-wrapper .about-list-title:after {
	content: '';
	display: block;
	margin-top: 2px;
	width: 100%;
	height: 3px;
	background-color: #ffc246;
}

.about-list-wrapper .about-list li {
	padding-left: 30px;
	position: relative;
	margin-bottom: 15px;
}

.about-list-wrapper .about-list li:last-child {
	margin-bottom: 0;
}

.about-list-wrapper .about-list li:before {
	position: absolute;
	top: 6px;
	left: 0;
	display: block;
	font-family: Ionicons;
	content: '\f400';
	font-size: 8px;
	color: #b6b6b6;
}

/* about box */

.about-box-image-wrapper {
	margin-bottom: -40px;
}

.about-box-image-single {
	margin-bottom: 40px;
}

.about-box-image-single__image {
	margin-bottom: 25px;
}

.about-box-image-single__image img {
	width: 100%;
}

.about-box-image-single__content .title {
	font-size: 16px;
	line-height: 1.2;
	margin-bottom: 20px;
}

/* about icon box */

.about-icon-box-wrapper {
	margin-top: 60px;
	margin-bottom: -30px;
}

.about-icon-box {
	text-align: center;
	margin-bottom: 30px;
}

.about-icon-box__image {
	margin-bottom: 30px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.about-icon-box__content .title {
	font-size: 1.25rem;
	margin-bottom: 20px;
}

.about-icon-box:hover .about-icon-box__image {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

/*=====  End of common  ======*/

/*=============================================
=            Shop            =
=============================================*/

/* shop header */

.sort-selection {
	padding: 3px 20px;
	height: 50px;
	outline: none;
	border: 0;
	border-top: 2px solid #ddd;
	border-bottom: 2px solid #ddd;
	border-radius: 0;
	font-weight: 400;
	color: #999;
}

/* shop product */

.shop-single-product-wrapper {
	margin-bottom: -50px;
}

.shop-single-product {
	margin-bottom: 50px;
}

.shop-single-product__thumb-wrapper {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
}

.shop-single-product__badges {
	position: absolute;
	top: 30px;
	left: 28px;
	z-index: 99;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.shop-single-product__badges span {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin: 0 2px;
	min-height: 0;
	min-width: 0;
	border-radius: 0;
	color: #ffffff;
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 1;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.shop-single-product__badges .hot {
	background: #d31129;
	padding: 5px 11px;
}

.shop-single-product__badges .onsale {
	background: #f6a623;
	padding: 5px 8px;
}

.shop-single-product__image > a {
	display: block;
	width: 100%;
}

.shop-single-product__image > a img {
	width: 100%;
}

.shop-single-product__actions {
	position: absolute;
	top: 50%;
	right: 30px;
	z-index: 99;
	margin-top: -30px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.shop-single-product__actions a {
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
	display: block;
	margin: 5px 0;
	width: 48px;
	height: 48px;
	line-height: 48px;
	border-radius: 0;
	color: #454545;
	padding: 0;
	background: #ffffff;
	border: 0;
	text-align: center;
}

.shop-single-product__actions a i {
	font-size: 18px;
}

.shop-single-product__actions a:hover,
.shop-single-product__actions a.active {
	background-color: #ffc246;
	color: #ffffff;
}

.shop-single-product__cart-btn {
	position: absolute;
	left: 0;
	bottom: 0;
	display: block;
	width: 100%;
	-webkit-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.shop-single-product__cart-btn a {
	display: block;
	text-transform: uppercase;
	background-color: #333;
	width: 100%;
	border: none;
	color: #fff;
	line-height: 50px;
	text-align: center;
	font-weight: 700;
	font-size: 13px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.shop-single-product__cart-btn a:hover,
.shop-single-product__cart-btn a.active {
	background-color: #ffc246;
}

.shop-single-product__cart-btn a i {
	font-size: 20px;
	margin-right: 10px;
	display: inline-block;
	line-height: 1;
	width: 20px;
	vertical-align: middle;
	height: 20px;
}

.shop-single-product__content {
	text-align: center;
}

.shop-single-product__title {
	font-size: 20px;
	line-height: 1.4;
}

.shop-single-product__title a {
	color: #222;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.shop-single-product__title a:hover {
	color: #ffc246;
}

.shop-single-product__price {
	line-height: 1;
}

.shop-single-product__price .main-price.discounted {
	color: #999;
	text-decoration: line-through;
}

.shop-single-product__price span {
	font-weight: 600;
	color: #ffc246;
	display: inline-block;
}

.shop-single-product__price span:nth-child(1) {
	margin-right: 5px;
}

.shop-single-product:hover .shop-single-product__cart-btn {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.shop-single-product:hover .shop-single-product__actions a {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

.shop-single-product:hover .shop-single-product__actions a:nth-child(2) {
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.shop-single-product:hover .shop-single-product__actions a:nth-child(3) {
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

/* description tab */

/* description tab area */

.description-tab-navigation .nav-tabs .nav-link {
	border: 0;
	font-size: 20px;
	color: #222;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0;
	position: relative;
	padding: 20px 0 18px;
	margin: 0 20px;
}

.description-tab-navigation .nav-tabs .nav-link:first-child {
	margin-left: 0;
}

.description-tab-navigation .nav-tabs .nav-link.active,
.description-tab-navigation .nav-tabs .nav-link:hover {
	color: #ffc246;
}

.description-tab-navigation .nav-tabs .nav-link.active:after,
.description-tab-navigation .nav-tabs .nav-link:hover:after {
	width: 100%;
}

.description-tab-navigation .nav-tabs .nav-link:after {
	position: absolute;
	bottom: -2px;
	left: 50%;
	width: 0;
	height: 3px;
	content: '';
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: #ffc246;
}

.single-product-description-tab-content .tab-content {
	padding: 80px 0;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content p {
	margin-bottom: 0;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content p.long-desc {
	margin-bottom: 20px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra .short-desc {
	margin-bottom: 60px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-image {
	-webkit-flex-basis: 60%;
	-ms-flex-preferred-size: 60%;
	flex-basis: 60%;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text {
	-webkit-flex-basis: 40%;
	-ms-flex-preferred-size: 40%;
	flex-basis: 40%;
	background-color: #333333;
	color: #ffffff;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 30px;
	padding-right: 30px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text .text-wrapper {
	width: 570px;
	max-width: 100%;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text p.medium-text {
	font-size: 14px;
	color: #ffffff;
	font-weight: 600;
	line-height: 15px;
	letter-spacing: 1px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text p.big-text {
	font-size: 40px;
	color: #ffffff;
	font-weight: 600;
	line-height: 44px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text p.desc-text {
	font-size: 16px;
	color: #ffffff;
	font-weight: 400;
	line-height: 25px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__bottom {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__bottom .single-block-image {
	-webkit-flex-basis: 33.33%;
	-ms-flex-preferred-size: 33.33%;
	flex-basis: 33.33%;
}

.single-product-description-tab-content .tab-content .tab-pane .additional-info-content .additional-info-table {
	width: 100%;
	max-width: 550px;
	margin: 0 auto;
	font-size: 16px;
	border-collapse: collapse;
	border-spacing: 0;
	line-height: 2;
	border-bottom: 1px solid #ededed;
}

.single-product-description-tab-content .tab-content .tab-pane .additional-info-content .additional-info-table tbody {
	display: table-row-group;
	vertical-align: middle;
	border-color: inherit;
}

.single-product-description-tab-content .tab-content .tab-pane .additional-info-content .additional-info-table tbody th {
	color: #333;
	border-top: 1px solid #ededed;
	padding: 15px 10px;
}

.single-product-description-tab-content .tab-content .tab-pane .additional-info-content .additional-info-table tbody td {
	padding: 15px 10px;
	border-top: 1px solid #ededed;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-title {
	font-size: 20px;
	color: #111;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 2px;
	margin-bottom: 25px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments {
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
	padding-right: 40px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment {
	position: relative;
	margin-top: 0;
	margin-left: 50px;
	padding-top: 35px;
	padding-left: 75px;
	padding-right: 30px;
	padding-bottom: 30px;
	margin-bottom: 27px;
	min-height: 170px;
	background-color: #ffffff;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__image {
	position: absolute;
	left: -50px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100px;
	height: 100px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__image img {
	border-radius: 50%;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__content {
	position: relative;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__content .review-time {
	margin-bottom: 15px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__content .review-time i {
	margin-right: 5px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__content .rating {
	position: absolute;
	top: 0;
	right: 0;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__content .rating i {
	color: #f6a623;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form {
	border-left: 1px solid #efefef;
	padding-left: 40px;
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form label {
	display: block;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form label span {
	color: red;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form .rating {
	margin-left: 20px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form .rating i {
	color: #f6a623;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form input,
.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form textarea {
	border: 1px solid #eee;
	padding: 9.5px 25px;
	width: 100%;
}

/* product details image slider */

.product-details__image-slider {
	position: relative;
}

.product-details__image-slider .swiper-pagination {
	position: absolute;
	bottom: 50px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.product-details__image-slider .swiper-pagination-bullet-active {
	background-color: #ffc246;
}

.product-details__image-single img {
	width: 100%;
}

.product-details__title {
	font-size: 2.625rem;
	line-height: 1.5;
	margin-bottom: 30;
}

.product-details__price {
	margin-bottom: 60px;
}

.product-details__price .main-price.discounted {
	color: #CCCCCC;
	text-decoration: line-through;
}

.product-details__price span {
	font-weight: 500;
	font-size: 1.9375rem;
	line-height: 1.8;
	color: #ffc246;
	display: inline-block;
}

.product-details__price span:nth-child(1) {
	margin-right: 5px;
}

.product-details__quantity-wrapper .quantity-label {
	font-weight: 700;
	display: inline-block;
	margin-right: 60px;
}

.product-details__actions .add-cart-btn {
	height: 50px;
	background-color: #ffc246;
	border: 2px solid #ffc246;
	padding: 0 30px;
	line-height: 46px;
	color: #222;
	font-size: 13px;
	font-weight: 700;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.product-details__actions .add-cart-btn i {
	margin-right: 5px;
	font-size: 18px;
	vertical-align: middle;
}

.product-details__actions .add-cart-btn:hover {
	background-color: transparent;
	border-color: #222;
}

.product-details__actions a {
	margin-right: 10px;
}

.product-details__actions a:last-child {
	margin-right: 0;
}

.product-details__actions a.wishlist,
.product-details__actions a.compare {
	width: 50px;
	height: 50px;
	line-height: 48px;
	font-size: 18px;
	border: 1px solid #ededed;
	color: #ababab;
	text-align: center;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.product-details__actions a.wishlist:hover,
.product-details__actions a.wishlist.active,
.product-details__actions a.compare:hover,
.product-details__actions a.compare.active {
	background-color: #ffc246;
	border-color: #ffc246;
	color: #ffffff;
}

.product-details__meta-wrap {
	margin-top: 38px;
	padding-top: 19px;
	width: 100%;
	border-top: 1px solid #f3f3f3;
}

.product-details__meta-wrap .single-meta {
	margin-bottom: 15px;
}

.product-details__meta-wrap .single-meta:last-child {
	margin-bottom: 0;
}

.product-details__meta-wrap .single-meta .title {
	display: inline-block;
	margin-right: 15px;
	font-size: 14px;
	min-width: 100px;
	line-height: 1.3;
}

.product-details__meta-wrap .single-meta .sku a:hover {
	color: #ffc246;
}

/* quantity counter */

.pro-qty {
	width: 100px;
	position: relative;
}

.pro-qty input {
	padding: 3px 30px 3px 10px;
	height: 40px;
	border: 1px solid #f8f8f8;
	width: 100%;
	text-align: center;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pro-qty input:focus {
	border-color: #ffc246;
}

.pro-qty .qty-btn {
	position: absolute;
	right: 15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 12px;
	color: #ccc;
}

.pro-qty .qty-btn:hover {
	color: #ffc246;
}

.pro-qty .qty-btn.inc {
	top: calc(50% - 5px);
}

.pro-qty .qty-btn.dec {
	top: calc(50% + 5px);
}

/*=====  End of Shop  ======*/

/*=============================================
=            Cart            =
=============================================*/

.cart-table {
	border: 1px solid #e7e7e7;
	width: 100%;
	margin-bottom: 30px;
}

.cart-table tr {
	border-bottom: 1px solid #ededed;
}

.cart-table th {
	text-transform: uppercase;
	font-size: 15px;
	line-height: 24px;
	font-weight: 700;
	letter-spacing: 1px;
	color: #222222;
	background-color: transparent;
	padding: 16px 12px;
}

.cart-table th:first-of-type {
	padding-left: 25px;
}

.cart-table td {
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 25px;
}

.cart-table td.product-thumbnail {
	width: 115px;
}

.cart-table td.product-name a {
	display: block;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 20px;
}

.cart-table td.product-name a:hover {
	color: #ffc246;
}

.cart-table td.product-name .product-variation {
	display: block;
	line-height: 22px;
	float: left;
	clear: both;
	color: #ababab;
}

.cart-table td.product-price {
	clear: left;
}

.cart-table td.product-price .price {
	font-size: 18px;
	line-height: 28px;
	font-weight: 400;
	color: #333;
}

.cart-table td.total-price {
	clear: left;
}

.cart-table td.total-price .price {
	font-size: 18px;
	line-height: 28px;
	font-weight: 400;
	color: #333;
}

.cart-table td.product-remove {
	text-align: center;
}

.cart-table td.product-remove a {
	text-align: center;
	font-size: 0;
	display: block;
	height: 45px;
	width: 45px;
	color: #ababab;
	border: 2px solid #e6e6e6;
}

.cart-table td.product-remove a i {
	font-size: 30px;
	line-height: 41px;
}

.cart-table td.product-remove a:hover {
	color: #c61932;
	border-color: #c61932;
}

.cart-coupon-area {
	margin-bottom: 30px;
}

.cart-calculation-area {
	background-color: #F7F7F7;
	padding: 40px;
}

.cart-calculation-area__title {
	font-size: 25px;
	color: #111;
	font-weight: 600;
	line-height: 27px;
}

.cart-calculation-table {
	width: 100%;
	background-color: #ffffff;
	margin-bottom: 30px;
}

.cart-calculation-table th {
	text-transform: uppercase;
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	letter-spacing: 1px;
	color: #222222;
	background-color: transparent;
	padding: 16px 12px;
	padding-left: 10px;
}

.cart-calculation-table td {
	padding-right: 10px !important;
	text-align: right;
}

.cart-calculation-table td.subtotal {
	font-size: 14px;
	line-height: 28px;
	color: #333;
	font-weight: 600;
}

.cart-calculation-table td.total {
	font-size: 24px;
	line-height: 48px;
	color: #333;
	font-weight: 600;
}

.coupon-form input {
	display: inline-block;
	background-color: #fff;
	margin-right: 16px;
	padding: 13px 15px;
	color: #222;
	width: 100%;
	background: #f7f7f7;
	border: 1px solid #eee;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	font-size: 15px;
}

.coupon-form input:focus {
	border-color: #c7c7c7;
}

.stock-stat-message {
	font-size: 12px;
	color: #297e29;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 1px;
	padding: 0 5px;
	border: 2px solid #297e29;
}

.stock-stat-message--out-of-stock {
	color: red;
	border-color: red;
}

/*=====  End of Cart  ======*/

/*=============================================
=            checkout            =
=============================================*/

.billing-form {
	margin-bottom: 40px;
}

/*-- Checkout Title --*/

.checkout-title {
	font-size: 20px;
	line-height: 23px;
	text-decoration: underline;
	text-transform: capitalize;
	font-weight: 700;
	margin-bottom: 30px;
}

/*-- Checkout Form --*/

.checkout-form label {
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	color: #333;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.checkout-form .nice-select {
	width: 100%;
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	border-radius: 0;
	line-height: 23px;
	padding: 10px 20px;
	font-size: 14px;
	height: 45px;
	color: #888;
	margin-bottom: 15px;
}

.checkout-form .nice-select::after {
	width: 6px;
	height: 6px;
	border-width: 1px;
	right: 20px;
	border-color: #888;
}

.checkout-form .nice-select .current {
	color: #888;
	display: block;
	line-height: 23px;
}

.checkout-form .nice-select .list {
	width: 100%;
}

.checkout-form .nice-select:focus {
	border-color: #c7c7c7;
}

.checkout-form input {
	width: 100%;
	background-color: #ffffff;
	border: 1px solid #eee;
	border-radius: 0;
	line-height: 23px;
	padding: 10px 20px;
	font-size: 14px;
	color: #888;
	margin-bottom: 15px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.checkout-form input[type="checkbox"] {
	width: auto;
}

.checkout-form input:focus {
	border: 1px solid #c7c7c7;
}

.checkout-form .check-box {
	float: left;
	margin-right: 70px;
}

.checkout-form .check-box:last-child {
	margin-right: 0;
}

.checkout-form .check-box input[type="checkbox"] {
	display: none;
}

.checkout-form .check-box input[type="checkbox"] + label {
	position: relative;
	padding-left: 30px;
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #222;
	margin: 0;
}

.checkout-form .check-box input[type="checkbox"] + label::before {
	position: absolute;
	left: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: block;
	border: 2px solid #999999;
	content: "";
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.checkout-form .check-box input[type="checkbox"] + label::after {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	content: "\f00c";
	font-family: Fontawesome;
	font-size: 12px;
	line-height: 20px;
	opacity: 0;
	color: #222;
	width: 20px;
	text-align: center;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.checkout-form .check-box input[type="checkbox"]:checked + label::before {
	border: 2px solid #222;
}

.checkout-form .check-box input[type="checkbox"]:checked + label::after {
	opacity: 1;
}

/*-- Shipping Form --*/

#shipping-form {
	display: none;
}

/*-- Checkout Cart Total --*/

.checkout-cart-total {
	background-color: #f2f2f2;
	padding: 45px;
	margin-bottom: 30px;
}

.checkout-cart-total h4 {
	-webkit-flex-basis: 18px;
	-ms-flex-preferred-size: 18px;
	flex-basis: 18px;
	line-height: 23px;
	font-weight: 700;
}

.checkout-cart-total h4:first-child {
	margin-top: 0;
	margin-bottom: 25px;
}

.checkout-cart-total h4:last-child {
	margin-top: 15px;
	margin-bottom: 0;
}

.checkout-cart-total h4 span {
	float: right;
	display: block;
}

.checkout-cart-total ul {
	border-bottom: 1px solid #999999;
}

.checkout-cart-total ul li {
	color: #888;
	font-size: 14px;
	line-height: 23px;
	font-weight: 500;
	display: block;
	margin-bottom: 16px;
}

.checkout-cart-total ul li span {
	color: #222;
	float: right;
}

.checkout-cart-total p {
	font-size: 14px;
	line-height: 30px;
	font-weight: 600;
	color: #505050;
	padding: 10px 0;
	border-bottom: 1px solid #999999;
	margin: 0;
}

.checkout-cart-total p span {
	float: right;
}

/*-- Checkout Payment Method --*/

.checkout-payment-method {
	background-color: #f2f2f2;
	padding: 45px;
}

/*-- Single Payment Method --*/

.single-method {
	margin-bottom: 20px;
}

.single-method:last-child {
	margin-bottom: 0;
}

.single-method input[type="radio"] {
	display: none;
}

.single-method input[type="radio"] + label {
	position: relative;
	padding-left: 30px;
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #222;
	margin: 0;
}

.single-method input[type="radio"] + label::before {
	position: absolute;
	left: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: block;
	border: 2px solid #999999;
	content: "";
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.single-method input[type="radio"] + label::after {
	position: absolute;
	left: 5px;
	top: 5px;
	display: block;
	content: "";
	opacity: 0;
	background-color: #222;
	width: 10px;
	text-align: center;
	height: 10px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.single-method input[type="radio"]:checked + label::before {
	border: 2px solid #222;
}

.single-method input[type="radio"]:checked + label::after {
	opacity: 1;
}

.single-method input[type="checkbox"] {
	display: none;
}

.single-method input[type="checkbox"] + label {
	position: relative;
	padding-left: 30px;
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #888;
	margin: 0;
}

.single-method input[type="checkbox"] + label::before {
	position: absolute;
	left: 0;
	top: 0;
	width: 16px;
	height: 16px;
	display: block;
	border: 2px solid #999999;
	content: "";
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.single-method input[type="checkbox"] + label::after {
	position: absolute;
	left: 4px;
	top: 4px;
	display: block;
	content: "";
	opacity: 0;
	background-color: #222;
	width: 8px;
	text-align: center;
	height: 8px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.single-method input[type="checkbox"]:checked + label::before {
	border: 2px solid #222;
}

.single-method input[type="checkbox"]:checked + label::after {
	opacity: 1;
}

.single-method p {
	display: none;
	margin-top: 8px;
	font-size: 14px;
	color: #888;
	line-height: 23px;
}

/*-- Place Order --*/

.place-order {
	margin-top: 40px;
	width: 140px;
	height: 36px;
	border: none;
	line-height: 24px;
	padding: 6px 20px;
	float: left;
	font-weight: 400;
	text-transform: uppercase;
	color: #ffffff;
	background-color: #222;
}

.place-order:hover {
	background-color: #fff;
}

.place-order-btn {
	margin-top: 15px;
}

/*=====  End of checkout  ======*/

/*=============================================
=           My Account            =
=============================================*/

.myaccount-tab-menu {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #ffffff;
}

.myaccount-tab-menu a {
	border: 1px solid #eeeeee;
	border-bottom: none;
	color: #888;
	font-weight: 500;
	font-size: 12px;
	display: block;
	padding: 15px 15px 13px;
	text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
	border-bottom: 1px solid #eeeeee;
}

.myaccount-tab-menu a:hover,
.myaccount-tab-menu a.active {
	background-color: #ffc246;
	color: #ffffff;
}

.myaccount-tab-menu a i {
	font-size: 14px;
	text-align: center;
	width: 25px;
}

/*-- My Account Content -*/

.myaccount-content {
	background-color: #ffffff;
	font-size: 14px;
	border: 1px solid #eeeeee;
	padding: 30px;
}

.myaccount-content h3 {
	border-bottom: 1px dashed #eeeeee;
	padding-bottom: 10px;
	margin-bottom: 25px;
}

.myaccount-content .welcome a {
	color: #000000;
}

.myaccount-content .welcome a:hover {
	color: #ffc246;
}

.myaccount-content .welcome strong {
	font-weight: 600;
}

.myaccount-content a.edit-address-btn {
	background: none;
	border: none;
	font-weight: 400;
	font-size: 14px;
	text-transform: uppercase;
	color: #ffffff;
	background-color: #222;
	border-color: #222;
	padding: 10px 20px;
	border-radius: 0;
}

.myaccount-content a.edit-address-btn i {
	padding-right: 5px;
}

.myaccount-content a.edit-address-btn:hover {
	background-color: #ffc246;
}

.myaccount-content button.save-change-btn {
	background: none;
	border: none;
	font-weight: 400;
	text-transform: uppercase;
	color: #ffffff;
	background-color: #222;
	border-color: #222;
	width: 140px;
	padding: 10px 0;
}

.myaccount-content button.save-change-btn:hover {
	background-color: #ffc246;
}

/*-- My Account Table -*/

.myaccount-table {
	white-space: nowrap;
	font-size: 15px;
}

.myaccount-table table th,
.myaccount-table .table th {
	padding: 10px;
}

.myaccount-table table td,
.myaccount-table .table td {
	padding: 20px 10px;
	vertical-align: middle;
}

.myaccount-table table td a:hover,
.myaccount-table .table td a:hover {
	color: #ffc246;
}

.saved-message {
	border-top: 3px solid #ffc246;
	border-radius: 5px 5px 0 0;
	font-weight: 600;
	font-size: 13px;
	padding: 20px;
}

/*-- My Account Details Form -*/

.account-details-form h4 {
	margin: 0;
}

.account-details-form input {
	margin-bottom: 30px;
	display: block;
	width: 100%;
	border: 1px solid #ebebeb;
	border-radius: 0;
	line-height: 24px;
	padding: 11px 25px;
	color: #656565;
}

/*=====  End of My Account  ======*/

/*=============================================
=            Login Register            =
=============================================*/

/*-- Login Title --*/

.login-title {
	font-size: 20px;
	line-height: 23px;
	text-decoration: underline;
	text-transform: capitalize;
	font-weight: 700;
	margin-bottom: 30px;
}

/*-- Login Form --*/

.login-form {
	background-color: #ffffff;
	padding: 30px;
	-webkit-box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.1);
}

.login-form label {
	display: block;
	font-size: 14px;
	margin-bottom: 12px;
	font-weight: 500;
	text-transform: capitalize;
}

.login-form .nice-select {
	margin-bottom: 20px;
	width: 100%;
	background-color: transparent;
	border: 1px solid #999999;
	border-radius: 0;
	line-height: 23px;
	padding: 10px 20px;
	font-size: 14px;
	height: 45px;
	color: #888;
	margin-bottom: 15px;
}

.login-form .nice-select::after {
	width: 6px;
	height: 6px;
	border-width: 1px;
	right: 20px;
	border-color: #888;
}

.login-form .nice-select .current {
	color: #888;
	display: block;
	line-height: 23px;
}

.login-form .nice-select .list {
	width: 100%;
}

.login-form input {
	width: 100%;
	background-color: transparent;
	border: 1px solid #999999;
	border-radius: 0;
	line-height: 23px;
	padding: 10px 20px;
	font-size: 14px;
	color: #888;
	margin-bottom: 15px;
}

.login-form input[type="checkbox"] {
	width: auto;
}

.login-form .check-box {
	float: left;
	margin-right: 70px;
}

.login-form .check-box:last-child {
	margin-right: 0;
}

.login-form .check-box input[type="checkbox"] {
	display: none;
}

.login-form .check-box input[type="checkbox"] + label {
	position: relative;
	padding-left: 30px;
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #222;
	margin: 0;
}

.login-form .check-box input[type="checkbox"] + label::before {
	position: absolute;
	left: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: block;
	border: 2px solid #999999;
	content: "";
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.login-form .check-box input[type="checkbox"] + label::after {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	content: "\f00c";
	font-family: Fontawesome;
	font-size: 12px;
	line-height: 20px;
	opacity: 0;
	color: #222;
	width: 20px;
	text-align: center;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.login-form .check-box input[type="checkbox"]:checked + label::before {
	border: 2px solid #222;
}

.login-form .check-box input[type="checkbox"]:checked + label::after {
	opacity: 1;
}

/*-- Place Order --*/

.register-button {
	display: block;
	margin-top: 20px;
	width: 140px;
	height: 36px;
	border: none;
	line-height: 24px;
	padding: 6px 20px;
	float: left;
	font-weight: 400;
	text-transform: uppercase;
	color: #ffffff;
	background-color: #222;
}

.register-button:hover {
	background-color: #ffc246;
}

.forget-pass-link:hover {
	color: #ffc246;
}

/*=====  End of Login Register  ======*/

/*=============================================
=            not found            =
=============================================*/

.not-found__page-title {
	font-size: 200px;
	font-weight: 500;
	line-height: 1.3;
	color: #ffc246;
}

.not-found__page-subtitle {
	font-size: 36px;
	font-weight: 700;
	color: #222;
}

.not-found__page-search form {
	position: relative;
	width: 500px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
}

.not-found__page-search form input {
	width: 100%;
	padding-top: 5px;
	padding-bottom: 5px;
	border: 0;
	border-bottom: 2px solid #ffc246;
}

.not-found__page-search form button {
	font-size: 20px;
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	border: none;
	background: none;
}

/*=====  End of not found  ======*/

/*=============================================
=            site maintenance            =
=============================================*/

.maintenance-section {
	position: relative;
	z-index: 9;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.maintenance-section:before {
	position: absolute;
	z-index: 1;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	content: "";
	opacity: 0.9;
	background-color: #111111;
}

.site-maintenance h2 {
	font-size: 36px;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 10px;
	text-align: center;
	color: #ffc246;
}

.site-maintenance h1 {
	font-size: 58px;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 60px;
	text-align: center;
	color: #ffffff;
}

.site-maintenance p {
	line-height: 1.5;
	width: 555px;
	max-width: 100%;
	margin: auto;
	margin-top: 60px;
	margin-bottom: 35px;
	color: rgba(255, 255, 255, 0.5);
}

/*=====  End of site maintenance  ======*/

@media only screen and (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {

.single-list-blog-post__media .blog-post-big-quote {
	padding: 50px;
}

}

@media only screen and (min-width: 768px) and (max-width: 991px) {

.row-16 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-16 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-17 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-17 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-18 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-18 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-19 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-19 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-20 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-20 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-21 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-21 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-22 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-22 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-23 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-23 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-24 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-24 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-25 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-25 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-26 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-26 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-27 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-27 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-28 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-28 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-29 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-29 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-30 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-30 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-31 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-31 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-32 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-32 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-33 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-33 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-34 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-34 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-35 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-35 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-36 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-36 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-37 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-37 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-38 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-38 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-39 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-39 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-40 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-40 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-41 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-41 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-42 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-42 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-43 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-43 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-44 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-44 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-45 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-45 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-46 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-46 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-47 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-47 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-48 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-48 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-49 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-49 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-50 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-50 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-51 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-51 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-52 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-52 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-53 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-53 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-54 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-54 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-55 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-55 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-56 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-56 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-57 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-57 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-58 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-58 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-59 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-59 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-60 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-60 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-61 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-61 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-62 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-62 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-63 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-63 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-64 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-64 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-65 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-65 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-66 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-66 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-67 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-67 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-68 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-68 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-69 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-69 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-70 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-70 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-71 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-71 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-72 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-72 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-73 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-73 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-74 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-74 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-75 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-75 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-76 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-76 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-77 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-77 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-78 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-78 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-79 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-79 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-80 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-80 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.section-space--inner--30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.section-space--inner--50 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section-space--inner--60 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.section-space--inner--80 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.section-space--inner--100 {
	padding-top: 80px;
	padding-bottom: 80px;
}

.section-space--inner--120 {
	padding-top: 80px;
	padding-bottom: 80px;
}

.section-space--outter--120 {
	margin-top: 80px;
	margin-bottom: 80px;
}

.section-space--inner--140 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.section-space--inner--190 {
	padding-top: 120px;
	padding-bottom: 120px;
}

.section-space--inner--top--120 {
	padding-top: 80px;
}

.section-space--inner--top--100 {
	padding-top: 80px;
}

.section-space--inner--top--200 {
	padding-top: 80px;
}

.section-space--inner--bottom--120 {
	padding-bottom: 80px;
}

.section-space--top--60 {
	margin-top: 40px;
}

.section-space--top--80 {
	margin-top: 60px;
}

.section-space--bottom--60 {
	margin-bottom: 40px !important;
}

.section-space--bottom--80 {
	margin-bottom: 60px;
}

.section-space--bottom--120 {
	margin-bottom: 80px !important;
}

.header-overlay-menu::before {
	display: none;
}

.footer-intro-wrapper {
	margin-bottom: 40px;
}

.page-sidebar {
	margin-top: 70px;
}

.search-overlay .popup-search-close {
	right: 30px;
}

.search-overlay .page-popup-search-inner {
	width: 100%;
}

.feature-project-box-wrapper {
	margin-top: -260px;
}

.single-feature-project-box__title {
	font-size: 16px !important;
}

.featured-project-three-slider__container-area .swiper-pagination {
	bottom: -50px;
}

.featured-project-three-slider__container {
	max-width: 720px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
}

.featured-project-three-slider__single-slide-wrapper {
	margin-right: auto;
	margin-left: auto;
}

.industry-single-nav {
	padding: 10px 30px;
}

.industry-slider-content-single {
	height: 600px;
}

.industry-four-slider__container-area .ht-swiper-button-prev {
	left: 50px;
}

.industry-four-slider__container-area .ht-swiper-button-next {
	right: 50px;
}

.industry-six-slider__container-area .swiper-pagination {
	padding-left: 25px;
}

.industry-six-slider__content {
	left: 40px;
}

.industry-seven-slider__single-slide .item-wrap__image {
	height: 500px;
}

.industry-seven-slider__single-slide .item-wrap__info {
	padding-left: 30px;
	padding-right: 30px;
	height: 500px;
}

.testimonial-slider-three__container-area {
	padding: 60px 30px 0;
}

.testimonial-slider-three__container-area .testi-quote {
	top: 0;
}

.testimonial-slider-six__content-container-area {
	padding-top: 40px;
}

.testimonial-slider-six__content-container-area .swiper-pagination {
	margin-top: 50px;
}

.project-counter-bg {
	padding: 200px 0;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.project-counter-content {
	padding: 80px 30px;
}

.blog-title-wrapper-area {
	margin-bottom: 60px;
}

.blog-title-wrapper {
	margin-bottom: 40px;
}

.blog-grid-wrapper--style5 {
	margin-bottom: -80px;
}

.single-grid-blog-post__content .post-title {
	font-size: 1.125rem;
}

.single-grid-blog-post--style2 {
	margin-bottom: 80px;
}

.google-map--style2 {
	height: 400px;
}

.cta-video-image__icon {
	width: 64px;
	height: 64px;
	line-height: 64px;
	left: -32px;
}

.cta-blog-area {
	padding-top: 200px;
	padding-left: 30px;
	padding-right: 30px;
}

.career-banner__content .subtitle {
	margin-bottom: 20px;
}

.career-banner__content .title {
	margin-bottom: 40px;
}

.job-list__wrapper {
	margin-top: 30px;
}

.single-icon-info__content {
	-webkit-flex-basis: calc(50% - 100px);
	-ms-flex-preferred-size: calc(50% - 100px);
	flex-basis: calc(50% - 100px);
}

.banner-image-two__title {
	font-size: 1.5625rem;
}

.banner-image-three__content {
	padding-top: 80px;
}

.banner-image-three__image {
	padding-top: 80px;
	padding-bottom: 80px;
	border-left: 1px solid #eee;
}

.banner-image-three__image .banner-sign {
	right: 30px;
	bottom: 30px;
}

.feature-background__content {
	max-width: 360px;
	width: 100%;
	padding-left: 30px;
}

.feature-background__content--style3 {
	padding-top: 100px;
	padding-bottom: 100px;
	padding-left: 40px;
	float: none;
}

.project-grid-post__wrapper {
	margin-bottom: -30px;
}

.service .service-inner {
	padding: 60px 30px;
}

.twitter-feed-slider__area {
	min-height: 370px;
}

.twitter-feed-slider__single--style2 .tweet-date {
	left: 30px;
	top: 30px;
}

.twitter-feed-slider__single--style2 .tweet-text {
	padding: 0 30px;
}

.single-contact-icon-info--style2 {
	padding: 40px 20px;
}

.single-contact-icon-info--style2 .single-contact-icon-info__image {
	margin-right: 15px;
}

.contact-social-link-list-wrapper {
	margin-top: 50px;
}

.single-industry-icon--style2 .single-industry-icon__content .text-wrapper {
	padding-left: 15px;
	padding-right: 15px;
}

.industry-tab__link-wrapper.nav-tabs a.nav-link {
	padding: 15px 25px;
}

.breadcrumb-page-title--style2 {
	margin-bottom: 50px;
}

.single-list-blog-post-wrapper {
	margin-bottom: -60px;
}

.single-list-blog-post {
	margin-bottom: 60px;
}

.team-member-slider-area {
	padding-bottom: 30px;
}

.solution-grid-wrapper {
	margin-top: -10px;
	padding-top: 0;
}

.solution-grid-wrapper:before {
	display: none;
}

.service-details-box-icon__wrapper--style2 {
	margin-bottom: -60px;
}

.about-list-wrapper {
	margin-top: 50px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text .text-wrapper {
	width: auto;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	padding-right: 0;
	margin-bottom: 30px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	padding-left: 0;
	border-left: 0;
}

.product-details__price {
	margin-bottom: 40px;
}

.cart-table thead {
	display: none;
}

.cart-table tr {
	position: relative;
	display: block;
	text-align: center;
	border: 1px solid #ededed;
	padding: 30px 0;
}

.cart-table td {
	text-align: center;
	display: block;
	width: 100% !important;
	margin: 0 auto;
	padding: 0 !important;
	border: none;
}

.cart-table td.product-name a {
	margin-top: 20px;
}

.cart-table td.product-name .product-variation {
	float: none;
	margin-bottom: 10px;
}

.cart-table td.product-price {
	margin-bottom: 15px;
}

.cart-table td.stock-status {
	margin-bottom: 15px;
}

.cart-table td.product-quantity {
	margin-bottom: 20px;
}

.cart-table td.product-remove {
	position: absolute;
	top: 10px;
	right: 10px;
	width: auto !important;
}

.cart-table td.product-remove a i {
	line-height: 35px;
}

.cart-table td.product-remove a {
	border: none;
	width: auto;
	height: auto;
}

.coupon-form {
	margin-bottom: 30px;
}

.shipping-form {
	margin-bottom: 30px;
}

.login-form {
	margin-bottom: 30px;
}

.login-form {
	padding: 15px;
}

}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {

.header-area__desktop--default .header-top-bar {
	display: none;
}

.footer-widget__title {
	margin-bottom: 20px;
}

.section-title-area .title--left--style2 span.translate-left {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

.section-title-area .title--left--style2 span.translate-left--style2 {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

.section-title-content-wrapper--about-counter {
	padding-left: 0;
	border: 0;
}

.section-title-content-wrapper--about-counter--style2 {
	padding-left: 0;
	border: 0;
}

.case-study__page-content-title {
	margin-top: -5px;
}

.half-bg-image {
	position: static;
	width: 100%;
	min-height: 500px;
}

.industry-slider-nav-area {
	padding: 0;
}

.industry-three-slider__area .section-title-area {
	padding-left: 0;
}

.industry-three-slider__single-slide .item-wrap__content {
	margin-bottom: 0;
}

.industry-three-slider__single-slide:nth-child(2n+1) .item-wrap__content {
	margin-top: 0;
}

.industry-four-slider__container {
	padding: 30px 0;
}

.industry-seven-slider__single-slide .item-wrap__info .show-more-link {
	right: 30px;
}

.testimonial-slider-three__area {
	background-image: none !important;
}

.testimonial-slider-three__image {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.project-counter-single-content--style2 .project-counter-single-content__image {
	margin-bottom: 15px;
}

.hiring-background {
	margin-bottom: 30px;
	height: auto;
}

.hiring-background__content .ht-btn {
	margin-bottom: 0;
}

.support-footer__area .contact-form-area {
	float: none;
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	padding-left: 30px;
	padding-right: 30px;
}

.google-map--style3 {
	height: 400px;
	margin-bottom: 30px;
}

.cta-text {
	margin-bottom: 30px;
}

.single-icon-info {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.banner-video-area {
	background-image: none !important;
}

.banner-video-area__content .section-title-area .title {
	margin-top: -5px;
}

.banner-image-two {
	padding: 100px 0;
}

.industry-grid-wrapper {
	margin-bottom: -40px;
}

.single-industry-grid-two {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.single-industry-grid-two__content {
	padding-top: 50px;
}

.single-industry-grid-two__content .content-wrapper {
	max-width: 100%;
}

.single-industry-grid-two:nth-child(2n) .single-industry-grid-two__image {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.single-industry-grid-two:nth-child(2n) .single-industry-grid-two__content {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2;
}

.feature-background__image--style2 {
	min-height: 400px;
}

.about-counter-header-wrapper .section-title-area {
	margin-bottom: 20px;
}

.project-grid-5-column .project-item {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 50%;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.project .image::before {
	left: 10px;
	top: 10px;
	right: 10px;
	bottom: 10px;
	opacity: 0.75;
}

.project .content {
	opacity: 1;
}

.project .content .title {
	margin-bottom: 5px;
}

.twitter-feed-slider__container-area--style2 .ht-swiper-button-prev {
	left: 15px;
}

.twitter-feed-slider__container-area--style2 .ht-swiper-button-next {
	left: 55px;
}

.twitter-block__status {
	min-height: 0;
}

.contact-form-wrapper--style2 {
	margin-bottom: 40px;
}

.industry-icon-wrapper {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.single-industry-icon-wrapper {
	-webkit-flex-basis: 50%;
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
}

.single-industry-icon-wrapper:before {
	display: none;
}

.case-study-table {
	margin-top: 40px;
}

.solution-grid-wrapper .count-wrap {
	margin-top: 50px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-image {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	padding: 50px 30px;
}

.product-details__image-slider {
	margin-bottom: 40px;
}

}

@media only screen and (min-width: 768px) and (max-width: 991px) and (max-width: 1200px) {

.banner-image-two__title {
	font-size: calc(1.28125rem + 0.375vw);
}

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

.row-16 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-16 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-17 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-17 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-18 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-18 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-19 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-19 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-20 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-20 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-21 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-21 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-22 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-22 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-23 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-23 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-24 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-24 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-25 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-25 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-26 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-26 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-27 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-27 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-28 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-28 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-29 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-29 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-30 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-30 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-31 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-31 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-32 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-32 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-33 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-33 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-34 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-34 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-35 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-35 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-36 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-36 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-37 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-37 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-38 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-38 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-39 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-39 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-40 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-40 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-41 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-41 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-42 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-42 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-43 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-43 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-44 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-44 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-45 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-45 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-46 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-46 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-47 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-47 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-48 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-48 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-49 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-49 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-50 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-50 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-51 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-51 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-52 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-52 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-53 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-53 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-54 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-54 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-55 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-55 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-56 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-56 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-57 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-57 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-58 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-58 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-59 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-59 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-60 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-60 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-61 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-61 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-62 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-62 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-63 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-63 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-64 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-64 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-65 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-65 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-66 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-66 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-67 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-67 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-68 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-68 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-69 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-69 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-70 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-70 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-71 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-71 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-72 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-72 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-73 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-73 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-74 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-74 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-75 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-75 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-76 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-76 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-77 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-77 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-78 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-78 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-79 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-79 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-80 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-80 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.featured-project-three-slider__container {
	max-width: 960px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
}

.featured-project-three-slider__single-slide-wrapper {
	margin-right: auto;
	margin-left: auto;
}

.industry-single-nav {
	padding: 10px 30px;
}

.industry-single-nav__title {
	font-size: 16px;
}

.industry-slider-content-single {
	height: 600px;
}

.industry-two-slider .ht-swiper-button-prev {
	left: 30px;
}

.industry-two-slider .ht-swiper-button-next {
	right: 30px;
}

.industry-six-slider__container-area .swiper-pagination {
	padding-left: 15px;
}

.industry-six-slider__content {
	left: 25px;
}

.industry-six-slider__background {
	min-height: 650px;
}

.testimonial-slider-two__slide.swiper-slide-active {
	width: 100% !important;
	max-width: 100%;
}

.testimonial-slider-three__container-area {
	padding: 60px 30px;
}

.testimonial-slider-six__content-container-area .ht-swiper-button-prev {
	left: 0;
}

.testimonial-slider-six__content-container-area .ht-swiper-button-next {
	right: 0;
}

.project-counter-bg {
	padding: 200px 0;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.project-counter-content {
	padding: 100px 30px;
}

.support-footer__area .contact-form-area {
	float: none;
	width: 770px;
	max-width: 100%;
	margin: 0 auto;
}

.google-map {
	height: 400px;
}

.google-map--style2 {
	height: 400px;
}

.google-map--style3 {
	height: 100%;
}

.career-banner__content .subtitle {
	margin-bottom: 20px;
}

.career-banner__content .title {
	margin-bottom: 40px;
}

.career-banner .col-lg-8 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 100%;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.career-banner .col-lg-4 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 100%;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.feature-background__content {
	max-width: 480px;
	width: 100%;
	padding-left: 30px;
}

.feature-background__content--style3 {
	padding-left: 20px;
	float: none;
}

.feature-background__content-wrapper {
	width: 100%;
	padding-left: 50px;
	padding-right: 50px;
}

.project-grid-5-column .project-item {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 25%;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.contact-social-link-list li {
	float: none;
	width: 100%;
}

.single-industry-icon__content .title {
	font-size: 1.125rem;
}

.single-industry-flip-front__content {
	padding: 50px 15px;
}

.single-industry-flip-front__content .title {
	font-size: 18px;
}

.single-industry-flip-back__content .career-btn {
	margin-top: 40px;
}

.common-blog-carousel .ht-swiper-button-nav {
	display: none !important;
}

.history-grid__single-wrapper {
	padding-right: 30px;
}

.history-grid__single-wrapper:nth-child(2n) {
	padding-left: 30px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text .text-wrapper {
	width: auto;
}

}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {

.vertical-navigation-home-wrapper {
	margin-left: 0;
}

.header-area__desktop {
	display: none;
}

.header-area__desktop--default {
	display: block;
}

.header-area__desktop--default .header-navigation-area {
	display: none;
}

.header-area__desktop--default .mobile-navigation-icon {
	display: block;
}

.header-area__desktop--default .header-info-area {
	padding: 25px 0;
}

.header-area__mobile {
	display: block;
}

.header-area--absolute {
	position: static;
}

.header-area--transparent {
	background-color: #ffffff;
}

.header-navigation-wrapper--style3 {
	height: 100%;
}

.header-navigation-wrapper--style3__left {
	display: none;
}

.mobile-navigation-icon--mobile-off {
	display: none;
}

.mobile-navigation-icon--desktop-off {
	display: block;
}

.header-wrapper--style3 {
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.header-wrapper--style3__header-right {
	-webkit-box-flex: 0;
	-webkit-flex-grow: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}

.navigation-top--style3 {
	display: none;
}

.cart-wrapper--style3 .header-navigation__icon__cart--mobile-off {
	display: none;
}

.cart-wrapper--style3 .header-navigation__icon__cart--desktop-off {
	display: block;
}

.footer-logo--style2 {
	margin-bottom: 50px;
}

.footer-intro-wrapper--style2 {
	margin-bottom: 40px;
}

.featured-project-three-slider__single-slide-wrapper.swiper-slide-next .content {
	display: block;
}

.featured-project-three-slider__single-slide {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.featured-project-three-slider__single-slide .image {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	width: 100%;
}

.featured-project-three-slider__single-slide .content {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	width: 100%;
}

.project-counter-wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.banner-image-content {
	padding: 80px 30px;
}

.single-industry-grid-two .post-readmore-btn {
	position: absolute;
	bottom: 0;
	top: auto;
	left: 0;
	width: 100%;
	height: 60px;
}

.single-industry-grid-two .post-readmore-btn span {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	padding-left: 30px;
}

.single-industry-grid-two .post-readmore-btn i {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	right: 30px;
	left: auto;
	top: 50%;
	bottom: auto;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.single-industry-grid-two .post-readmore-btn:after {
	width: 0;
	height: 100%;
	left: auto;
	right: 0;
}

.single-industry-grid-two .post-readmore-btn:hover:after {
	width: 100%;
	right: auto;
	left: 0;
}

.service-details-chart {
	margin-top: 40px;
}

}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {

.header-area__desktop--default .header-info-single-item {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.industry-six-slider__single-slide {
	padding-left: 300px;
}

.blog-grid-wrapper--style3 {
	margin-bottom: 40px;
	padding-bottom: 0;
}

}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 479px) {

.single-grid-overlay-blog-post__content--style2 {
	padding: 0 25px 25px;
}

}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {

.row-16 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-16 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-17 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-17 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-18 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-18 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-19 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-19 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-20 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-20 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-21 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-21 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-22 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-22 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-23 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-23 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-24 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-24 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-25 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-25 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-26 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-26 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-27 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-27 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-28 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-28 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-29 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-29 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-30 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-30 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-31 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-31 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-32 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-32 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-33 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-33 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-34 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-34 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-35 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-35 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-36 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-36 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-37 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-37 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-38 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-38 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-39 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-39 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-40 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-40 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-41 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-41 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-42 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-42 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-43 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-43 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-44 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-44 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-45 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-45 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-46 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-46 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-47 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-47 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-48 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-48 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-49 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-49 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-50 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-50 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-51 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-51 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-52 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-52 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-53 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-53 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-54 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-54 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-55 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-55 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-56 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-56 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-57 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-57 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-58 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-58 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-59 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-59 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-60 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-60 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-61 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-61 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-62 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-62 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-63 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-63 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-64 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-64 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-65 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-65 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-66 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-66 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-67 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-67 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-68 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-68 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-69 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-69 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-70 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-70 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-71 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-71 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-72 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-72 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-73 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-73 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-74 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-74 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-75 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-75 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-76 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-76 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-77 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-77 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-78 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-78 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-79 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-79 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-80 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-80 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

body.boxed {
	max-width: 1200px;
}

.header-area.header-sticky.is-sticky .header-navigation-wrapper--style5 .header-right .button-wrap {
	display: none;
}

.header-area__desktop .header-navigation-area--style2 {
	padding-left: 30px;
}

.topbar-info--style3 li {
	padding: 10px 5px;
}

.topbar-info--style4 li {
	padding: 0 5px;
}

.language-change-wrapper--style2 .language-dropdown {
	right: 0;
	left: auto;
}

.header-navigation-wrapper--style5 .header-right .button-wrap {
	display: none;
}

.header-navigation {
	-webkit-flex-basis: calc(100% - 430px);
	-ms-flex-preferred-size: calc(100% - 430px);
	flex-basis: calc(100% - 430px);
}

.header-navigation__nav {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.header-navigation__nav nav > ul > li > a {
	padding: 30px 13px;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children .megamenu {
	top: -200px;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children > .submenu {
	top: -200px;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children > .submenu .submenu {
	top: -100px;
}

.header-navigation__nav--vertical-mode nav > ul > li.has-children .megamenu--home-variation {
	top: -250px;
	min-width: 800px;
}

.header-search--style3 input {
	width: 300px;
}

.header-search--style5 input {
	width: 300px;
}

.header-search {
	-webkit-flex-basis: 210px;
	-ms-flex-preferred-size: 210px;
	flex-basis: 210px;
}

.search-form {
	width: 180px;
}

.megamenu--home-variation {
	width: 100%;
	min-width: 640px;
}

.megamenu--home-variation__item__title {
	font-size: 0.9375rem;
}

.megamenu--mega {
	min-width: 700px;
}

.megamenu--tab {
	min-width: 900px;
}

.get-quote-button-wrapper a {
	min-width: 180px;
}

.header-navigation-area-wrapper .header-navigation-wrapper {
	padding: 0 30px;
}

.featured-project-three-slider__container {
	max-width: 1170px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

.featured-project-three-slider__single-slide-wrapper {
	width: 100% !important;
	margin-right: auto;
	margin-left: auto;
}

.industry-single-nav {
	padding: 10px 30px;
}

.industry-single-nav__title {
	font-size: 18px;
}

.industry-slider-content-single {
	height: 600px;
}

.industry-two-slider .ht-swiper-button-prev {
	left: 30px;
}

.industry-two-slider .ht-swiper-button-next {
	right: 30px;
}

.testimonial-slider__content-area {
	margin: 0 -15px;
	margin-top: -30px;
}

.testimonial-slider__container {
	padding: 30px 15px;
}

.testimonial-slider-two__slide.swiper-slide-active {
	width: 100% !important;
	max-width: 100%;
}

.testimonial-slider-six__content-container-area .ht-swiper-button-prev {
	left: 0;
}

.testimonial-slider-six__content-container-area .ht-swiper-button-next {
	right: 0;
}

.brand-logo-slider__container-area {
	margin: -15px;
}

.brand-logo-slider__container {
	padding: 15px;
}

.project-counter-content {
	padding: 120px 100px;
}

.support-footer__area .contact-form-area {
	padding-left: 30px;
	padding-right: 30px;
	width: 100%;
	margin-right: 0;
}

.industry-grid-wrapper--laptop-width .col-xl-4 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 50%;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.feature-background__content {
	padding-left: 30px;
}

.feature-background__content--style3 {
	float: right;
	padding-left: 15px;
	padding-right: 15px;
	width: 500px;
}

.common-blog-carousel .ht-swiper-button-nav {
	display: none !important;
}

.career-single__content {
	padding: 50px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text .text-wrapper {
	width: auto;
}

}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {

.header-navigation__contact {
	display: none;
}

.glyph {
	width: 9em;
}

.featured-project-two-slider-container {
	margin: -15px;
}

.featured-project-two-slider-wrapper .swiper-slide {
	padding: 15px;
}

.single-industry-grid {
	padding: 0;
}

.feature-background__content-area {
	padding-left: 0;
}

}

@media (min-width: 1200px) {

.page-content-double-sidebar .container {
	max-width: 1600px;
}

.container {
	max-width: 1200px;
}

}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {

.featured-project-two-slider .ht-swiper-button-next {
	right: 50px;
}

.featured-project-two-slider .ht-swiper-button-prev {
	left: 50px;
}

.featured-project-three-slider__container-area .ht-swiper-button-nav {
	display: none !important;
}

.industry-four-slider__container-area .ht-swiper-button-prev {
	left: 0;
}

.industry-four-slider__container-area .ht-swiper-button-next {
	right: 0;
}

.cta-blog-area {
	padding-top: 250px;
}

.banner-image-three__image .banner-sign {
	right: 30px;
}

}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 768px) and (max-width: 991px) {

.feature-background__content-wrapper {
	width: 100%;
	padding-left: 100px;
	padding-right: 100px;
}

}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {

.team-slider .ht-swiper-button-prev {
	left: 50px;
}

.team-slider .ht-swiper-button-next {
	right: 50px;
}

.ecosystem-slider .ht-swiper-button-prev {
	left: 50px;
}

.ecosystem-slider .ht-swiper-button-next {
	right: 50px;
}

.product-slider .ht-swiper-button-prev {
	left: 50px;
}

.product-slider .ht-swiper-button-next {
	right: 50px;
}

.portfolio-slider .ht-swiper-button-prev {
	left: 50px;
}

.portfolio-slider .ht-swiper-button-next {
	right: 50px;
}

.related-product-slider .ht-swiper-button-prev {
	left: 50px;
}

.related-product-slider .ht-swiper-button-next {
	right: 50px;
}

.career-single__image {
	min-height: 400px;
}

}

@media (max-width: 1919px) {

.container-fluid--cp-60 {
	padding: 0 30px;
}

.container-fluid--cp-80 {
	padding: 0 40px;
}

}

@media only screen and (max-width: 1919px) and (max-width: 767px) {

.container-fluid--cp-80 {
	padding: 0 30px;
}

}

@media only screen and (max-width: 1919px) and (max-width: 575px) {

.container-fluid--cp-80 {
	padding: 0 15px;
}

}

@media (max-width: 1200px) {

h1 {
	font-size: calc(1.325rem + 0.9vw);
}

h2 {
	font-size: calc(1.28125rem + 0.375vw);
}

h3 {
	font-size: calc(1.2625rem + 0.15vw);
}

.header-navigation__contact__image i {
	font-size: calc(1.425rem + 2.1vw);
}

.header-navigation__contact__content .sub-text {
	font-size: calc(1.275rem + 0.3vw);
}

.header-info-single-item__icon i {
	font-size: calc(1.3625rem + 1.35vw);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon i {
	font-size: calc(1.46875rem + 2.625vw);
}

.header-text-info-wrapper__icon i {
	font-size: calc(1.35625rem + 1.275vw);
}

.header-text-info-wrapper__text {
	font-size: calc(1.275rem + 0.3vw);
}

.footer-widget__title--style3 {
	font-size: calc(1.3125rem + 0.75vw);
}

.search-overlay .popup-search-close {
	font-size: calc(1.75rem + 6vw);
}

.section-title-area .title {
	font-size: calc(1.375rem + 1.5vw);
}

.section-title-area .title--left--style2 {
	font-size: calc(1.4375rem + 2.25vw);
}

.section-title-area .title--style2 {
	font-size: calc(1.4375rem + 2.25vw);
}

.section-title-area .title--style3 {
	font-size: calc(1.4125rem + 1.95vw);
}

.section-title-area .title--style5 {
	font-size: calc(1.4375rem + 2.25vw);
}

.section-title-area .title--style6 {
	font-size: calc(1.4375rem + 2.25vw);
}

.section-title-area .title--style7 i {
	font-size: calc(1.4375rem + 2.25vw);
}

.section-title-area .title--style8--small {
	font-size: calc(1.3125rem + 0.75vw);
}

.service-title-wrapper .title {
	font-size: calc(1.4375rem + 2.25vw);
}

.career-title-area .title {
	font-size: calc(1.375rem + 1.5vw);
}

.single-feature-project-box__title {
	font-size: calc(1.275rem + 0.3vw);
}

.featured-project-three-slider__single-slide .content .project-no {
	font-size: calc(1.275rem + 0.3vw);
}

.featured-project-three-slider__single-slide .content .project-name {
	font-size: calc(1.4375rem + 2.25vw);
}

.industry-single-nav__icon {
	font-size: calc(1.4375rem + 2.25vw);
}

.industry-two-slider__single-item__content .title {
	font-size: calc(1.275rem + 0.3vw);
}

.industry-three-slider__single-slide .item-wrap__content__title:after {
	font-size: calc(1.275rem + 0.3vw);
}

.industry-five-slider__single-slide__content .title {
	font-size: calc(1.275rem + 0.3vw);
}

.industry-six-slider__content .slider-no {
	font-size: calc(1.275rem + 0.3vw);
}

.industry-six-slider__content .slider-title {
	font-size: calc(1.375rem + 1.5vw);
}

.industry-seven-slider__single-slide .item-wrap__info .icon {
	font-size: calc(1.5625rem + 3.75vw);
}

.industry-seven-slider__single-slide .item-wrap__info .title {
	font-size: calc(1.375rem + 1.5vw);
}

.testimonial-slider__title {
	font-size: calc(1.375rem + 1.5vw);
}

.testimonial-slider__title--style2 i {
	font-size: calc(1.5125rem + 3.15vw);
}

.testimonial-slider-two__slide .text {
	font-size: calc(1.3375rem + 1.05vw);
}

.testimonial-slider-three__single-slide .testimonial-item__text {
	font-size: calc(1.2875rem + 0.45vw);
}

.testimonial-slider-six__content-single-slide .testimonial-content-wrapper .testimonial-text {
	font-size: calc(1.3125rem + 0.75vw);
}

.project-counter-single-content__project-count {
	font-size: calc(1.5rem + 3vw);
}

.project-counter-two-single__number {
	font-size: calc(1.4rem + 1.8vw);
}

.blog-title-wrapper .single-blog-title .post-title {
	font-size: calc(1.275rem + 0.3vw);
}

.blog-post-slider__content .post-title {
	font-size: calc(1.2875rem + 0.45vw);
}

.blog-post-slider__content--style2 .post-title {
	font-size: calc(1.275rem + 0.3vw);
}

.newsletter-box-wrapper .newsletter-title {
	font-size: calc(1.325rem + 0.9vw);
}

.single-grid-blog-post__content .post-title {
	font-size: calc(1.275rem + 0.3vw);
}

.single-grid-overlay-blog-post__content .post-title {
	font-size: calc(1.275rem + 0.3vw);
}

.hiring-background__content .title {
	font-size: calc(1.3125rem + 0.75vw);
}

.support-footer__single {
	font-size: calc(1.275rem + 0.3vw);
}

.animated-dot .middle-dot {
	font-size: calc(1.375rem + 1.5vw);
}

.cta-content__title {
	font-size: calc(1.4rem + 1.8vw);
}

.cta-content__title--style2 {
	font-size: calc(1.5rem + 3vw);
}

.cta-blog-content__title {
	font-size: calc(1.4375rem + 2.25vw);
}

.cta-text {
	font-size: calc(1.35rem + 1.2vw);
}

.career-banner__content .title {
	font-size: calc(1.375rem + 1.5vw);
}

.job-list__title-wrapper .title {
	font-size: calc(1.3375rem + 1.05vw);
}

.career-background__content .title {
	font-size: calc(1.4rem + 1.8vw);
}

.banner-image-content__title {
	font-size: calc(1.4375rem + 2.25vw);
}

.banner-image-two__title {
	font-size: calc(1.4375rem + 2.25vw);
}

.banner-image-three__content .title {
	font-size: calc(1.4375rem + 2.25vw);
}

.partner-career__single__content .title {
	font-size: calc(1.2875rem + 0.45vw);
}

.single-industry-grid__heading .icon {
	font-size: calc(1.4375rem + 2.25vw);
}

.single-industry-grid-two__content .title {
	font-size: calc(1.375rem + 1.5vw);
}

.single-industry-grid-two__content .title i {
	font-size: calc(1.625rem + 4.5vw);
}

.single-counter__number {
	font-size: calc(1.625rem + 4.5vw);
}

.single-counter__number .number {
	font-size: calc(1.625rem + 4.5vw);
}

.project-grid-post__feature-list .title {
	font-size: calc(1.275rem + 0.3vw);
}

.twitter-feed-slider__single .tweet-text {
	font-size: calc(1.2625rem + 0.15vw);
}

.twitter-block__logo:before {
	font-size: calc(1.5rem + 3vw);
}

.single-contact-icon-info__image i {
	font-size: calc(1.3rem + 0.6vw);
}

.single-industry-icon__content .title {
	font-size: calc(1.275rem + 0.3vw);
}

.quote-background__content .quote {
	font-size: calc(1.4rem + 1.8vw);
}

.breadcrumb-page-title {
	font-size: calc(1.375rem + 1.5vw);
}

.case-study__page-content-title {
	font-size: calc(1.3125rem + 0.75vw);
}

.case-study__single-step .count {
	font-size: calc(1.375rem + 1.5vw);
}

.case-study__single-gallery-image a:after {
	font-size: calc(1.4375rem + 2.25vw);
}

.single-list-blog-post__media .blog-post-link a {
	font-size: calc(1.275rem + 0.3vw);
}

.single-list-blog-post__media .blog-post-big-quote .blog-quote-text {
	font-size: calc(1.275rem + 0.3vw);
}

.single-list-blog-post__content--style2 .title {
	font-size: calc(1.375rem + 1.5vw);
}

.quote-text:before {
	font-size: calc(1.275rem + 0.3vw);
}

.blog-post-comment .comment-title .title {
	font-size: calc(1.3125rem + 0.75vw);
}

.service-details-text-wrapper .title {
	font-size: calc(1.3125rem + 0.75vw);
}

.service-details-text-wrapper .big-title {
	font-size: calc(1.375rem + 1.5vw);
}

.service-details-box-icon__single .icon {
	font-size: calc(1.3125rem + 0.75vw);
}

.career-single__title {
	font-size: calc(1.375rem + 1.5vw);
}

.about-content-row__content .title {
	font-size: calc(1.3375rem + 1.05vw);
}

.product-details__title {
	font-size: calc(1.3875rem + 1.65vw);
}

.product-details__price span {
	font-size: calc(1.31875rem + 0.825vw);
}

}

@media only screen and (max-width: 767px) {

.row-16 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-16 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-17 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-17 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-18 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-18 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-19 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-19 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-20 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-20 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-21 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-21 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-22 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-22 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-23 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-23 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-24 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-24 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-25 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-25 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-26 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-26 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-27 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-27 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-28 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-28 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-29 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-29 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-30 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-30 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-31 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-31 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-32 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-32 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-33 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-33 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-34 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-34 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-35 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-35 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-36 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-36 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-37 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-37 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-38 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-38 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-39 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-39 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-40 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-40 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-41 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-41 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-42 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-42 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-43 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-43 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-44 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-44 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-45 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-45 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-46 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-46 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-47 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-47 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-48 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-48 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-49 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-49 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-50 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-50 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-51 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-51 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-52 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-52 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-53 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-53 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-54 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-54 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-55 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-55 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-56 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-56 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-57 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-57 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-58 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-58 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-59 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-59 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-60 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-60 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-61 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-61 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-62 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-62 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-63 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-63 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-64 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-64 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-65 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-65 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-66 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-66 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-67 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-67 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-68 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-68 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-69 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-69 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-70 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-70 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-71 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-71 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-72 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-72 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-73 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-73 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-74 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-74 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-75 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-75 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-76 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-76 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-77 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-77 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-78 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-78 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-79 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-79 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.row-80 {
	margin-left: -15px;
	margin-right: -15px;
}

.row-80 > [class*="col"] {
	padding-left: 15px;
	padding-right: 15px;
}

.section-space--inner--30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.section-space--inner--50 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section-space--inner--60 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.section-space--inner--80 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.section-space--inner--100 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.section-space--inner--120 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.section-space--outter--120 {
	margin-top: 60px;
	margin-bottom: 60px;
}

.section-space--inner--140 {
	padding-top: 80px;
	padding-bottom: 80px;
}

.section-space--inner--190 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.section-space--inner--top--120 {
	padding-top: 60px;
}

.section-space--inner--top--100 {
	padding-top: 60px;
}

.section-space--inner--top--200 {
	padding-top: 60px;
}

.section-space--inner--bottom--120 {
	padding-bottom: 60px;
}

.section-space--top--60 {
	margin-top: 40px;
}

.section-space--top--80 {
	margin-top: 40px;
}

.section-space--bottom--50 {
	margin-bottom: 30px !important;
}

.section-space--bottom--60 {
	margin-bottom: 40px !important;
}

.section-space--bottom--80 {
	margin-bottom: 40px;
}

.section-space--bottom--120 {
	margin-bottom: 60px !important;
}

.header-area__desktop--default .header-contact-info {
	display: none;
}

.header-area__desktop--default .header-info-wrapper {
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.header-search--style3 {
	display: none;
}

.header-search--style5 {
	display: none;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon i {
	font-size: 50px;
}

.header-overlay-menu::before {
	display: none;
}

.footer-content-wrapper {
	margin-bottom: 0;
}

.footer-desc--style2 {
	margin-bottom: 15px;
}

.footer-intro-wrapper {
	margin-bottom: 30px;
}

.footer-widget-wrapper {
	margin-bottom: -30px;
}

.footer-widget {
	margin-bottom: 30px;
}

.footer-widget__title--style2 {
	padding-bottom: 20px;
}

.footer-copyright-wrapper {
	text-align: center;
}

.footer-copyright-right {
	text-align: center;
}

.page-sidebar {
	margin-top: 50px;
}

.search-overlay .popup-search-close {
	right: 30px;
}

.search-overlay .page-popup-search-inner {
	width: 100%;
}

.service-title-wrapper .title {
	margin-top: -5px;
}

.feature-project-box-wrapper {
	margin-top: -260px;
}

.feature-project-slider-container .feature-project-single-slide__image {
	margin-bottom: 30px;
}

.featured-project-three-slider__container-area .swiper-pagination {
	bottom: -40px;
}

.featured-project-three-slider__container {
	max-width: 540px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
}

.featured-project-three-slider__single-slide-wrapper {
	margin-right: auto;
	margin-left: auto;
}

.industry-single-nav {
	padding: 20px 30px 0;
}

.industry-slider-content-single {
	height: 600px;
}

.industry-three-slider__area .section-title-area {
	margin-bottom: 40px;
}

.industry-four-slider__container-area .ht-swiper-button-prev {
	left: 30px;
}

.industry-four-slider__container-area .ht-swiper-button-next {
	right: 30px;
}

.industry-six-slider__container-area .swiper-pagination {
	position: static;
	padding-left: 0;
	text-align: center;
	margin-top: 30px;
}

.industry-six-slider__container-area .swiper-pagination-bullet {
	height: auto;
	line-height: 1;
}

.industry-six-slider__single-slide {
	padding-left: 0;
}

.industry-six-slider__content {
	position: static;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	width: 100%;
	height: auto;
}

.industry-six-slider__content .slider-no {
	margin-bottom: 30px;
}

.industry-six-slider__content .slider-excerpt {
	margin-bottom: 40px;
}

.industry-six-slider__background {
	min-height: 400px;
}

.industry-seven-slider__single-slide .item-wrap__image {
	height: 400px;
}

.industry-seven-slider__single-slide .item-wrap__info {
	height: auto;
	padding-left: 30px;
	padding-right: 30px;
}

.industry-seven-slider__single-slide .item-wrap__info .show-more-link {
	bottom: 40px;
}

.industry-four-slider__area .section-title-area {
	margin-bottom: 0 !important;
}

.testimonial-slider__title--style2 {
	margin-bottom: 30px;
}

.testimonial-slider__content-area {
	margin: 0 -15px;
	margin-top: -30px;
}

.testimonial-slider__container {
	padding: 30px 15px;
}

.testimonial-slider-two__slide.swiper-slide-active {
	width: 100% !important;
	max-width: 100%;
}

.testimonial-slider-two__slide .rating {
	visibility: visible;
	opacity: 1;
}

.testimonial-slider-two__author-info {
	visibility: visible;
	opacity: 1;
}

.testimonial-slider-three__container-area {
	padding: 60px 30px 0;
}

.testimonial-slider-three__container-area .testi-quote {
	top: 10px;
}

.testimonial-slider-four__container {
	padding: 0;
	margin-top: 0;
}

.testimonial-slider-six__content-container-area {
	padding-top: 30px;
}

.testimonial-slider-six__content-container-area .swiper-pagination {
	margin-top: 30px;
}

.project-counter-bg {
	padding: 200px 0;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.project-counter-content {
	padding: 60px 30px;
}

.blog-title-wrapper-area {
	margin-bottom: 40px;
}

.blog-title-wrapper {
	margin-bottom: 30px;
}

.blog-grid-wrapper--style3 {
	margin-bottom: 10px;
	padding-bottom: 0;
}

.blog-grid-wrapper--style5 {
	margin-bottom: -60px;
}

.blog-post-slider__area {
	margin-bottom: 40px;
}

.blog-post-slider__single-slide--grid-view--style2 {
	margin-bottom: 70px;
}

.blog-post-slider__single-slide--grid-view--style3 {
	margin-bottom: 30px;
}

.single-grid-blog-post--style2 {
	margin-bottom: 60px;
}

.google-map--style2 {
	height: 400px;
}

.cta-video-content {
	margin-bottom: 40px;
}

.cta-video-image__video-mark {
	display: none;
}

.cta-video-image__icon {
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.cta-blog-area {
	padding-top: 200px;
	padding-left: 30px;
	padding-right: 30px;
}

.career-banner__content .subtitle {
	margin-bottom: 20px;
}

.career-banner__content .title {
	margin-bottom: 40px;
}

.job-list__wrapper {
	margin-top: 30px;
}

.single-icon-info__content {
	-webkit-flex-basis: calc(70% - 100px);
	-ms-flex-preferred-size: calc(70% - 100px);
	flex-basis: calc(70% - 100px);
}

.banner-video-area__image {
	margin-bottom: 40px;
}

.banner-image-two__content {
	padding-left: 30px;
	padding-right: 30px;
}

.banner-image-three__content {
	padding-top: 60px;
}

.banner-image-three__image {
	padding-top: 60px;
	padding-bottom: 60px;
	border-left: 1px solid #eee;
}

.banner-image-three__image .banner-sign {
	right: 30px;
	bottom: 30px;
}

.feature-background__content {
	max-width: 540px;
	margin: 0 auto;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 0;
}

.feature-background__content--style2 {
	margin-bottom: 60px;
}

.feature-background__content--style3 {
	padding-top: 100px;
	padding-bottom: 100px;
	padding-left: 40px;
	float: none;
	width: 430px;
	padding-left: 0;
}

.feature-background__content--style2 {
	padding-top: 60px;
	padding-bottom: 0;
}

.feature-background__content-wrapper {
	width: 100%;
	padding-left: 30px;
	padding-right: 30px;
}

.about-counter-content__image {
	margin-bottom: 30px;
}

.twitter-feed-slider__area {
	min-height: 370px;
}

.twitter-feed-slider__single--style2 .tweet-date {
	left: 30px;
	top: 30px;
}

.twitter-feed-slider__single--style2 .tweet-text {
	padding: 0 30px;
}

.contact-icon-info-wrapper--style2 {
	margin-bottom: 50px;
}

.contact-social-link-list-wrapper {
	margin-top: 40px;
}

.project-filter-menu {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-top: 0;
}

.project-filter-menu li {
	border-right: 0;
}

.industry-tab__link-wrapper {
	margin-bottom: 40px;
}

.industry-tab__link-wrapper.nav-tabs a.nav-link:after {
	display: none;
}

.industry-tab__content-wrapper {
	padding-left: 0;
}

.breadcrumb-page-title--style2 {
	margin-bottom: 30px;
}

.case-study__single-step {
	margin-bottom: 30px;
}

.case-study__step-wrapper {
	margin-bottom: -30px;
}

.single-list-blog-post-wrapper {
	margin-bottom: -40px;
}

.single-list-blog-post {
	margin-bottom: 40px;
}

.single-list-blog-post__media .video iframe {
	height: 300px;
}

.team-slider .ht-swiper-button-prev {
	left: 30px;
}

.team-slider .ht-swiper-button-next {
	right: 30px;
}

.ecosystem-slider .ht-swiper-button-prev {
	left: 30px;
}

.ecosystem-slider .ht-swiper-button-next {
	right: 30px;
}

.product-slider .ht-swiper-button-prev {
	left: 30px;
}

.product-slider .ht-swiper-button-next {
	right: 30px;
}

.portfolio-slider .ht-swiper-button-prev {
	left: 30px;
}

.portfolio-slider .ht-swiper-button-next {
	right: 30px;
}

.team-member-slider-area {
	padding-bottom: 5px;
}

.related-product-slider .ht-swiper-button-prev {
	left: 30px;
}

.related-product-slider .ht-swiper-button-next {
	right: 30px;
}

.image-list-text {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.image-list-text .image-wrapper {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	margin-right: 0;
	margin-bottom: 30px;
}

.image-list-text .text-wrapper {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.blog-post-nav .post-nav-title {
	width: auto;
}

.blog-post-nav__prev {
	margin-bottom: 15px;
}

.solution-grid-wrapper {
	margin-top: 0;
	padding-top: 0;
}

.solution-grid-wrapper:before {
	display: none;
}

.service-details-tab__navigation-wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.service-details-tab__navigation-wrapper .nav-link {
	margin-left: 0;
	border: 1px solid #ddd;
}

.service-details-box-icon__wrapper--style2 {
	margin-bottom: -40px;
}

.service-details-image-desc__single {
	margin-bottom: 40px;
}

.service-details-image-desc__single .image {
	margin-bottom: 30px;
}

.service-details-list-text {
	margin-top: 30px;
}

.award-item__single .image {
	margin-right: 20px;
}

.award-item__single .content {
	-webkit-flex-basis: calc(100% - 250px);
	-ms-flex-preferred-size: calc(100% - 250px);
	flex-basis: calc(100% - 250px);
}

.leadership__single .image {
	margin-right: 20px;
}

.leadership__single .content {
	-webkit-flex-basis: calc(100% - 270px);
	-ms-flex-preferred-size: calc(100% - 270px);
	flex-basis: calc(100% - 270px);
}

.career-single__content {
	padding-left: 30px;
	padding-right: 30px;
}

.about-content-row__content {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-list-wrapper {
	margin-top: 40px;
}

.about-icon-box-wrapper {
	margin-top: 40px;
}

.shop-single-product-wrapper {
	margin-bottom: -30px;
}

.shop-single-product {
	margin-bottom: 30px;
}

.description-tab-navigation .nav-tabs .nav-link {
	margin: 0 15px;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text .text-wrapper {
	width: auto;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__bottom {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__bottom .single-block-image {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	padding-right: 0;
	margin-bottom: 30px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comment-form {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	padding-left: 0;
	border-left: 0;
}

.product-details__price {
	margin-bottom: 30px;
}

.cart-table thead {
	display: none;
}

.cart-table tr {
	position: relative;
	display: block;
	text-align: center;
	border: 1px solid #ededed;
	padding: 30px 0;
}

.cart-table td {
	text-align: center;
	display: block;
	width: 100% !important;
	margin: 0 auto;
	padding: 0 !important;
	border: none;
}

.cart-table td.product-name a {
	margin-top: 20px;
}

.cart-table td.product-name .product-variation {
	float: none;
	margin-bottom: 10px;
}

.cart-table td.product-price {
	margin-bottom: 15px;
}

.cart-table td.stock-status {
	margin-bottom: 15px;
}

.cart-table td.product-quantity {
	margin-bottom: 20px;
}

.cart-table td.product-remove {
	position: absolute;
	top: 0;
	right: 10px;
	width: auto !important;
}

.cart-table td.product-remove a i {
	line-height: 35px;
}

.cart-table td.product-remove a {
	border: none;
	width: auto;
	height: auto;
}

.coupon-form {
	margin-bottom: 30px;
}

.shipping-form {
	margin-bottom: 30px;
}

.not-found__page-title {
	font-size: 150px;
}

.not-found__page-subtitle {
	font-size: 30px;
}

.site-maintenance h2 {
	font-size: 30px;
}

.site-maintenance h1 {
	font-size: 45px;
}

}

@media only screen and (max-width: 575px) {

.mobile-menu-content .social-links {
	display: none;
}

.featured-project-two-slider-container {
	margin: 0;
}

.featured-project-two-slider-wrapper .swiper-slide {
	padding: 0;
}

.featured-project-three-slider__container {
	max-width: 100%;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
}

.featured-project-three-slider__single-slide-wrapper {
	margin-right: auto;
	margin-left: auto;
}

.industry-six-slider__content {
	padding-left: 15px;
	padding-right: 15px;
}

.industry-seven-slider__single-slide .item-wrap__info {
	padding: 50px 15px;
}

.industry-seven-slider__single-slide .item-wrap__info .icon {
	margin-bottom: 30px;
}

.industry-seven-slider__single-slide .item-wrap__info .show-more-link {
	position: static;
}

.testimonial-slider__container--style2 {
	padding: 0 15px;
}

.testimonial-slider__container--style3 {
	padding: 0 15px;
}

.testimonial-slider__single-item--style2 {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.testimonial-slider__single-item--style2 .testimonial-slider__single-item__image {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	width: 100%;
	margin-right: 0;
	margin-bottom: 30px;
}

.testimonial-slider__single-item--style2 .testimonial-slider__single-item__content {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.brand-logo-slider__container-area {
	margin: -15px;
}

.brand-logo-slider__container {
	padding: 15px;
}

.cta-blog-area {
	padding-left: 15px;
	padding-right: 15px;
}

.single-icon-info__content {
	-webkit-flex-basis: calc(70% - 100px);
	-ms-flex-preferred-size: calc(70% - 100px);
	flex-basis: calc(70% - 100px);
}

.banner-image-content {
	padding: 50px 15px;
}

.banner-image-three__image {
	padding-left: 15px;
	padding-right: 15px;
}

.banner-image-three__image .banner-sign {
	right: 15px;
	bottom: 15px;
}

.single-industry-grid-two__content {
	padding-left: 15px;
	padding-right: 15px;
}

.single-industry-grid-two__content .title i {
	font-size: 60px;
	margin-right: 15px;
}

.single-industry-grid-two .post-readmore-btn span {
	padding-left: 15px;
}

.single-industry-grid-two .post-readmore-btn i {
	right: 15px;
}

.feature-background__image--style2 {
	min-height: 250px;
}

.feature-background__content {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

.feature-background__content--style3 {
	padding-left: 60px;
}

.feature-background__content-wrapper {
	padding-left: 15px;
	padding-right: 15px;
}

.twitter-feed-slider__container-area--style2 .ht-swiper-button-prev {
	left: 0;
}

.twitter-feed-slider__container-area--style2 .ht-swiper-button-next {
	left: 40px;
}

.twitter-feed-slider__single--style2 .tweet-date {
	left: 15px;
	top: 15px;
}

.twitter-feed-slider__single--style2 .tweet-text {
	padding: 0 15px;
}

.single-industry-icon-wrapper {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.single-industry-icon--style2 .single-industry-icon__content .text-wrapper {
	padding-left: 15px;
	padding-right: 15px;
}

.single-industry-icon--style2 .single-industry-icon__content .title {
	font-size: 20px;
}

.breadcrumb-page-list {
	text-align: left;
	margin-top: 10px;
}

.leadership__single {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.leadership__single .image {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	margin-right: 0;
	margin-bottom: 30px;
}

.leadership__single .content {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.history-grid__wrapper:before {
	left: 20px;
}

.history-grid__single-wrapper {
	padding-left: 50px;
	padding-right: 15px;
	margin-top: 40px;
}

.history-grid__single-wrapper .dot {
	left: 6px;
}

.history-grid__single-wrapper:nth-child(2n) {
	padding-left: 50px;
	padding-right: 15px;
}

.history-grid__single-wrapper:nth-child(2n) .dot {
	left: 6px;
}

.history-grid__single-wrapper:nth-child(2n+2) {
	margin-top: 40px;
}

.sort-selection-wrapper {
	margin-top: 15px;
}

.shop-single-product-wrapper .col-sm-6 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 50%;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.coupon-form input {
	margin-bottom: 15px;
}

.checkout-cart-total {
	padding: 30px;
}

.checkout-payment-method {
	padding: 30px;
}

.myaccount-content {
	padding: 20px 15px;
}

.forget-pass-link {
	margin-top: 15px;
}

}

@media only screen and (max-width: 575px), only screen and (max-width: 479px) {

.support-footer__area .contact-form-area {
	padding-left: 15px;
	padding-right: 15px;
}

}

@media only screen and (max-width: 479px) {

.header-area__desktop--default .header-info-wrapper .logo {
	-webkit-flex-basis: 265px;
	-ms-flex-preferred-size: 265px;
	flex-basis: 265px;
	padding-right: 0;
}

.footer-intro-wrapper .social-links--round-style li a {
	height: 40px;
	width: 40px;
	line-height: 40px;
}

.sidebar-category li a {
	padding-left: 20px;
}

.search-overlay .form-description {
	font-size: 15px;
}

.section-title-area .title--style7 {
	margin-bottom: 15px;
}

.section-title-area--style3 {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.half-bg-image {
	min-height: 350px;
}

.faq-wrapper .card-header h5 button {
	padding-left: 15px;
	padding-right: 20px;
}

.faq-wrapper .card-header h5 button span {
	right: 15px;
}

a.scroll-top {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 1.25rem;
}

.progress-bar-content {
	padding-right: 0;
}

.industry-five-slider__single-slide {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.industry-five-slider__single-slide__image {
	width: 100%;
	min-height: 330px;
}

.industry-five-slider__single-slide__content {
	width: 100%;
}

.industry-six-slider__background {
	min-height: 350px;
}

.industry-seven-slider__single-slide .item-wrap__image {
	height: 300px;
}

.testimonial-slider__title-wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.testimonial-slider__title {
	margin-bottom: 15px;
}

.testimonial-slider__nav-container .ht-swiper-button-prev {
	padding-left: 0;
}

.testimonial-slider__single-item {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.testimonial-slider__single-item__image {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	margin-bottom: 30px;
	width: 90px;
	margin-left: auto;
	margin-right: auto;
}

.testimonial-slider__single-item--style3 .testimonial-slider__single-item__image {
	margin-left: 0;
}

.testimonial-slider-three__container-area {
	padding: 60px 15px 0;
}

.testimonial-slider-three__container-area .testi-quote {
	right: 0;
}

.testimonial-slider-three__single-slide .testimonial-item__image {
	margin-bottom: 30px;
}

.testimonial-slider-seven__container-area .swiper-pagination {
	margin-top: 40px;
}

.google-map {
	height: 300px;
}

.google-map--style2 {
	height: 300px;
}

.google-map--style3 {
	height: 300px;
	margin-bottom: 30px;
}

.cta-blog-area {
	padding-top: 100px;
}

.job-list__wrapper {
	padding: 40px 20px;
}

.job-list__title-wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.career-background__content {
	padding: 50px 30px;
}

.single-icon-info__content {
	-webkit-flex-basis: calc(100% - 100px);
	-ms-flex-preferred-size: calc(100% - 100px);
	flex-basis: calc(100% - 100px);
}

.single-industry-grid-two__image {
	min-height: 300px;
}

.feature-background__content--style3 {
	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 15px;
}

.single-counter__number .number-suffix {
	left: 0;
}

.single-counter__text {
	font-size: 16px;
}

.project-grid-5-column .project-item {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 100%;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.service .service-inner {
	padding: 60px 30px;
}

.contact-form-wrapper--style2 {
	padding-left: 15px;
	padding-right: 15px;
}

.contact-social-link-list li {
	float: none;
	width: 100%;
}

.swiper-static-nav-wrap {
	margin-right: 0;
	margin-left: -15px;
}

.single-industry-flip-front__content {
	padding: 50px 15px;
}

.single-industry-flip-front__content .title {
	font-size: 18px;
}

.single-industry-flip-back__content .career-btn {
	margin-top: 40px;
}

.breadcrumb-page-title--style2 {
	margin-bottom: 15px;
	font-size: 2.1875rem;
}

.case-study__subcontent-wrapper {
	margin-left: 0;
}

.single-list-blog-post__media .blog-post-link {
	padding: 30px 50px;
}

.single-list-blog-post__media .blog-post-link a {
	font-size: 20px;
}

.single-list-blog-post__media .blog-post-big-quote {
	padding: 50px 20px;
}

.quote-text {
	padding-left: 60px;
}

.blog-post-author {
	padding: 30px 15px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.blog-post-author__image {
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
}

.award-item__single {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.award-item__single .image {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	margin-right: 0;
	margin-bottom: 30px;
}

.award-item__single .content {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.career-single__image {
	min-height: 300px;
}

.about-content-row__content {
	padding-left: 30px;
}

.shop-single-product-wrapper .col-sm-6 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 100%;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.description-tab-navigation .nav-tabs .nav-link {
	padding-left: 0;
	margin-left: 0;
}

.single-product-description-tab-content .tab-content .tab-pane .description-content--extra__top .single-block-text p.big-text {
	font-size: 30px;
	line-height: 34px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__content .review-time {
	margin-bottom: 5px;
}

.single-product-description-tab-content .tab-content .tab-pane .review-content-wrapper .review-comments .single-review-comment__content .rating {
	position: static;
}

.product-details__actions a {
	margin-right: 5px;
}

.forget-pass-link {
	margin-top: 15px;
}

}

@media only screen and (max-width: 479px) and (max-width: 1200px) {

.breadcrumb-page-title--style2 {
	font-size: calc(1.34375rem + 1.125vw);
}

}

@-webkit-keyframes moveVertical {

100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

}

@keyframes moveVertical {

100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

}

@-webkit-keyframes box-image-pop {

50% {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

}

@keyframes box-image-pop {

50% {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

}

@-webkit-keyframes animationSignal {

0% {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

1% {
	opacity: 0.25;
}

20% {
	opacity: 0.25;
}

60% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 0;
}

}

@-webkit-keyframes animationSignal2 {

0% {
	opacity: 0;
}

1% {
	opacity: 0.2;
}

20% {
	opacity: 0.17;
}

60% {
	opacity: 0;
}

}

@keyframes animationSignal {

0% {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

1% {
	opacity: 0.25;
}

20% {
	opacity: 0.25;
}

60% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 0;
}

}

@keyframes animationSignal2 {

0% {
	opacity: 0;
}

1% {
	opacity: 0.2;
}

20% {
	opacity: 0.17;
}

60% {
	opacity: 0;
}

}

@-webkit-keyframes zoomBig {

0% {
	-webkit-transform: translate(-50%, -50%) scale(0);
	transform: translate(-50%, -50%) scale(0);
	opacity: 1;
}

100% {
	-webkit-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
	opacity: 0;
}

}

@keyframes zoomBig {

0% {
	-webkit-transform: translate(-50%, -50%) scale(0);
	transform: translate(-50%, -50%) scale(0);
	opacity: 1;
}

100% {
	-webkit-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
	opacity: 0;
}

}

@keyframes box-image-pop {

50% {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

}








.hover-underline:hover {
	text-decoration: underline;
	color: currentColor;
	-webkit-text-decoration-color: currentColor; /* Safari */  
	text-decoration-color: currentColor;
}

.color-whitesmoke {
	color : whitesmoke;
}

.color-white:hover,
.color-white:focus-visible,
.color-white:visited,
.color-white:hover,
.color-white:target,
.color-white:focus-within,
.color-white:focus,
.color-white:active,
.color-white:hover,
.color-white {
	color : white;
}

.image-bg {
	/* this is needed or the background will be offset by a few pixels at the top */
	overflow: auto;
	position: relative;
	content: "";
	position: fixed;
	left: 0;
	right: 0;
	/* z-index: -1; */
  
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	filter: blur(5px);
}

.cursor-pointer:hover {
    cursor:pointer;
}

.border-radius-50 {
	border-radius:50px !important;
}

.no-border:hover,
.no-border:focus-visible,
.no-border:visited,
.no-border:hover,
.no-border:target,
.no-border:focus-within,
.no-border:focus,
.no-border:active,
.no-border:hover,
.no-border {
	border:solid 0px !important;
	box-shadow: 0 0 0 0 rgb(0 123 255 / 25%);
}

.with-border:hover,
.with-border:focus-visible,
.with-border:visited,
.with-border:hover,
.with-border:target,
.with-border:focus-within,
.with-border:focus,
.with-border:active,
.with-border:hover,
.with-border {
	border: 1px solid rgba(0,0,0,.125);
}

.card-header .accordion-arrow:after {
	content: "\f123";
	font-family: 'Ionicons';
	font-weight: 900;
	float: right;
}

.card-header .accordion-arrow:after {
	content: "\f123";
}

.card-header[aria-expanded="true"] .accordion-arrow:after {
	content: "\f126";
}

